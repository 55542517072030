import React, { useEffect, useState } from "react";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import { TiUserAdd } from "react-icons/ti"
import ProfileSidebar from "../ProfileSidebar";
import TitleBar from "../../../CommonComponents/TitleBar";
import CreateTeamModal from "./CreateTeamModal";
import { fetchAccData } from "../../../../Redux/Actions/AccountManagements";
import { useDispatch } from "react-redux";
import MemberRows from "./MemberRows";
import { FaSpinner } from "react-icons/fa";

const ManageTeam = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [teamMember, setTeamMember] = useState([])
    const [clients, setClients] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchMembers = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchAccData(setTeamMember, setClients, loader, setLoader))
    }

    useEffect(() => {
        fetchMembers()
    }, [])


    return (
        <>
            <TitleBar title="Account Management" />
            <Header />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="account-wrap">
                        <ProfileSidebar />
                        <div className="account-right large">
                            <div className="titleBar">
                                <div className="titleBar-left">
                                    <h2>Account Management</h2>
                                    <p>Add and manage members</p>
                                </div>
                                <div className="titleBar-right"><button className="theme-btn sm-w-100" onClick={handleShow}><span><TiUserAdd /> Add Member</span></button></div>
                            </div>
                            <div className="mt-3">
                                <h6 style={{ fontWeight: "bold" }}>Clients</h6>
                                <div className="manage-block">
                                    {clients.length > 0 ?
                                        clients.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <MemberRows
                                                        curElem={curElem}
                                                        fetchMembers={fetchMembers}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <div className="text-center mt-4">
                                            {loader.fetch ? <FaSpinner className="spin" size={25} /> : "No client created yet! "}
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className="mt-3">
                                <h6 style={{ fontWeight: "bold" }}>Team Members</h6>
                                <div className="manage-block">
                                    {teamMember.length > 0 ?
                                        teamMember.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <MemberRows
                                                        curElem={curElem}
                                                        fetchMembers={fetchMembers}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <div className="text-center mt-4">
                                            {loader.fetch ? <FaSpinner className="spin" size={25} /> : "No team member created yet! "}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <CreateTeamModal
                show={show}
                handleClose={handleClose}
                fetchMembers={fetchMembers}
            />
            <Footer />
        </>
    )
}

export default ManageTeam;