import { produce } from "immer"

const initialState = {
    list: [],
    create: {
        name: "",
        description: "",
        staff: [],
        projectStatus: "active"
    },
    conversationList: [],
    activeConv: false,
    godModeStatus: false
}

export const ProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_PROJECT_LIST":
            return produce(state, (draft) => {
                draft.list = action.payload
            })
        case "ON_CHANGE_PROJECT":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.create = {
                    ...draft.create,
                    [name]: value
                }
            })
        case "EDIT_PROJECT":
            return produce(state, (draft) => {
                draft.create = action.payload
            })

        case "UPDATE_PROJECT_STAFFS":
            return produce(state, (draft) => {
                draft.create.staff = action.payload
            })

        case "DELETE_PROJECT":
            return produce(state, (draft) => {
                draft.list = draft.list.filter((curElem) => {
                    return +curElem.id !== +action.payload
                })
            })
        case "ACTIVE_CONVERSATION":
            return produce(state, (draft) => {
                draft.activeConv = action.payload
            })

        case "FETCH_CONVERSATIONS_LIST":
            return produce(state, (draft) => {
                draft.conversationList = action.payload
            })

        case "DEFAULT_CONV_ACTIVE":
            return produce(state, (draft) => {
                if (draft.conversationList.length > 0) {
                    if (!action.payload) {
                        draft.activeConv = draft.conversationList[0]
                    } else {
                        draft.activeConv = draft.conversationList.find(({ id }) => +id === +action.payload)
                    }
                }
            })

        case "ON_DELETE_CONV":
            return produce(state, (draft) => {
                // if (+action.payload === +draft.activeConv.id) {
                //     draft.activeConv = false
                // }
                draft.conversationList = draft.conversationList.filter((curElem) => {
                    return +curElem.id !== +action.payload
                })
            })

        case "UPDATE_ACTIVE_CONV_VOICE":
            return produce(state, (draft) => {
                if (action.payload.name === "langCode") {
                    draft.activeConv = {
                        ...draft.activeConv,
                        language: action.payload.value.language,
                        langCode: action.payload.value.langCode
                    }
                } else {
                    draft.activeConv = {
                        ...draft.activeConv,
                        [action.payload.name]: action.payload.value
                    }
                }

            })

        case "ACTIVE_GODMODE":
            return produce(state, (draft) => {
                draft.godModeStatus = true
            })

        case "DEACTIVE_GODMODE":
            return produce(state, (draft) => {
                draft.godModeStatus = false
            })

        case "UNMOUNT_CREATE_PROJECT":
            return produce(state, (draft) => {
                draft.create = initialState.create
            })
        case "UNMOUNT_CHAT_SCREEN":
            return produce(state, (draft) => {
                draft.list = initialState.list
                draft.create = initialState.create
                draft.conversationList = initialState.conversationList
                draft.activeConv = initialState.activeConv
                draft.godModeStatus = initialState.godModeStatus
            })

        default:
            return state
    }
}