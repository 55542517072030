import React, { useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { FaUserCircle } from 'react-icons/fa'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import CreateTeamModal from './CreateTeamModal'
import CommonAlert from '../../../CommonComponents/CommonAlert'
import { onDeleteAcc } from '../../../../Redux/Actions/AccountManagements'
import { useDispatch } from 'react-redux'

const MemberRows = ({ curElem, fetchMembers }) => {
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            client_id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteAcc(data, fetchMembers, setSweet))
    }

    return (
        <div className="manage-block-single">
            <div className="manage-block-left">
                <div className="manage-block-icon"><FaUserCircle /></div>
                <div className="manage-block-txt">
                    <h6>{curElem.name}</h6>
                    <p>{curElem.email} . {curElem.created}</p>
                </div>
            </div>
            <div className="manage-block-right">
                <div className="group-icon">
                    <span onClick={handleShow}><MdOutlineModeEditOutline /></span>
                    <span onClick={onDelete}><BiTrash /></span>
                </div>
            </div>

            <CreateTeamModal
                show={show}
                handleClose={handleClose}
                curElem={curElem}
                fetchMembers={fetchMembers}
            />

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this member?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />

        </div>
    )
}

export default MemberRows