import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { AiOutlineEye } from 'react-icons/ai'
import { BsFiletypeDoc, BsFiletypePdf, BsImages } from 'react-icons/bs'
import { FiCopy, FiDownload } from 'react-icons/fi'
import { HiDotsVertical } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ImagePreview from './ImagePreview'
import pdfImg from "../../../../assets/images/icon/pdfFile.png"
import wordImg from "../../../../assets/images/icon/wordFile.png"
import { PiFilePdfDuotone } from "react-icons/pi";
import { GrDocumentText } from "react-icons/gr";
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { appName } from '../../../../Global/Global'

let typingIndex = 0

const UserMessage = ({ curElem, activeConv, assistant, typingActive, length, index, showIntTyping }) => {
    let arr = ["reading", "thinking", "analysing", "researching", "checking on internet", "typing"]
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const rebrandData = useSelector(state => state.rebrand)


    const [resMes, setResMes] = useState(arr[0])
    const [typingInterval, setTypingInterval] = useState(false)
    const [assiData, setAssiData] = useState({
        name: ""
    })

    const printHandle = (messageText) => {
        const element = document.createElement('div');
        element.innerHTML = `<pre >${displayTextWithLineBreaks(messageText, "20px Arial")}</pre><br><p style="text-align:right;z-index:1;position:absolute;bottom:10px;right:10px"><b style="color:black;">This Pdf is generated by ${rebrandData?.data?.name ? rebrandData?.data?.name : appName}</b></p>`;
        // element.innerHTML = `<pre >${formatText(messageText)}</pre><br><p style="text-align:right;z-index:1;position:absolute;bottom:10px;right:10px"><b style="color:black;">This Pdf is generated by AiStaff</b></p>`;
        element.style.fontFamily = 'Arial';
        element.style.border = "2px solid black"
        element.style.fontSize = '15px';
        element.style.color = 'black';
        element.style.padding = '10px';
        element.style.minHeight = "165vh"
        element.style.whiteSpace = 'pre-wrap';
        const options = {
            margin: 10,
            filename: "message",
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        html2pdf().set(options).from(element).save();
    }

    function displayTextWithLineBreaks(text, font) {
        let canvas = displayTextWithLineBreaks.canvas || (displayTextWithLineBreaks.canvas = document.createElement("canvas"));
        let context = canvas.getContext("2d");
        context.font = font;

        const maxLineWidth = 700; // 20px width constraint
        text = text.replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "")
        text = text.replaceAll(`<pre style="background-color:black;color:white">`, "")
        text = text.replaceAll("</pre>", "");
        //console.log(text);
        let words = text.split(' ');
        let lines = [];
        let currentLine = '';

        words.forEach(word => {
            const wordWidth = context.measureText(word).width;
            const currentLineWidth = context.measureText(currentLine).width;

            if (currentLine === '' || currentLineWidth + wordWidth < maxLineWidth) {
                currentLine += (currentLine === '' ? '' : ' ') + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        });

        lines.push(currentLine);
        //console.log(lines);
        for (let index = 0; index < lines.length; index++) {
            // if (lines[index].includes(`<pre style="background-color:black;color:white;width:100%,overflow-<br>x:scroll;border-radius:10px;padding:10px";>`)) {
            //     console.log(1);
            // } else {
            //     console.log(0);
            // }
            //console.log(lines[index]);
        }
        //console.log(lines.length);
        //let formattedText = lines.replaceAll(`style="background-color:black;color:white;width:100%,overflow-<br>x:scroll;border-radius:10px;padding:10px";`, "").replaceAll("</pre>;", "");
        let formattedText = lines.join(' <br /> ');
        //console.log(formattedText);


        return formattedText;
    }

    const handleAction = (type, ind) => {
        // let chatName = conversationData.find((ele) => +ele.id === +selectedcId)
        let copyText = document.getElementById(`message${index}`)
        if (type === "copy") {
            let p = document.createElement("textarea")
            let data = copyText.firstChild.innerHTML
            data = data.replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "").replaceAll(`<pre style="background-color:black;color:white">`, "").replaceAll("</pre>", "").replaceAll("&lt;", "<").replaceAll("&gt;", ">").replaceAll("<br>", "\n");
            p.value = data
            p.select();
            p.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(p.value);
            p.remove()
        } else if (type === "doc") {
            let documentName = activeConv.name
            let fileDownload = document.createElement("a");
            let data = copyText.firstChild.innerHTML
            data = data
                .replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "")
                .replaceAll(`<pre style="background-color:black;color:white">`, "")
                .replaceAll("</pre>", "").replaceAll("<br>", "\n")
                .replaceAll("&lt;", "\u2039").replaceAll("&gt;", "\u203A")
            fileDownload.href = `data:application/vnd.ms-word;charset=utf-8,` + encodeURIComponent(data)
            fileDownload.download = `${documentName}.doc`;
            fileDownload.click();

        } else {
            printHandle(copyText.firstChild.innerHTML);
        }
    }

    useEffect(() => {
        if (curElem.staff.length > 0) {
            setAssiData({
                ...assiData,
                name: curElem.staff[0].name
            })
        }
    }, [curElem])

    useEffect(() => {
        if (typingActive && (index === length - 1)) {
            setTypingInterval(
                setInterval(() => {
                    setResMes(arr[typingIndex])
                    typingIndex++
                    if (typingIndex === 6) {
                        typingIndex = 0
                    }
                }, 5000)
            )
        } else {
            typingIndex = 0
            clearInterval(typingInterval)
            setTypingInterval(false)
        }

        return () => {
            clearInterval(typingInterval)
            setTypingInterval(false)
        }

    }, [typingActive])

    useEffect(() => {
        return () => {
            clearInterval(typingInterval)
            setTypingInterval(false)
        }
    }, [])


    return (
        <>
            {curElem.fileType !== "" ?
                <div className="message-wrap own-message">
                    <div className="message-single">
                        <div className="message-group">
                            <div className="user-photo"><img alt="" src={auth.user.profile} /></div>
                            <div className="message-head">
                                <span>{auth.user.name}</span>
                                <span>{curElem.created}</span>
                            </div>
                            <div className="message-body">
                                <div className="message-txt">
                                    <div className="message-images sm">
                                        <div className="row" >
                                            <div className="col-12">
                                                <div className="message-image-single">
                                                    <img alt="" src={curElem.fileType === "doc" ? wordImg : curElem.fileType === "pdf" ? pdfImg : curElem.fileUrl} />
                                                    {curElem.fileType === "image" ?
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon" onClick={handleShow}><AiOutlineEye /></div>
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p>{curElem.message}</p>
                                </div>
                                {/* <div className="user-nav">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="g" id="dropdown-basic">
                                            <div className="drop-icon"><HiDotsVertical /></div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ul>
                                                <li><Link to="/">Action</Link></li>
                                                <li><Link to="/">Another action</Link></li>
                                                <li><Link to="/">Something else</Link></li>
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}
                            </div>
                            <div className="msg-tags">Tags  <span>@{assiData.name}</span>
                                <span className=''>
                                    {curElem.fileType === "image" ?
                                        <BsImages /> :
                                        curElem.fileType === "pdf" ?
                                            <PiFilePdfDuotone /> :
                                            <GrDocumentText />
                                    }
                                    {curElem.fileType}</span>
                            </div>
                            {typingActive && (length - 1 === index) ?
                                <p style={{ color: "#494949", display: "flex", marginBottom: "2px", paddingBottom: 5, fontSize: "14px", paddingLeft: 40, marginTop: 10 }}>
                                    {showIntTyping ?
                                        <span className="mx-1">Accessing Internet</span>
                                        :
                                        <> <span className="text-capitalize mx-1">{assistant.name} </span>is {resMes}</>
                                    }
                                    <div className="blink-typing">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </p>
                                : ""}
                        </div>
                    </div>
                </div>

                :
                <div className="message-wrap own-message">
                    <div className="message-single">
                        <div className="message-group">
                            <div className="user-photo"><img alt="" src={auth.user.profile} /></div>
                            <div className="message-head">
                                <span>{auth.user.name}</span>
                                <span>{curElem.created}</span>
                            </div>
                            <div className="message-body">
                                <div className="message-txt" id={`message${index}`}>
                                    <p>{curElem.message.replaceAll('\n', `<br/>`)}</p>
                                </div>
                                <div className="user-nav">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="g" id="dropdown-basic">
                                            <div className="drop-icon"><HiDotsVertical /></div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className="act-menu sm" onClick={() => handleAction("copy")}>
                                                <div className="embed-user-icon"><FiCopy /></div>
                                                <div className="act-menu-txt">
                                                    <h6>Copy Text</h6>
                                                </div>
                                            </div>
                                            <div className="act-menu sm" onClick={() => handleAction("doc")}>
                                                <div className="embed-user-icon"><BsFiletypeDoc /></div>
                                                <div className="act-menu-txt">
                                                    <h6>Export to Doc</h6>
                                                </div>
                                            </div>
                                            <div className="act-menu sm" onClick={() => handleAction("pdf", index)}>
                                                <div className="embed-user-icon"><BsFiletypePdf /></div>
                                                <div className="act-menu-txt">
                                                    <h6>Export to PDF</h6>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="msg-tags">Tags
                                <span>@{assiData.name}</span>
                                {/* <span><BsImages /> Image</span> */}
                            </div>
                            {typingActive && (length - 1 === index) ?
                                <p style={{ color: "##494949", display: "flex", marginBottom: "2px", paddingBottom: 5, fontSize: "14px", paddingLeft: 40, marginTop: 10 }}>
                                    {showIntTyping ?
                                        <span className="mx-1">Accessing Internet</span>
                                        :
                                        <> <span className="text-capitalize mx-1">{assistant.name} </span>is {resMes}</>
                                    }
                                    <div className="blink-typing">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </p>
                                : ""}
                        </div>
                    </div>

                </div>
            }

            <ImagePreview
                show={show}
                handleClose={handleClose}
                curElem={curElem}
            />


        </>
    )
}

export default UserMessage