import React, { useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { RiDownloadLine } from "react-icons/ri"
import { FaSpinner } from "react-icons/fa"
import { AiOutlinePause, AiOutlineDelete } from "react-icons/ai"
import ProfileSidebar from "./ProfileSidebar";
import TitleBar from "../../CommonComponents/TitleBar";
import { gdprAction, onChangeEmail } from "../../../Redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { appName } from "../../../Global/Global";
import CommonAlert from "../../CommonComponents/CommonAlert";
import { BsFillEnvelopeFill } from "react-icons/bs";

const Privacy = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand.data)
    const [email, setEmail] = useState("")
    const [loader, setLoader] = useState(false)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "",
        text: "",
        ep: "",
        loader: false
    })

    const modalData = [
        {
            message: `${rebrand ? rebrand.name : appName} stores your private information. If you wish to download these data, you can do that by clicking on Download button.`
        },
        {
            message: 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your'
        },
        {
            message: 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
        }

    ]
    const performAction = () => {
        setSweet({
            ...sweet,
            loader: true
        })
        dispatch(gdprAction({}, sweet, setSweet))
    }

    const handleClick = (index) => {
        setSweet({
            ...sweet,
            enable: true,
            id: index,
            text: modalData[index].message,
            confirmButtonName: index === 0 ? "Download" : index === 1 ? "Stop Processing" : "Delete Data",
            ep: index === 0 ? "download-user-data" : index === 1 ? "disable-account" : "delete-account"
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            ep: ""
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(onChangeEmail(data, setLoader))
    }

    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    // ]

    return (
        <>
            <TitleBar title="Privacy" />
            <Header />

            <div className="site-wrapper">
                <div className="site-container">

                    <div className="account-wrap">
                        <ProfileSidebar />



                        <div className="account-right">

                            <div className="titleBar">
                                <div className="titleBar-left">
                                    <h2>Privacy</h2>
                                    {/* <p>You can change and manage account details</p> */}
                                </div>
                            </div>
                            <div className="dashboard-block">
                                <h2 className="account-title">Change Email</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="input-wrap">
                                        <label htmlFor="">Current Email Address</label>
                                        <div className="inp-outer icon-left">
                                            <input
                                                className="inp alt"
                                                type="email"
                                                placeholder='test@gmail.com'
                                                value={auth.user.email}
                                                readOnly
                                            />
                                            <span className="inp-icon"><BsFillEnvelopeFill /></span>
                                        </div>
                                    </div>
                                    <div className="input-wrap">
                                        <label htmlFor="">New Email Addresss</label>
                                        <div className="inp-outer icon-left">
                                            <input
                                                className="inp alt"
                                                type="email"
                                                placeholder={auth.user.email}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                            <span className="inp-icon"><BsFillEnvelopeFill /></span>
                                        </div>
                                    </div>
                                    <div class="alert alert-primary mt-3" role="alert">Updating your email will log you out for re-login.</div>
                                    <div className="input-wrap text-end">
                                        <button className="theme-btn" type="submit"><span>{loader ? <>Updating <FaSpinner className="spin" size={14} /> </> : "Update"}</span></button>
                                    </div>
                                </form>
                            </div>
                            {/* <div className="dashboard-block">
                                <h2 className="account-title">General Settings</h2>
                                <div className="settings-block">
                                    <div className="settings-block-left">
                                        <h6>Press "ENTER" to submit chat messages.</h6>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                                    </div>
                                    <div>
                                        <div className="switch-btn">
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settings-block">
                                    <div className="settings-block-left">
                                        <h6>Auto-submit voice chat.</h6>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                                    </div>
                                    <div>
                                        <div className="switch-btn">
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settings-block">
                                    <div className="settings-block-left">
                                        <h6>Disable staffs from application in non-working hours.</h6>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                                    </div>
                                    <div>
                                        <div className="switch-btn">
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settings-block">
                                    <div className="settings-block-left">
                                        <h6>AI Staff Conversation Language.</h6>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                                    </div>
                                    <div className="select-style">
                                        <Select options={options} className="react-select-container" classNamePrefix="react-select" />
                                    </div>
                                </div>
                            </div> */}

                            <div className="dashboard-block">
                                <h2 className="account-title">Manage Your Account Data</h2>
                                <div className="settings-block">
                                    <div className="settings-block-left alt">
                                        <h6>Download My Data</h6>
                                        <p className="pt-2">{rebrand ? rebrand.name : appName} stores your private information like your name, email. It also stores your uploaded graphics such as images, videos and your music tiles along with TTS Text to speech. If you wish to download these data, you can do that -by clicking" download your data.</p>
                                        <button className="theme-btn mt-2" onClick={() => handleClick(0)}><span><RiDownloadLine /> Download My Data</span></button>
                                    </div>
                                </div>
                                <div className="settings-block">
                                    <div className="settings-block-left alt">
                                        <h6>Stop Processing My Data</h6>
                                        <p className="pt-2">If you wish to stop your private data from getting processed on {rebrand ? rebrand.name : appName} you can click the button to do so. Please note that doing this will disable your account and you will not be able to use {rebrand ? rebrand.name : appName} further. In order to reactivate your account, you would have to contact our support team at support@vineasx.com. <a href="">Please Proceed With Caution.</a></p>
                                        <button className="theme-btn theme-btn-orange mt-2" onClick={() => handleClick(1)}><span><AiOutlinePause /> Stop Processing My Data</span></button>
                                    </div>
                                </div>
                                <div className="settings-block">
                                    <div className="settings-block-left alt">
                                        <h6>Delete My Data</h6>
                                        <p className="pt-2">If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use {rebrand ? rebrand.name : appName} further. You cannot undo this step from support also. So if you wish to re-use {rebrand ? rebrand.name : appName} again, then you will have to start all over again from scratch. <a href="">Please Proceed With Caution.</a></p>
                                        <button className="theme-btn theme-btn-red mt-2" onClick={() => handleClick(2)}><span><AiOutlineDelete /> Delete My Data</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={sweet.text}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performAction}
                alertLoader={sweet.loader}
            />

            <Footer />
        </>
    )
}

export default Privacy;