import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onSubmitKd, onUpdateKd } from '../../../../Redux/Actions/ProjectActions'

const EditKnowledge = ({ curElem, show, handleClose, fetchFunction , type}) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        submit: false
    })
    const [text, setText] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = {
            text,
            id: curElem.id
        }
        if (curElem.type !== "write") {
            delete obj.url
        }
        setLoader({
            ...loader,
            submit: true
        })
        let url = "update-knowledgebase"
        if (type === "directory") {
            url = "update-custom-knowledge"
        }
        dispatch(onUpdateKd(url, obj, fetchFunction, handleClose, loader, setLoader))
    }

    useEffect(() => {
        if (curElem) {
            setText(curElem.data)
        }
    }, [curElem])

    return (
        <Modal className="VideoModal modal-800" show={show} centered>
            <Modal.Body>
                <div className="titleBar">
                    <div className="titleBar-profile">
                        <div className="titleBar-left">
                            <h2 className='text-capitalize'>Update {curElem.type} Data</h2>
                        </div>
                    </div>
                    <span className="modalClose" onClick={handleClose}><AiOutlineClose /></span>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="input-wrap">
                        <label>Content</label>
                        <div className='inp-outer'>
                            <textarea
                                className="inp alt"
                                type="text"
                                value={text}
                                style={{height:500}}
                                onChange={(e) => setText(e.target.value)}
                                rows={10}
                                required
                            />
                        </div>
                    </div>
                    <div className="input-wrap text-center">
                        <button className="theme-btn bdr" type='button' onClick={handleClose}><span>Cancel</span></button>
                        <button className="theme-btn ms-2" type='submit'><span>{loader.submit ? <>Updating <FaSpinner className="spin" /></> : "Update"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EditKnowledge