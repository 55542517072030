import React from 'react'

const DfyCourse = () => {
    return (
        <div className="upgrade-tab-in">
            <div className="upgrade-tab-block">
                <h6>DFY Courses</h6>
            </div>
            <div className="upgrade-tab-block">
                <div className="row">
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 1 </span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 11 </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 2</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 12</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 3</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 13</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 4</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 14</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 5</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 15</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 6</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 16</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                target="_blank"
                            >
                                {" "}
                                <span> Download DFY Course 7</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip"
                                target="_blank"
                            >
                                {" "}
                                <span> Download DFY Course 17</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                target="_blank"
                            >
                                {" "}
                                <span> Download DFY Course 8</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 18</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 9</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 19</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 10</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                className="theme-btn mt-3"
                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip"
                                target="_blank"
                            >
                                <span> Download DFY Course 20</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DfyCourse