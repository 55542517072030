import React, { useEffect, useState } from "react";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import ProfileSidebar from "../ProfileSidebar";
import TitleBar from "../../../CommonComponents/TitleBar";
import { useDispatch } from "react-redux";
import { fetchData } from "../../../../Redux/Actions/CommonActions";
import UserRows from "./UserRows";
import { FaSpinner } from "react-icons/fa";

const WhiteLabelUser = () => {
    const dispatch = useDispatch()
    const [users, setUsers] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchUsers = () => {
        let obj = {}
        dispatch(fetchData("fetch-wl-users", obj, setUsers, loader, setLoader))
    }

    useEffect(() => {
        fetchUsers()
    }, [])
    return (
        <>
            <TitleBar title="White Label" />
            <Header />

            <div className="site-wrapper">
                <div className="site-container">
                    <div className="account-wrap">
                        <ProfileSidebar />

                        <div className="account-right">

                            <div className="titleBar">
                                <div className="titleBar-left">
                                    <h2>Users</h2>
                                    <p>Create and Manage User</p>
                                </div>
                            </div>

                            <div className="dashboard-block">

                                <table className="table table-bordered theme-table">
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-end">Action</th>
                                    </tr>
                                    {users.length > 0 ?
                                        users.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <UserRows
                                                        curElem={curElem}
                                                        fetchUsers={fetchUsers}
                                                        users={users}
                                                        setUsers={setUsers}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <tr className="text-center mt-2">
                                            <td colSpan={2}>
                                                {loader.fetch ?
                                                    <FaSpinner size={25} className="spin p-0" /> : "You do not have users created yet!"}
                                            </td>
                                        </tr>
                                    }
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default WhiteLabelUser;