import React from 'react'
import { useSelector } from 'react-redux'
import { appName } from '../../../Global/Global'

const WebHosting = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <div className="upgrade-tab-in">
            <div className="upgrade-tab-block">
                <h6>Web Hosting</h6>
                <p className="text-center pt-3">
                    Please raise a support ticket with your {rebrand ? rebrand.name : appName} purchase transaction ID, your domain name that you
                    currently own and wish to use with the hosting, email
                    address, your full name and a note about how you would
                    be using the webhosting for your business.
                </p>
            </div>
            <div className="upgrade-tab-block text-center">
                <a
                    rel="noreferrer"
                    href="https://support.vineasx.com/"
                    target="_blank"
                    className="theme-btn mt-3"
                >

                    <span>Raise a Ticket</span>
                </a>

            </div>
        </div>
    )
}

export default WebHosting