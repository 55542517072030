import React, { useEffect, useState } from "react";
import { BsStars } from "react-icons/bs"
import { HiDotsVertical } from "react-icons/hi"
import { MdOutlineFileDownload } from "react-icons/md"
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from "react-redux";
import { onCreateGodMode, onExecuteGodMode, onResetTaskLimit } from "../../../../../Redux/Actions/GodModeAction";
import { setAlert } from "../../../../../Redux/Actions/AlertActions";
import { FaSpinner } from "react-icons/fa";
import { Document, Page, View, Text, StyleSheet, Font, usePDF } from '@react-pdf/renderer';


const AutoMode = ({ curElem, messages, setMessages, activeConv, index }) => {
    const dispatch = useDispatch()
    const godModeData = useSelector(state => state.godMode.data)
    const [taskLimit, setTaskLimit] = useState(godModeData.maxTask)
    const [pdf, setPdf] = useState(false)
    const [selectedMsg, setSelectedMsg] = useState(false)
    const [instance, updateInstance] = usePDF({
        document: pdf
    })

    const [assiData, setAssiData] = useState({
        image: "",
        name: ""
    })

    const handleSubmit = () => {
        let doneTasks = 0
        curElem.tasks.forEach((curE) => {
            if (curE.status === 2) {
                doneTasks++
            }
        })
        if (+taskLimit > +doneTasks) {
            dispatch(onResetTaskLimit(taskLimit))
            // handleClose(false)
            if (curElem.results[curElem.results.length - 1].message === "Task limit reached") {
                let data = { ...godModeData }
                let taskData = curElem.tasks.find(({ status }) => +status === 0)
                if (taskData) {
                    data.task = taskData.task
                    dispatch(onExecuteGodMode(data, messages, setMessages))
                } else {
                    dispatch(onCreateGodMode(messages, setMessages))
                }

            }
        } else {
            dispatch(setAlert(`Your ${doneTasks} task${doneTasks === 1 ? "" : "s"} ${doneTasks === 1 ? "is" : "are"} done. Please provide limit more than ${doneTasks}!`, "danger"))
        }
    }

    const handleAction = (type) => {
        let documentName = "file"
        let data = "", newIndex = 1

        if (type === "doc") {
            if (curElem.results.length > 0) {
                curElem.results.forEach((curE, index) => {
                    if (index === 0) {
                        data += `Goal : ${curElem.goal}\n\n`
                    }
                    if (curE.type === "response") {
                        data += `${newIndex}: ${curE.task}\n\nResponse:\n${curE.message}\n\n\n`
                        newIndex++
                    }
                })
            }
            let fileDownload = document.createElement("a");
            fileDownload.href = `data:application/vnd.ms-word;charset=utf-8,` + encodeURIComponent(data)
            fileDownload.download = `${documentName}.doc`;
            fileDownload.click();
        } else {
            handleGeneratePDF(curElem.results)
            setSelectedMsg(index)
        }
    }

    const handleGeneratePDF = (resultArr) => {
        const styles = StyleSheet.create({
            section: {
                padding: 5,
                flexGrow: 1,
                fontSize: 10,
                border: "1px solid black"
            },
            footer: {
                fontSize: 11,
                position: "absolute",
                bottom: 11,
                right: 12,
            }
        })
        let newIndex = 0
        setPdf(
            <Document >
                <Page style={{ padding: 10 }}>
                    <View style={styles.section}>
                        {resultArr.length > 0 ?
                            resultArr.filter((curElem) => curElem.type === "response").map((curElem, index) => {
                                if (curElem.type === "response") {
                                    newIndex++
                                }
                                return (
                                    <React.Fragment key={index}>
                                        {index === 0 ?
                                            <>
                                                <View style={{ fontWeight: 600, marginBottom: 4, fontSize: 13 }}>
                                                    {/* <Text>Goal: {curElem.goal}</Text> */}
                                                </View>
                                            </>
                                            : ""}
                                        {
                                            curElem.type === "response" ?
                                                <>
                                                    <View style={{ paddingTop: 8, paddingBottom: 4, fontSize: 12, fontWeight: "bold" }}>
                                                        <Text>{newIndex}{": "} {curElem?.task}</Text>
                                                    </View>
                                                    <View style={{ marginTop: 4, fontWeight: "bold", fontSize: 11 }}>
                                                        <Text>Response: </Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 3, marginBottom: 6 }}>
                                                        <Text>{curElem.message}</Text>
                                                    </View>
                                                </> : ""
                                        }

                                    </React.Fragment>
                                )
                            })

                            : ""}

                        <View style={styles.footer}>
                            <Text>This PDF is generated by AIOffices.</Text>
                        </View>
                    </View>
                </Page>
            </Document >)
    }


    useEffect(() => {
        if (curElem && activeConv.staff.length > 0) {
            let staffData = activeConv.staff.find(({ id }) => +id === +curElem.staffId)
            if (staffData) {
                setAssiData({
                    ...assiData,
                    image: staffData.image,
                    name: staffData.name
                })
            }
        }
    }, [curElem])


    useEffect(() => {
        if (pdf && (index === selectedMsg)) {
            setTimeout(() => {
                updateInstance({
                    document: pdf
                })
            },1000)
           
        }
    }, [pdf])

    useEffect(() => {
        if (instance.url && (index === selectedMsg)) {
            let a = document.createElement('a')
            a.href = instance.url
            a.download = "file" + { index } + ".pdf"
            a.click()
            setSelectedMsg(false)
            setPdf(false)
        }
    }, [instance])



    return (
        <>
            {/* <ChatHeaderAuto /> */}
            <div className="message-wrap auto-message">
                <div className="message-single">
                    <div className="message-group">
                        <div className="user-photo">
                            <img alt="" src={assiData.image} />
                            <div className="user-photo-mark"><img alt="" src={require('../../../../../assets/images/star.png')} /></div>
                        </div>
                        {/* <div className="message-head">
                            <span></span>
                            <span>09:00 AM</span>
                        </div> */}
                        <div className="message-body">
                            <div className="message-txt">
                                <div className="auto-header">
                                    <h3>
                                        Goal : {curElem.goal}
                                    </h3>
                                    <div className="user-nav">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                                <div className="drop-icon"><MdOutlineFileDownload /> Export</div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <ul>
                                                    {/* <li><a style={{ cursor: "pointer" }} onClick={() => handleAction("pdf")}><MdOutlineFileDownload /> PDF Export</a></li> */}
                                                    <li><a style={{ cursor: "pointer" }} onClick={() => handleAction("doc")}><MdOutlineFileDownload /> DOC Export</a></li>
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>


                                <div className="theme-accord">
                                    <Accordion defaultActiveKey={0}>
                                        {curElem.results.length > 0 ?
                                            curElem.results.map((curElem, index) => {
                                                return (
                                                    curElem.type === "response" ?
                                                        <>
                                                            <Accordion.Item eventKey={index} key={index}>
                                                                <Accordion.Header>
                                                                    <div>
                                                                        <p>Added task <img alt="" src={require('../../../../../assets/images/tick.png')} /></p>
                                                                        <span>{curElem.task}</span>
                                                                    </div>
                                                                </Accordion.Header>
                                                                <Accordion.Body>

                                                                    <p dangerouslySetInnerHTML={{ __html: curElem.message.replaceAll('\n', `<br/>`) }} />
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </>
                                                        :
                                                        curElem.type === "limitReach" ?
                                                            <div className="accordion-item p-4" key={index}>
                                                                <span style={{ fontWeight: "bold" }}>{curElem.message}</span>
                                                            </div>
                                                            :
                                                            ""
                                                )
                                            })
                                            : ""
                                        }
                                    </Accordion>
                                </div>
                            </div>
                            {messages.length - 1 === index ?
                                <div className="task-addition">
                                    <p>Maximum Tasks</p>
                                    <input
                                        className="inp alt"
                                        type="number"
                                        placeholder='Enter max task here'
                                        value={taskLimit}
                                        onChange={(e) => setTaskLimit(e.target.value)}
                                    />
                                    <button onClick={handleSubmit} className="theme-btn"><span><BsStars /> Add Task</span></button>
                                </div> : ""}

                            {/* <div className="user-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="g" id="dropdown-basic">
                                        <div className="drop-icon"><HiDotsVertical /></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            <li><Link to="/">Action</Link></li>
                                            <li><Link to="/">Another action</Link></li>
                                            <li><Link to="/">Something else</Link></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AutoMode;