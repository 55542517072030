import React, { useEffect, useState } from "react";
import { RiRobot2Fill } from 'react-icons/ri'
import { AiOutlineEyeInvisible } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom";
import LoginHeader from "../../Header/LoginHeader";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, onFetchWhitelabel } from "../../../Redux/Actions/AuthActions";
import { appName } from "../../../Global/Global";
import { FaSpinner } from "react-icons/fa";
import TitleBar from "../../CommonComponents/TitleBar";

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const rebrandData = useSelector(state => state.rebrand)
    const auth = useSelector(state => state.auth)

    const [view, setView] = useState(false)
    const [loader, setLoader] = useState(false)
    const [loaderWl, setLoaderWl] = useState({
        fetch: true
      })
    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: ""
    })

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate('/dashboard')
        }
    }, [auth])


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(loginUser(loginInfo, setLoader))
    }

    const fetchWhiteLabel = () => {
        setLoaderWl({
          ...loaderWl,
          fetch: true
        })
        let obj = { domain: window.location.host }
        dispatch(onFetchWhitelabel(obj, loaderWl, setLoaderWl))
      }
    
      useEffect(() => {
        fetchWhiteLabel()
      }, [])


    return (
        <>
            <TitleBar title="Login" />
            <div className="login-header"><LoginHeader /></div>
            <div className="login-wrap">

                <div className="login-left">
                    <h2>Welcome to <span>{rebrandData?.data?.name ? rebrandData?.data?.name : appName}</span></h2>
                    <p className="pt-3">Launch your very own AI HR Agency —fully white-label the platform, rebrand it under your business name, including the URL, and offer AI-powered teams as your own service to clients. Step into the future where AI innovation and new revenue streams are just a click away.</p>
                </div>

                <div className="login-right">
                    <div className="login-box">
                        <div className="symbol"><RiRobot2Fill /></div>
                        <h2>Login to {rebrandData?.data?.name ? rebrandData?.data?.name : appName}</h2>
                        <form className="login-form" onSubmit={handleSubmit}>
                            <div className="input-wrap">
                                <label htmlFor="">Email Address</label>
                                <div className="inp-outer">
                                    <input
                                        className="inp"
                                        type="email"
                                        placeholder="Email Address"
                                        value={loginInfo.email}
                                        onChange={handleChange}
                                        name="email"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="input-wrap">
                                <label htmlFor="">Password</label>
                                <div className="inp-outer">
                                    <input
                                        className="inp"
                                        type={view ? "text" : "password"}
                                        placeholder="Password"
                                        required
                                        onChange={handleChange}
                                        value={loginInfo.password}
                                        name="password"
                                    />
                                    <span
                                        className="inp-icon"
                                        onMouseUp={() => setView(false)}
                                        onMouseLeave={() => setView(false)}
                                        onMouseDown={() => setView(true)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <AiOutlineEyeInvisible />
                                    </span>
                                </div>
                            </div>
                            <div className="input-wrap">
                                <button className="theme-btn full" type="submit"><span>Login {loader ? <FaSpinner className="spin ms-1" /> : null}</span></button>
                            </div>
                        </form>
                        <div className="log-option text-center">Forgot your account details? <Link to="/forgot-password">Click here</Link></div>
                    </div>
                </div>
            </div>
            <div className="login-footer"><Footer /></div>
        </>
    )
}

export default Login;