import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { BsFillEnvelopeFill } from "react-icons/bs"
import { FaUserCircle, FaSpinner } from "react-icons/fa"
import ProfileSidebar from "./ProfileSidebar";
import TitleBar from "../../CommonComponents/TitleBar";
import { changeProfile, onUpdatePassword, onUpdateUser } from "../../../Redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { FiUploadCloud } from "react-icons/fi";

const MyAccount = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        user: false,
        password: false,
        image: false
    })
    const [membershipName, setMembershipName] = useState("")
    const [user, setUser] = useState({
        name: "",
        email: "",
    })

    const [password, setPassword] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }
    console.log(auth.user, "membershipNamemembershipName")
    const handlePassword = (e) => {
        const { name, value } = e.target
        setPassword({
            ...password,
            [name]: value
        })
    }


    const onImageChange = (e) => {
        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
            if (e.target.files[0].size < 5000000) {
                const formData = new FormData()
                formData.append('user_id', auth.user.id);
                formData.append('file', e.target.files[0]);
                setLoader({
                    ...loader,
                    image: true
                })
                dispatch(changeProfile(formData, loader, setLoader))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#000"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected invalid file type!',
                confirmButtonColor: "#000"
            })
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            user: true
        })
        delete user.email
        dispatch(onUpdateUser(user, loader, setLoader))
    }
    const updatePassword = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (password.new_password) {
            if (pattern.test(password.new_password)) {
                if (password.new_password === password.confirm_password) {
                    setLoader({
                        ...loader,
                        password: true
                    })
                    dispatch(onUpdatePassword(password, loader, setLoader))
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }

            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    useEffect(() => {
        if (auth.user) {
            setUser({
                ...user,
                name: auth.user.name,
                email: auth.user.email,
            })
            if (auth.user.membership) {
                let strMem = auth.user.membership.split("__")[auth.user.membership.split("__").length - 1]
                setMembershipName(strMem)
            }
        }
    }, [auth])

    return (
        <>
            <TitleBar title="My Account" />
            <Header />

            <div className="site-wrapper">
                <div className="site-container">

                    <div className="account-wrap">
                        <ProfileSidebar />

                        <div className="account-right">
                            <div className="titleBar">
                                <div className="titleBar-left">
                                    <h2>My Account</h2>
                                    <p>Create and Manage Account</p>
                                </div>
                            </div>
                            <div className="dashboard-block">
                                <h2 className="account-title">Basic Info</h2>
                                <div className="info-img">
                                    {loader.image ?
                                        <div className="d-flex align-items-center justify-content-center" style={{ height: "100%", fontSize: 20 }}>
                                            <FaSpinner className="spin" />
                                        </div>
                                        :
                                        <>
                                            <img src={auth.user.profile} alt="" />
                                            <div className="account-pic-upload">
                                                <FiUploadCloud />
                                                <input type="file" onChange={onImageChange} />
                                            </div>
                                        </>}
                                    <button className="theme-btn text-capitalize my-2"><span>{membershipName === "ultimate" ? "Unlimited" : membershipName}</span></button>
                                    {/* <img alt="" src={auth.user.profile} />
                                    <div className="edit-img-btn"><MdModeEdit /></div> */}
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="input-wrap mt-5">
                                        <label htmlFor="">Name</label>
                                        <div className="inp-outer icon-left">
                                            <input
                                                className="inp alt"
                                                type="text"
                                                name="name"
                                                value={user.name}
                                                onChange={handleChange}
                                                required

                                            />
                                            <span className="inp-icon"><FaUserCircle /></span>
                                        </div>
                                    </div>
                                    <div className="input-wrap">
                                        <label htmlFor="">Email Address</label>
                                        <div className="inp-outer icon-left">
                                            <input
                                                className="inp alt"
                                                type="email"
                                                name='email'
                                                value={user.email}
                                                readOnly
                                            />
                                            <span className="inp-icon"><BsFillEnvelopeFill /></span>
                                        </div>
                                    </div>
                                    <div className="input-wrap text-end">
                                        <button className="theme-btn" type="submit"><span>{loader.user ? <>Updating <FaSpinner className="spin" size={14} /> </> : "Update"}</span></button>
                                    </div>
                                </form>
                            </div>

                            <form className="dashboard-block" onSubmit={updatePassword}>
                                <h2 className="account-title">Update Password</h2>

                                <div className="input-wrap">
                                    <label htmlFor="">Current Password</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp alt"
                                            type="password"
                                            name='password'
                                            placeholder='Enter your current password'
                                            onChange={handlePassword}
                                            required
                                        />
                                        {/* <span className="inp-icon"><AiOutlineEyeInvisible /></span> */}
                                    </div>
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">New Password</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp alt"
                                            type="password"
                                            name='new_password'
                                            placeholder='Enter your new password'
                                            onChange={handlePassword}
                                            required
                                        />
                                        {/* <span className="inp-icon"><AiOutlineEyeInvisible /></span> */}
                                    </div>
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Re-type New Password</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp alt"
                                            type="password"
                                            placeholder='Confirm password'
                                            name='confirm_password'
                                            onChange={handlePassword}
                                            required
                                        />
                                        {/* <span className="inp-icon"><AiOutlineEyeInvisible /></span> */}
                                    </div>
                                </div>
                                {passwordMsg.validation ?
                                    <p style={{ fontSize: 13, marginLeft: 5, color: "#DC143C" }}>{passwordMsg.msg}</p> : ""}
                                <div class="alert alert-primary mt-3" role="alert">Updating your password will log you out for re-login.</div>
                                <div className="input-wrap text-end">
                                    <button className="theme-btn"><span>{loader.password ? <>Updating <FaSpinner className="spin" size={14} /> </> : "Update"}</span></button>
                                </div>

                            </form>
                        </div>
                    </div>


                </div>
            </div>

            <Footer />
        </>
    )
}

export default MyAccount;