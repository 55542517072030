import React from 'react'

const ClientContract = () => {
    return (
        <div className="upgrade-tab-in">
            <div className="upgrade-tab-block">
                <h6>Client Contract</h6>
                <p className="pt-3">
                    Download our "Client Contract" template. This is a docx
                    file, works best with MS Word. Edit the doc and add your
                    business name, address and other details. Fill the empty
                    blank space with your service, eg Video Marketing, Local
                    Reputation Management, Social Media Marketing, Design
                    &amp; Creatives, Ads Management and so on.
                </p>
            </div>

            <div className="upgrade-tab-block text-center">
                <div className='row'>
                    <div className='col-md-4'>
                        <h6>Brief Pitch Template</h6>
                        <a
                            className="theme-btn mt-3"
                            href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <span>Download</span>
                        </a>
                    </div>
                    <div className='col-md-4'>
                        <h6>Service Agreement</h6>
                        <a
                            className="theme-btn mt-3"
                            href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <span>Download</span>
                        </a>
                    </div>
                    <div className='col-md-4'>
                        <h6>Meeting Setup Template</h6>
                        <a
                            className="theme-btn mt-3"
                            href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <span>Download</span>
                        </a>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default ClientContract