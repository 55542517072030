import { produce } from "immer"

const initialState = {
    list: [],
    create: {
        name: "",
        description: "",
        image: "",
        staff: []
    },
}

export const DepartmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_DEPARTMENT_LIST":
            return produce(state, (draft) => {
                draft.list = action.payload
            })
        case "ON_CHANGE_DEPARTMENT":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.create = {
                    ...draft.create,
                    [name]: value
                }
            })
        case "EDIT_DEPARTMENT":
            return produce(state, (draft) => {
                draft.create = action.payload
            })

        case "DELETE_DEPARTMENT":
            return produce(state, (draft) => {
                draft.list = draft.list.filter((curElem) => {
                    return +curElem.id !== +action.payload
                })
            })
        case "UNMOUNT_CREATE_DEPARTMENT":
            return produce(state, (draft) => {
                draft.create = initialState.create
            })

        case "UNMOUNT_DEPARTMENT_LIST":
            return produce(state, (draft) => {
                draft.list = []
            })


        default:
            return state
    }
}