import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

import { BiTrash, BiSolidCopy, BiChevronLeft } from "react-icons/bi"
import { BsCardImage, BsGlobeEuropeAfrica } from "react-icons/bs"
import { AiOutlineClose } from "react-icons/ai"

import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import queryString from "query-string";

const EmbedHeader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = queryString.parse(location.search)
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  return (
    <>
      <header className="site-header">
        <div className="site-container">
          <div className="site-header-main">
            <div className="backBtn alt">
              <a style={{ cursor: "pointer" }} className="back" onClick={() => navigate(-1)}><BiChevronLeft /></a>
              <h6>Create Your Embed</h6>
            </div>
            {/* <div className="header-right">
              <Link className="theme-btn bdr"><span>Next</span></Link>
              <Link className="theme-btn" onClick={handleShow2}><span>Create Embed</span></Link>
            </div> */}
          </div>
        </div>
      </header>

      {/* Add Member Modal */}
      <Modal className="VideoModal modal-700" show={show2} onHide={handleClose2} centered>
        <Modal.Body>
          <span className="modalClose abs" onClick={handleClose2}><AiOutlineClose /></span>
          <div className="">
            <div className="pt-4">

              <div className="confirmation-cont text-center">
                <div className="modalIcon"><BsCardImage /></div>
                <h5 className="pt-4">Your Embed “Johnsondoe”Create Successfully</h5>
                <p>You can copy and paste Code now</p>
              </div>

              <div className="">
                <div className="embed-block">
                  <div className="embed-block-top">
                    <div className="embed-block-top-left">
                      <h2>Header Code</h2>
                      <p>Insert this code in the header section between &#x3C;head&#x3E;&#x3C;/head&#x3E; tags in your page</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="embed-user-icon ms-2"><BiSolidCopy /></div>
                    </div>
                  </div>
                  <div className="code-block">
                    <p>&#x3C;script type=&#x22;text/Javascript&#x22; id=&#x22;ai_widget&#x22; src=&#x22;https://backend.aistaffs.com/front-end/chat-box/embed.js type=aiStaff&#x26;staffId=26&#x26;uId=3180&#x26;arId=0&#x26;arListId=0&#x26;icn=https://reeelapps-app.s3.us-west-2.amazonaws.com/aistaff/embed_logo/Embed_logo.png&#x26;webUrl=https://google.com&#x26;embId=158&#x22;&#x3E;&#x3C;/script&#x3E; </p>
                  </div>
                </div>

                <div className="embed-block">
                  <div className="embed-block-top">
                    <div className="embed-block-top-left">
                      <h2>Body Code</h2>
                      <p>Insert this code in the header section between &#x3C;head&#x3E;&#x3C;/head&#x3E; tags in your page</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="embed-user-icon ms-2"><BiSolidCopy /></div>
                    </div>
                  </div>
                  <div className="code-block">
                    <p>&#x3C;div id = &#x22;aistaff_chat_box&#x22;&#x3E;&#x3C;/div &#x3E; </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Add Member Modal */}

    </>
  )
}

export default EmbedHeader;