import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onStartGodMode = (data, messages, setMessages, assistant, setLoader) => (dispatch, getState) => {
    setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const lastIndex = updatedMessages.length;
        if (lastIndex >= 0) {
            updatedMessages[lastIndex] = {
                results: [],
                type: "godMode",
                tasks: [],
                staffId: assistant.id,
                goal: data.goal
            }
        }
        return updatedMessages;
    });

    // let newMessages = [...messages]
    // let obj1 = [{
    //     message: data.goal,
    // },
    // {
    //     message: "Thinking...",
    //     type: "thinking"
    // }
    // ]
    // newMessages[newMessages.length - 1].results = obj1
    // setMessages(newMessages)
    dispatch({ type: "ON_SET_GOAL_MESSAGE" })
    setLoader(false)
    commonAxios("god-mode-start", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let dataNew = res.data.tasks.map((curElem) => {
                    return {
                        ...curElem,
                        status: 0
                    }
                })
                let arr = dataNew.map((curElem) => {
                    return {
                        message: curElem.task,
                        enable: false,
                        id: curElem.id,
                        status: curElem.status,
                        type: "addedTask"
                    }
                })
                setMessages(prevMessages => {
                    const updatedMessages = [...prevMessages];
                    const lastIndex = updatedMessages.length - 1;
                    if (lastIndex >= 0) {
                        updatedMessages[lastIndex] = {
                            ...updatedMessages[lastIndex],
                            tasks: dataNew,
                            results: updatedMessages[lastIndex].results.concat(arr)
                        }
                    }
                    return updatedMessages;
                });
                dispatch({ type: "ON_SET_GOAL_RESPONSE", payload: res.data.goalId })
                let dataVal = {
                    ...data,
                    task: res.data.tasks[0].task,
                    taskId: res.data.tasks[0].id,
                    gId: res.data.goalId
                }
                if (getState().godMode.isGodModeActive) {
                    dispatch(onExecuteGodMode(dataVal, messages, setMessages))
                }

            } else {
                dispatch(setAlert(res.msg, "danger"))
            }

        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onExecuteGodMode = (data, messages, setMessages) => (dispatch, getState) => {
    let taskIndex, lastIndex
    setTimeout(() => {
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            lastIndex = updatedMessages.length - 1
            taskIndex = updatedMessages[lastIndex].tasks.findIndex(({ status }) => status === 0)
        });
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            updatedMessages[lastIndex].tasks[taskIndex].status = 1
            updatedMessages[lastIndex].results.push({
                message: "Thinking...",
                type: "thinking"
            })
        });
        commonAxios("god-mode-process", data, dispatch, getState().auth.token)
            .then((res) => {
                if (res.status) {
                    if (getState().godMode.isGodModeActive) {
                        let newRes = {
                            resVal: res.data,
                            taskId: data.taskId,
                            task: data.task,
                        }
                        dispatch({ type: "ON_EXECUTE_MODE", payload: newRes })
                        let doneTasks = 0, executedTaskIndex

                        setMessages(prevMessages => {
                            const updatedMessages = [...prevMessages];
                            executedTaskIndex = updatedMessages[lastIndex].tasks.findIndex(({ status }) => status === 1)
                        });

                        setMessages(prevMessages => {
                            const updatedMessages = [...prevMessages];
                            updatedMessages[lastIndex].tasks[executedTaskIndex].status = 2
                            updatedMessages[lastIndex].results.push({
                                message: res.data.response,
                                id: res.data.id,
                                type: "response",
                                task: data.task
                            })
                        });

                        setMessages(prevMessages => {
                            const updatedMessages = [...prevMessages];
                            updatedMessages[lastIndex].tasks.forEach((curElem) => {
                                if (curElem.status === 2) {
                                    doneTasks++
                                }
                            })
                            if (+getState().godMode.data.maxTask === doneTasks) {
                                updatedMessages[lastIndex].results.push({
                                    message: "Task limit reached",
                                    type: "limitReach"
                                })
                            } else {
                                dispatch(onCreateGodMode(messages, setMessages))
                            }
                        });
                    }
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
            }).catch((err) => {
                console.log(err)
            })
    }, 1000)
}


export const onCreateGodMode = (messages, setMessages) => (dispatch, getState) => {
    let lastIndex
    let data = getState().godMode.data

    setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        lastIndex = updatedMessages.length - 1
        updatedMessages[lastIndex].results.push({
            message: "Thinking...",
            type: "thinking"
        })
    });

    commonAxios("god-mode-create", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (getState().godMode.isGodModeActive) {
                    let tasksData = res.data.map((curElem) => {
                        return {
                            ...curElem,
                            status: 0
                        }
                    })

                    let arr = tasksData.map((curElem) => {
                        return {
                            message: curElem.task,
                            enable: false,
                            id: curElem.id,
                            status: curElem.status,
                            type: "addedTask"
                        }
                    })

                    setMessages(prevMessages => {
                        const updatedMessages = [...prevMessages];
                        if (lastIndex >= 0) {
                            updatedMessages[lastIndex] = {
                                ...updatedMessages[lastIndex],
                                tasks: updatedMessages[lastIndex].tasks.concat(tasksData),
                                results: updatedMessages[lastIndex].results.concat(arr)
                            }
                        }
                        return updatedMessages;
                    });


                    let newData = { ...data }
                    delete newData.completedTasks
                    delete newData.lastTask
                    delete newData.pendingTasks
                    let taskToExec
                    setMessages(prevMessages => {
                        const updatedMessages = [...prevMessages];
                        taskToExec = updatedMessages[lastIndex].tasks.find(({ status }) => status === 0)
                        if (taskToExec) {
                            newData.task = taskToExec.task
                            newData.taskId = taskToExec.id
                            setTimeout(() => {
                                dispatch(onExecuteGodMode(newData, messages, setMessages))
                            }, 1000)
                        }
                    });
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onCheckAPI = (setShow, selectedcId, staff, Swal, navigate) => (dispatch, getState) => {
    commonAxios("check-user-key", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = { cid: selectedcId, staff }
                dispatch(onSetInitialData(data))
                setShow(true)
            } else {
                Swal.fire({
                    title: 'API key is not available.',
                    html: "<p>Please go to settings page to add the API key!</p>",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#b32f3d',
                    cancelButtonColor: '#2a2c3a',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/settings')
                    }
                })
            }
        }).catch((err) => {
            console.log(err)
        })
}


// No Axios Here
export const onSetInitialData = (data) => (dispatch) => {
    dispatch({ type: "ON_SET_INITIALS", payload: data })
}

export const onUnsetInitialData = () => (dispatch) => {
    dispatch({ type: "ON_UNSET_INITIALS" })
}

export const onEnterGoalInfo = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_SET_GOAL", payload: data })
}

export const onStopGodMode = () => (dispatch) => {
    dispatch({ type: "ON_STOP_GOD_MODE" })
}
export const onResetTaskLimit = (limit) => (dispatch) => {
    dispatch({ type: "RESET_TASK_LIMIT", payload: limit })
}
