import { useDispatch, useSelector } from "react-redux"
import { forgetPassword } from "../../../Redux/Actions/AuthActions"
import { useState } from "react"
import LoginHeader from "../../Header/LoginHeader"
import { RiRobot2Fill } from "react-icons/ri"
import { appName } from "../../../Global/Global"
import { FaSpinner } from "react-icons/fa"
import { Link } from "react-router-dom"
import Footer from "../../Footer/Footer"
import TitleBar from "../../CommonComponents/TitleBar"

const ForgotPassword = () => {

  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [email, setEmail] = useState("")
  const rebrandData = useSelector(state => state.rebrand)

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      email: email
    }
    setLoader(true)
    dispatch(forgetPassword(data, setLoader))
  }
  return (
    <>
      <TitleBar title="Forgot Password" />
      <div className="login-header"><LoginHeader /></div>

      <div className="login-wrap">

        <div className="login-left">
          <h2>Welcome to <span>{rebrandData?.data?.name ? rebrandData?.data?.name : appName}</span></h2>
          <p className="pt-3">Launch your very own AI HR Agency —fully white-label the platform, rebrand it under your business name, including the URL, and offer AI-powered teams as your own service to clients. Step into the future where AI innovation and new revenue streams are just a click away.</p>
        </div>

        <div className="login-right">

          <div className="login-box">
            <div className="symbol"><RiRobot2Fill /></div>
            <h2>Forget Password</h2>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="input-wrap">
                <label htmlFor="">Email Address</label>
                <div className="inp-outer">
                  <input
                    className="inp"
                    type="email"
                    placeholder="Email Address"
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    required
                  />
                </div>
              </div>

              <div className="input-wrap">
                <button className="theme-btn full" type="submit"><span>{loader ? <>Submitting <FaSpinner className="spin ms-1" /></> : "Submit"}</span></button>
              </div>

            </form>

            <div className="log-option text-center">Remember? <Link to="/">Sign in</Link></div>

          </div>


        </div>

      </div>

      <div className="login-footer"><Footer /></div>
    </>
  )
}

export default ForgotPassword;