import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onChangeProject, onCreateProject, unmountCreateProject } from "../../../Redux/Actions/ProjectActions";
import TitleBar from "../../CommonComponents/TitleBar";
import { FaSpinner } from "react-icons/fa";
import Select from 'react-select'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import KnowledgeBase from "./KnowledgeBase/KnowledgeBase";
import { onFetchById } from "../../../Redux/Actions/CommonActions";
import { IoChatbubblesOutline } from "react-icons/io5";
import Header from "../../Header/Header";
import { IoIosArrowBack } from "react-icons/io";
import CreateProjConvModal from "./CreateProjConvModal";

const CreateProjects = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { cid, did, pid } = queryString.parse(location.search)
  const create = useSelector(state => state.project.create)
  const department = useSelector(state => state.department.create)


  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const [loader, setLoader] = useState({
    upload: false,
    create: false,
    fetch: pid ? true : false
  })

  const [tabs, setTabs] = useState({
    first: true,
    second: false,
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    dispatch(onChangeProject(name, value))
  }

  const onChooseStaff = (e) => {
    dispatch(onChangeProject("staff", e))
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    let obj = { ...create }
    let url = "create-project"
    if (create.id) {
      url = "update-project"
      obj.companyId = cid
      obj.departmentId = did
    } else {
      obj.companyId = cid
      obj.departmentId = did
    }
    setLoader({
      ...loader,
      create: true
    })
    dispatch(onCreateProject(url, obj, handleActiveTab, loader, setLoader))

  }

  const handleActiveTab = (type) => {
    if (type === "first") {
      setTabs({
        first: true,
        second: false
      })
    }
    if (type === "second") {
      setTabs({
        first: false,
        second: true
      })
    }
    if (type === "ifId" && create.id) {
      setTabs({
        first: false,
        second: true
      })
    }
  }


  const fetchDepartment = () => {
    let data = {
      id: did
    }
    dispatch(onFetchById("fetch-department", data, "EDIT_DEPARTMENT"))
  }


  const fetchProject = () => {
    let data = {
      id: pid
    }
    setLoader({
      ...loader,
      fetch: true
    })
    dispatch(onFetchById("fetch-project", data, "EDIT_PROJECT", loader, setLoader))
  }

  useEffect(() => {
    if (department.id) {
      dispatch(onChangeProject("staff", department.staff))
    }
  }, [department])

  useEffect(() => {
    if (pid) {
      fetchProject()
    }
  }, [pid])

  useEffect(() => {
    if (did) {
      fetchDepartment()
    }
  }, [did])

  useEffect(() => {
    return () => {
      dispatch(unmountCreateProject())
    }
  }, [])
  return (

    loader.fetch ?
      <div className="loader-sec">
        <div className="loader" />
      </div> :
      <>
        <TitleBar title="Projects" />
        <Header />

        <div className="site-wrapper">
          <div className="site-container">
            <div className="d-flex justify-content-between align-items-center mb-2 px-2">
              <div className="theme-btn" onClick={() => navigate(-1)}>
                <span><IoIosArrowBack size={25} />Back</span>
              </div>
              <button disabled={!create.id} onClick={handleShow} className="theme-btn" type="button" style={{ background: create.id ? "" : "gray", cursor: !create.id ? "not-allowed" : "" }}>
                <span><IoChatbubblesOutline /> Start Conversation</span>
              </button>
            </div>
            <div className="company-empty large">
              <div className="company-block">
                <div className="company-block-title">
                  <div>
                    <h3> {create.id ? "Update" : "Create"} Project Details</h3>
                    <p>Fill the Project details</p>
                  </div>
                </div>
              </div>

              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="nav-switch mt-5">
                  <Nav variant="pillsN">
                    <Nav.Item>
                      <Nav.Link onClick={() => handleActiveTab("first")} className={tabs.first ? 'active' : ""}><span>Basic Information</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => handleActiveTab("ifId")} className={tabs.second ? 'active' : ""}><span>Knowledge Base</span></Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

                <Tab.Content>
                  <Tab.Pane className={`${tabs.first ? "active show" : ""}`}>
                    <form onSubmit={handleSubmit}>
                      <div className="input-wrap">
                        <label htmlFor="">Project Name</label>
                        <div className="inp-outer">
                          <input
                            className="inp"
                            type="text"
                            placeholder="Type Project Name"
                            value={create.name}
                            onChange={handleChange}
                            name="name"
                            required
                          />
                        </div>
                      </div>
                      <div className="input-wrap">
                        <label htmlFor="">Description</label>
                        <div className="inp-outer">
                          <textarea
                            className="inp"
                            name="description"
                            value={create.description}
                            onChange={handleChange}
                            placeholder="Type Project Description"
                            required
                          />
                        </div>
                      </div>

                      <div className="input-wrap">
                        <label htmlFor="">Select Staffs</label>
                        <Select
                          options={department.staff}
                          className="inp-outer"
                          getOptionLabel={(e) => {
                            return e.name
                          }}
                          getOptionValue={(e) => {
                            return e.id
                          }}
                          value={create.staff}
                          isMulti={true}
                          classNamePrefix="react-select"
                          onChange={(e) => onChooseStaff(e)}
                        />
                      </div>

                      <div className="input-wrap">
                        <label htmlFor="">Select Status</label>
                        <div className="inp-outer">
                          <select
                            name="projectStatus"
                            className="inp"
                            value={create.projectStatus}
                            onChange={handleChange}
                            required
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="completed">Completed </option>
                          </select>
                        </div>
                      </div>

                      <div className="input-wrap">
                        {create.id ?
                          <button
                            className="theme-btn full"
                            type="submit"
                            style={{
                              background: create.name && create.description && create.staff.length > 0 ? "" : "gray",
                              cursor: create.name && create.description && create.staff.length > 0 ? "" : "not-allowed"
                            }}
                            disabled={create.name && create.description && create.staff.length > 0 ? false : true}
                          >
                            <span>{loader.create ? <>Updating <FaSpinner className="spin" /></> : "Update Project"}</span>
                          </button>
                          :
                          <button
                            className="theme-btn full"
                            type="submit"
                            style={{
                              background: create.name && create.description && create.staff.length > 0 ? "" : "gray",
                              cursor: create.name && create.description && create.staff.length > 0 ? "" : "not-allowed"
                            }}
                            disabled={create.name && create.description && create.staff.length > 0 ? false : true}
                          >
                            <span>{loader.create ? <>Creating <FaSpinner className="spin" /></> : "Create Project"}</span>
                          </button>
                        }
                      </div>
                    </form>
                  </Tab.Pane>

                  <Tab.Pane className={`${tabs.second ? "active show" : ""}`}>
                    <KnowledgeBase />
                  </Tab.Pane>

                </Tab.Content>

              </Tab.Container>



            </div>
          </div>
        </div>


        <CreateProjConvModal
          show={show}
          pid={create.id}
          cid={cid}
          did={did}
          handleClose={handleClose}
        />

        <Footer />
      </>
  )
}

export default CreateProjects;