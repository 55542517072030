import React, { useState } from "react";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

function HelpPost() {

    return(
        <>
        <Header></Header>

        <div className="site-wrapper">
           <div className="site-container">
            <div className="outer-container pb-5">
                <div className="titleBar">
                    <div className="titleBar-left">
                        <h2>Account Profile and Privacy Setting</h2>
                        <p>Select from Popular Topics</p>
                    </div>
                </div>
            </div>

              <div className="account-wrap for-post">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="account-left">
                        <Nav variant="b" className="flex-column tabNav">
                            <Nav.Item>
                              <Nav.Link eventKey="first">How to Hire/Fire staff</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second">youAi agent & Training your AI Staff</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="third">Account Profile and Privacy Setting</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fourth">Conversation and God mode Features</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fifth">Embedding AI Staff and on Your Website</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="sixth">General Setting , Work Hours and Integration</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="seventh">How to add Clients Accounts and Team Member Accounts</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="eighth">Video Training</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    
                    <div className="account-right">
                        <div className="dashboard-block mt-0">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="post-block">
                                        <h2>How to Hire/Fire Staffs</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisi porta lorem mollis aliquam. Et tortor at risus viverra. Potenti nullam ac tortor vitae purus faucibus ornare. Euismod elementum nisi quis eleifend quam adipiscing vitae. Sapien et ligula ullamcorper mal proin libero nunc consequat. Velit laoreet id donec ultrices tincidunt arcu. At consectetur lorem donec massa. Enim diam vulputate ut pharetra sit amet. Vel fringilla est ullamco eget nulla facilisi etiam. Est ante in nibh mauris. Ac felis donec et odio pellentesque. </p>
                                        <div className="post-block-img"><img alt="" src={require('../../../assets/images/screen-1.jpg')} /></div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisi porta lorem mollis aliquam. Et tortor at risus viverra. Potenti nullam ac tortor vitae purus faucibus ornare. Euismod elementum nisi quis eleifend quam adipiscing vitae. Sapien et ligula ullamcorper mal proin libero nunc consequat. Velit laoreet id donec ultrices tincidunt arcu. At consectetur lorem donec massa. Enim diam vulputate ut pharetra sit amet. Vel fringilla est ullamco eget nulla facilisi etiam. Est ante in nibh mauris. Ac felis donec et odio pellentesque. </p>
                                        <div className="post-block-img"><img alt="" src={require('../../../assets/images/screen-2.jpg')} /></div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisi porta lorem mollis aliquam. Et tortor at risus viverra. Potenti nullam ac tortor vitae purus faucibus ornare. Euismod elementum nisi quis eleifend quam adipiscing vitae. Sapien et ligula ullamcorper mal proin libero nunc consequat. Velit laoreet id donec ultrices tincidunt arcu. At consectetur lorem donec massa. Enim diam vulputate ut pharetra sit amet. Vel fringilla est ullamco eget nulla facilisi etiam. Est ante in nibh mauris. Ac felis donec et odio pellentesque. </p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisi porta lorem mollis aliquam. Et tortor at risus viverra. Potenti nullam ac tortor vitae purus faucibus ornare. Euismod elementum nisi quis eleifend quam adipiscing vitae. Sapien et ligula ullamcorper mal proin libero nunc consequat. Velit laoreet id donec ultrices tincidunt arcu. At consectetur lorem donec massa. Enim diam vulputate ut pharetra sit amet. Vel fringilla est ullamco eget nulla facilisi etiam. Est ante in nibh mauris. Ac felis donec et odio pellentesque. </p>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="post-block">
                                       <h2>youAi agent & Training your AI Staff</h2>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className="post-block">
                                       <h2>Account Profile and Privacy Setting</h2>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <div className="post-block">
                                       <h2>Conversation and God mode Features</h2>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth">
                                    <div className="post-block">
                                       <h2>Embedding AI Staff and on Your Website</h2>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sixth">
                                    <div className="post-block">
                                       <h2>General Setting , Work Hours and Integration</h2>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="seventh">
                                    <div className="post-block">
                                       <h2>How to add Clients Accounts and Team Member Accounts</h2>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="eighth">
                                    <div className="post-block">
                                       <h2>Video Training</h2>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
              </div> 
           </div>
        </div>
          
        <Footer></Footer>
        </>
    )
}

export default HelpPost;