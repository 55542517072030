import React, { useState } from 'react'
import wordpress from "../../../assets/images/round-icon-wordpress.png";
import download from "../../../assets/images/round-icon-download.png";
import importIcon from "../../../assets/images/round-icon-import.png";
import customize from "../../../assets/images/round-icon-customize.png";
import { AiOutlineClose } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import youtubeimg from "../../../assets/images/youtubeimg.jpg";
import { FaPlay } from 'react-icons/fa';
const AgencyWebsite = () => {

    const [state, setState] = useState(false);
    const handleClose = () => setState(false)
    const [frameUrl, setFrameUrl] = useState();

    const addFrameUrl = () => {
        setState(true);
        setFrameUrl("https://player.vimeo.com/video/514577280?");
    };

    return (
        <div className="upgrade-tab-in">
            <div className="upgrade-tab-block">
                <h6>Agency</h6>
            </div>
            <div className="upgrade-tab-block">
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="upgrade-tab-block">
                            <div className="row">
                                <div className="col-md-2 pr-0 text-center ">
                                    <img src={wordpress} alt="wprdpress" />
                                </div>
                                <div className="col-md-10">
                                    <p className="m-0">
                                        Fresh Install and configure wordpress at your
                                        server. If you need help in installing and
                                        configuring wordpress, Click here to find the
                                        tutorial.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="upgrade-tab-block">
                            <div className="row">
                                <div className="col-md-2 pr-0 text-center ">
                                    <img src={download} alt="download" />
                                </div>
                                <div className="col-md-10">
                                    <p className="m-0">
                                        Once wordpress is installed at your server.
                                        Download the site content by clicking the
                                        "Download site button". This will download a
                                        wpress file which you will be needing to
                                        import in your wordpress site.
                                    </p>
                                    <div className="row  d-flex justify-content-center">
                                        <div className="col-md-12">
                                            <div className="upgrade-box-content text-center  text-white">
                                                <div className="upgrade-para p-3">
                                                    <a
                                                        className='theme-btn mt-3'
                                                        rel="noreferrer"
                                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress"
                                                        target="_blank"
                                                    >
                                                        <span>Download the site</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="upgrade-tab-block">
                            <div className="row">
                                <div className="col-md-2 pr-0 text-center ">
                                    <img src={importIcon} alt="import" />
                                </div>
                                <div className="col-md-10">
                                    <p className="m-0">
                                        Import the xml file in to your wordpress site.
                                        If you need help importing the xml file in
                                        wordpress, Click here to find the tutorial.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="upgrade-tab-block">
                            <div className="row">
                                <div className="col-md-2 pr-0 text-center ">
                                    <img src={customize} alt="customize" />
                                </div>
                                <div className="col-md-10">
                                    <p className="m-0">
                                        Customize the site as according to your need.
                                        If you need help customizing the site. Click
                                        here to find the tutorial
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="upgrade-tab-block">
                <h6>Tutorial</h6>
            </div>
            <div className="upgrade-tab-block">
                <div className="row">
                    <div className="col-md-12">
                        <div className="upload-box-content">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tutorial-content">
                                        <div className="row">
                                            <div className="col-md-2 text-center">
                                                <img src={wordpress} alt="wordpress" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="upgrade ml-lg-4">
                                                    <a
                                                        rel="noreferrer"
                                                        className='theme-btn mt-3'
                                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                                                        target="_blank"
                                                    >
                                                        <span>Install WordPress From cPanel</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-2 text-center">
                                                <img src={importIcon} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="upgrade ml-lg-4">
                                                    <a
                                                        className='theme-btn mt-3'
                                                        rel="noreferrer"
                                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                                                        target="_blank"
                                                    >
                                                        <span>Import Website </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-2 text-center">
                                                <img src={customize} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="upgrade ml-lg-4">
                                                    <a
                                                        className='theme-btn mt-3'
                                                        rel="noreferrer"
                                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                                                        target="_blank"
                                                    >
                                                        <span>Customize website</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-2 text-center">
                                                <img src={download} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="upgrade ml-lg-4">
                                                    <a
                                                        className='theme-btn mt-3'
                                                        rel="noreferrer"
                                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                                                        target="_blank"
                                                    >
                                                        <span>Upload Video and Usage in Website</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="upgrade-tab-block">
                <h6>How to Install the Agency Website</h6>
            </div>
            <div className="upgrade-tab-block">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card fb-card border-0">
                            <div
                                className="card-header text-center"
                                style={{ background: "rgb(0, 123, 255)" }}
                            >
                                <h6 className="text-white Account-Management-headings">
                                    How to Install the Agency Website
                                </h6>
                            </div>
                            <div className="card-body p-0">
                                <img
                                    src={youtubeimg}
                                    alt=""
                                    className="img-fluid"
                                />
                                <span
                                    className="youtubeicon"
                                    onClick={(e) => addFrameUrl()}
                                    style={{ cursor: "pointer", color: "#007bff" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#MyModal"
                                >
                                    <FaPlay />
                                </span>
                            </div>
                            <div></div>
                        </div>


                        <Modal className={`theme-modal video-modal xl`} show={state} onHide={handleClose} centered>
                            <Modal.Body>
                                <div className='modalClose' onClick={handleClose}><AiOutlineClose /></div>
                                <div className="ratio ratio-16x9">
                                    <iframe class="embed-responsive-item" src={frameUrl} width="1920" height="1074" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="Walkthrough"></iframe>
                                </div>
                            </Modal.Body>
                        </Modal>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default AgencyWebsite