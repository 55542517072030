import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeDirectory, onCreateDirectory } from '../../../../Redux/Actions/DirectoryActions'
import { useNavigate } from 'react-router-dom'
import { FaSpinner } from 'react-icons/fa'

const CreateDirectoryModal = ({ show, handleClose, activeCompany }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const createDirectory = useSelector(state => state.directory.create)
    const [loader, setLoader] = useState({
        create: false
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeDirectory(name, value))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { ...createDirectory }
        data.cid = activeCompany.id
        setLoader({
            ...loader,
            create: true
        })
        let url = "create-directory"
        dispatch(onCreateDirectory(url, data, navigate, loader, setLoader))
    }

    return (
        <Modal className="VideoModal sm-modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <span className="modalClose abs" onClick={handleClose}><AiOutlineClose /></span>
                <form className="" onSubmit={handleSubmit}>
                    <div className="company-block-title">
                        <div>
                            <h3>Create New Directory</h3>
                            <p>Give a directory  name to start training your custom AI</p>
                        </div>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="">Directory Name</label>
                        <div className="inp-outer">
                            <input
                                type="text"
                                className="inp"
                                placeholder="Type Directory Name"
                                value={createDirectory.name}
                                name='name'
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="">Directory Description</label>
                        <div className="inp-outer">
                            <textarea
                                className="inp"
                                placeholder="Write Directory Description"
                                value={createDirectory.description}
                                name='description'
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="input-wrap">
                        <button className="theme-btn full" type='submit'><span>{loader.create ? <>Creating <FaSpinner className='spin' /></> : "Create Directory"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateDirectoryModal