import { produce } from "immer"

const initialState = {
    list: [],
    create: {
        name: "",
        description: "",
    },
    updateData: {
        main: false
    }
}

export const DirectoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_DIRECTORY_LIST":
            return produce(state, (draft) => {
                draft.list = action.payload
            })

        case "ON_FETCH_DIRECTORY":
            return produce(state, (draft) => {
                draft.updateData.main = action.payload
            })


        case "DELETE_DIRECTORY":
            return produce(state, (draft) => {
                draft.list = draft.list.filter((curElem) => {
                    return +curElem.id !== +action.payload
                })
            })

        case "ON_CHANGE_DIRECTORY":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.create = {
                    ...draft.create,
                    [name]: value
                }
            })
        default:
            return state
    }
}