import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../Redux/Actions/AuthActions'
import { FaUserCircle, FaKey, FaShareAlt, FaUsersCog } from "react-icons/fa"
import { BiSupport, BiLogOut } from "react-icons/bi"
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom'

import { MdOutlineInbox } from "react-icons/md";

const UserNav = () => {
    const dispatch = useDispatch()

    const auth = useSelector(state => state.auth)

    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])
    const handleOut = () => {
        dispatch(logoutUser())
    }
    return (
        <div className="user-nav">
            <Dropdown>
                <Dropdown.Toggle variant="n" id="dropdown-basic">
                    <div className="user-img">
                        <div className="user-img-in"><img alt="" src={auth.user.profile} /></div>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <ul>
                        {+auth.user.isClientAccount === 1 ? null :
                            <>
                                <li><Link to="/my-account"><span><FaUserCircle /></span> My Account</Link></li>
                                <li><Link to="/privacy"><span><FaKey /></span> Privacy</Link></li>
                                <li><Link to="/integrations"><span><FaShareAlt /></span> Integrations</Link></li>
                                {+auth.user.isVirtualAccount === 1 || +auth.user.isWL === 1 ? null :
                                    <>
                                        <li><Link to="/account-management"><span><FaUsersCog /></span> Account Management</Link></li>

                                        <li><Link to="/white-label"><span><MdOutlineInbox /></span>White Label</Link></li>
                                        {memberShip.includes("premium") || memberShip.includes("bundle") || memberShip.includes("fastpass") ?
                                            <li><Link to="/upgrades"><span><FaUsersCog /></span>Upgrades</Link></li> : null}
                                    </>}
                            </>}
                        <li><Link to="/help-support"><span><BiSupport /></span> Help and Support</Link></li>
                        <li style={{ cursor: "pointer" }} onClick={handleOut}><a ><span><BiLogOut /></span> Logout</a></li>
                    </ul>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default UserNav