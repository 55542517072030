import React from 'react'

const FbTemplates = () => {
    return (
        <div className="upgrade-tab-in">
            <div className="upgrade-tab-block">
                <h6>Facebook Ads Templates</h6>
                <p className="pt-3">Download this done-for-you Facebook and Instagram ad creatives and ad copy templates that can easily be customized to be used for any niche</p>
            </div>
            <div className="upgrade-tab-block">
                <h6>Download your Facebook Ads Templates here</h6>
                <a
                    className="theme-btn mt-3"
                    href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                    rel="noreferrer"
                    target="_blank"
                >
                    <span>Download the Templates</span>
                </a>
            </div>
        </div>
    )
}

export default FbTemplates
