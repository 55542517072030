import React from 'react'
import { BiLogOut, BiSupport } from 'react-icons/bi'
import { FaKey, FaShareAlt, FaUserCircle, FaUsersCog } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { MdOutlineInbox } from "react-icons/md";

import { LuFileEdit, LuUser } from "react-icons/lu";
import { RiBookmark3Line } from "react-icons/ri";
import { useSelector } from 'react-redux';

const ProfileSidebar = () => {
    const auth = useSelector(state => state.auth)
    return (
        <div className="account-left">
            <div className="account-nav">
                <ul>
                    {+auth.user.isClientAccount === 1 ? null :
                        <>
                            <li><NavLink to="/my-account"><span><FaUserCircle /></span> My Account</NavLink></li>
                            <li><NavLink to="/privacy"><span><FaKey /></span> Privacy</NavLink></li>

                            <li><NavLink to="/integrations"><span><FaShareAlt /></span> Integrations</NavLink></li>
                            {+auth.user.isVirtualAccount === 1 || +auth.user.isWL === 1? null :
                                <li><NavLink to="/account-management"><span><FaUsersCog /></span> Account Management</NavLink></li>
                            }
                        </>}
                    <li><NavLink to="/help-support"><span><BiSupport /></span> Help and Support</NavLink></li>
                    {+auth.user.isClientAccount === 1 || +auth.user.isVirtualAccount === 1 || +auth.user.isWL === 1 ? null :
                        <div className='whiteMenu'>
                            <li><NavLink to="/white-label"><span><MdOutlineInbox /></span> White Label</NavLink></li>
                            <div className='subMenu'>
                                <ul>
                                    <li><NavLink to="/white-label"><LuFileEdit /> Customization</NavLink></li>
                                    <li><NavLink to="/membership"><RiBookmark3Line /> Membership</NavLink></li>
                                    <li><NavLink to="/user"><LuUser /> Users</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    }

                    {/* <li><NavLink to="/"><span><BiLogOut /></span> Logout</NavLink></li> */}
                </ul>
            </div>
        </div>
    )
}

export default ProfileSidebar