import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { Modal } from "react-bootstrap";
import { FaPlay } from "react-icons/fa"
import { BsCheck, BsFillLayersFill, BsFillChatDotsFill, BsLaptop, BsFacebook } from "react-icons/bs"
import { RiUser3Fill } from "react-icons/ri"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Link, useLocation } from "react-router-dom";
import TitleBar from "../../CommonComponents/TitleBar";
import { appName } from "../../../Global/Global";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsedCredits, onFetchDbData, onFetchProfileStates } from "../../../Redux/Actions/CompanyActions";
import { IoCloseSharp } from "react-icons/io5";
import { unmountDepartmentList } from "../../../Redux/Actions/DepartmentActions";
import { fetchDataRedux, onFetchCredits } from "../../../Redux/Actions/CommonActions";
import HomeDepart from "./HomeDepart";
import RecentConv from "./RecentConv";
import StaffsList from "./StaffsList";
import TutorialVideos from "./TutorialVideos";

const Dashboard1 = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const auth = useSelector(state => state.auth)
    const activeCompany = useSelector(state => state.company.activeCompany)
    // const { cid } = queryString.parse(location.search)
    const rebrandData = useSelector(state => state.rebrand)

    const [memberShip, setMemberShip] = useState([])

    const [percent, setPercent] = useState(0)
    const [statics, setStatics] = useState({
        totalProjects: 0,
        totalConversation: 0,
        totalStaffs: 0
    })
    const [loader, setLoader] = useState(false)
    const [credits, setCredits] = useState([

    ])
    const progressPercentage = (credits.leftCredits / credits.totalCredits) * 100;
    const clampedProgress = Math.max(0, Math.min(100, progressPercentage));
    const [profileData, setProfileData] = useState({
        ishire: false,
        isCompany: false,
        isProject: false,
        isDepartment: false,
        isConversation: false
    })
    const onFetchdashboardData = () => {
        dispatch(onFetchCredits(setCredits))
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const fetchDbData = () => {
        let obj = {
            cid: activeCompany.id
        }
        dispatch(onFetchDbData(obj, setStatics))
    }

    const fetchProfileStates = () => {
        dispatch(onFetchProfileStates(setProfileData))
    }

    const fetchDepartment = () => {
        let data = {
            id: activeCompany.id
        }
        let url = "fetch-department-list"
        dispatch(fetchDataRedux(url, data, "FETCH_DEPARTMENT_LIST"))
    }

    // const fetchCredits = () => {
    //     let obj = {}
    //     dispatch(fetchUsedCredits(obj, setUsedCredits))
    // }

    // useEffect(() => {
    //     fetchCredits()
    // }, [])


    useEffect(() => {
        if (memberShip.length > 0) {
            if (memberShip.includes("ultimate")) {
                setCredits({
                    ...credits,
                    conv: 30000,
                    media: 3000
                })
            }
        }
    }, [memberShip])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        let isTrue = 0
        Object.keys(profileData).forEach((curElem) => {
            if (profileData[curElem]) {
                isTrue += 1
            }
        })
        setPercent((isTrue / 5) * 100)
    }, [profileData])

    useEffect(() => {
        if (activeCompany) {
            fetchDepartment()
            fetchDbData()
            return () => {
                dispatch(unmountDepartmentList())
            }
        }
    }, [activeCompany])

    useEffect(() => {
        fetchProfileStates()
    }, [])
    useEffect(() => {
        onFetchdashboardData()
    }, [])
    return (
        <>
            <TitleBar title="Dashboard" />
            <Header />

            <div className="site-wrapper">
                <div className="site-container">
                    <div className="hero-banner">
                        <div className="hero-banner-left">
                            <h1>Welcome to {rebrandData?.data?.name ? rebrandData?.data?.name : appName}!</h1>
                            <p>Unlock the power of AI-driven business solutions with your very own AI HR Agency. Customize and deploy AI-powered teams to handle key tasks like marketing, customer support, and admin while you focus on growing your business. White-label {rebrandData?.data?.name ? rebrandData?.data?.name : appName} to make it your own, rebrand the platform including the URL, and offer it as a service to your clients. Start your journey to smarter, automated business success now!</p>
                        </div>
                        <div className="hero-banner-right">
                            <div className="banner-right-video">
                                <div className="banner-right-video-in">
                                    <button onClick={handleShow}><FaPlay /> Watch Walkthrough</button>
                                </div>
                            </div>
                        </div>

                        <div className="hero-banner-video">
                            {/* <iframe src="https://player.vimeo.com/video/1013034813?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" style={{ height: "100%", width: "100%" }} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="AICallings BG Video"></iframe> */}

                            <video
                                autoPlay
                                loop
                                muted
                                playsInline
                                preload="metadata"
                                style={{ width: "100%" }}
                                src="https://reeelapps-app.s3.us-west-2.amazonaws.com/AiOffice/AI+Office+(1920X390).mp4"
                            >
                            </video>



                        </div>
                    </div>

                    <div className="dashboard-wrap">
                        <div className="dashboard-left">
                            <div className="dashboard-block profile-stat">
                                <div className="profile-stat-top">
                                    <div className="circlePro">
                                        <div className="circlePro-img"><img alt="" src={auth.user.profile} /></div>
                                        <CircularProgressbar value={percent}
                                            styles={buildStyles({
                                                strokeWidth: 100,
                                                circleRatio: 1,
                                            })}
                                        />
                                    </div>
                                    <div className="profile-stat-details">
                                        <h5>{auth.user.name}</h5>
                                        <p>{percent}% Profile Completed</p>
                                    </div>
                                </div>
                                <div className="profile-stat-list">
                                    <ul>
                                        <li className={profileData.ishire ? "active" : ""}><span><BsCheck /></span> Hire Staff</li>
                                        <li className={profileData.isCompany ? "active" : ""}><span><BsCheck /></span> Create Company</li>
                                        <li className={profileData.isDepartment ? "active" : ""}><span><BsCheck /></span>Create Department</li>
                                        <li className={profileData.isProject ? "active" : ""}><span><BsCheck /></span> Create Project</li>
                                        <li className={profileData.isConversation ? "active" : ""}><span><BsCheck /></span> Start Chat</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dashboard-block credits">
                                <h2 className="title">Credits</h2>
                                <div className="credit-list">
                                    <ul>
                                        <li>
                                            <div className="credit-icon"><img alt="" src={require('../../../assets/images/icon/chat.svg').default} /></div>
                                            <div className="credit-details">
                                                <h3>{credits.leftCredits}<span>Left</span></h3>
                                                <p>Conversation Credits</p>
                                                <div className="credit-progress">
                                                    <div className="credit-progress-bar" style={{ width: `${clampedProgress}%` }}></div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="credit-icon"><img alt="" src={require('../../../assets/images/icon/image.svg').default} /></div>
                                            <div className="credit-details">
                                                <h3>3000<span>Left</span></h3>
                                                <p>Media Credits</p>
                                                <div className="credit-progress">
                                                    <div className="credit-progress-bar" style={{ width: `100%` }}></div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dashboard-block tutorial">
                                <h2 className="title">Tutorials</h2>
                                <TutorialVideos />
                            </div>
                        </div>
                        <div className="dashboard-right">
                            <div className="row dash-row">
                                <div className="col-lg-4">
                                    <div className="dashboard-block credits">
                                        <div className="credits-item">
                                            <div className="credits-icon"><BsFillLayersFill /></div>
                                            <div className="credits-details">
                                                <h2>{statics.totalProjects}</h2>
                                                <p>Projects</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="dashboard-block credits">
                                        <div className="credits-item">
                                            <div className="credits-icon"><RiUser3Fill /></div>
                                            <div className="credits-details">
                                                <h2>{statics.totalStaffs}</h2>
                                                <p>Staffs</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="dashboard-block credits">
                                        <div className="credits-item">
                                            <div className="credits-icon"><BsFillChatDotsFill /></div>
                                            <div className="credits-details">
                                                <h2>{statics.totalConversation}</h2>
                                                <p>Conversations</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row dash-row">
                                <div className="col-lg-7">
                                    <RecentConv
                                        activeCompany={activeCompany}
                                    />
                                </div>
                                <div className="col-lg-5">
                                    <HomeDepart />
                                </div>
                            </div>

                            <StaffsList
                                activeCompany={activeCompany}
                            />
                            <div className="row dash-row">
                                <div className="col-lg-6">
                                    <div className="dashboard-block webnair">
                                        <h2>Check Our Free Webinar</h2>
                                        <p className="pt-2">Join us for a private training that will walk you through how to use {rebrandData?.data?.name ? rebrandData?.data?.name : appName} to help you create and sell videos for maximum engagement, views and profits. This training or upgrade will not be made available afterwards. Sign up and make sure to show up on this live training with all your questions.</p>
                                        <a className="theme-btn mt-3" href="https://www.abhineos.com/training" target="_blank"><span><BsLaptop /> Join Webinar</span></a>
                                        <div className="photo-frame"><img alt="" src={require('../../../assets/images/webnair.png')} /></div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="dashboard-block join-facebook">
                                        <div className="join-facebook-bar">
                                            <div className="join-facebook-bar-left">
                                                <h2>Join to Our Facebook Group</h2>
                                                <p>10000+ Members in Groups</p>
                                            </div>
                                            <div className="join-facebook-bar-right">
                                                <a href="https://aistaffs.com/fb" target="_blank" rel="nooper noreferrer" className="theme-btn" ><span><BsFacebook /> Join Now</span></a>
                                            </div>
                                        </div>
                                        <div className="facebook-img"><img alt="" src={require('../../../assets/images/facebook-group.png')} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />

            <Modal className="VideoModal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose">
                        <IoCloseSharp />
                    </div>
                    <div className="modalVidWrap">
                        <div className="modalVid">
                            <iframe src="https://player.vimeo.com/video/1013219027?share=copy" title="AIOffices Walkthrough" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Dashboard1;