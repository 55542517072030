import React from "react";

import { FiExternalLink } from "react-icons/fi"
import ReelAppsLogo from '../../assets/images/aisuits_logo.png';
import { useSelector } from "react-redux";

const Footer = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <>
            <footer className="site-footer">
                <div className="site-container">
                    <div className="site-footer-main">
                        <div className="footer-left">
                            <ul>
                                <li><a href="https://aioffices.io/terms.html" target="_blank" rel="noreferrer">TERMS |</a> </li>
                                <li><a href="https://aioffices.io/privacy.html" target="_blank" rel="noreferrer">PRIVACY |</a> </li>
                                <li><a href="http://support.vineasx.com/" target="_blank" rel="noreferrer">SUPPORT </a></li>
                            </ul>
                        </div>
                        <div className="footer-mid">
                            <p>You are interacting with AI system. Content Safety & Content Moderation Limits Applied. <br /> © 2024, All Rights Reserved to <a href="https://vineasx.com/" target="_blank">VineaSX Solutions LLC.</a> Developed and Maintained by <a href="http://www.vega6.com/" target="_blank">Vega6</a></p>
                        </div>
                        <div className="footer-right h-100">
                            <div className="reel-logo"><a href={rebrand.logo ? "" : "https://aisuites.io/"} target="_blank" rel="noreferrer"><img src={rebrand.logo ? rebrand.logo : ReelAppsLogo} alt={'logo'} /></a></div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;