import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaPlay } from 'react-icons/fa'
import { IoCloseSharp } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchVideos } from '../../../Redux/Actions/HelpActions'

const TutorialVideos = () => {
    const dispatch = useDispatch()
    const help = useSelector(state => state.help)
    const [url, setUrl] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loader, setLoader] = useState({
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
        })
        dispatch(fetchVideos(loader, setLoader))
    }

    const playVideo = (url) => {
        setUrl(url);
        handleShow()
    }

    const closeModal = () => {
        setUrl('');
        handleClose()
    }

    useEffect(() => {
        fetchHelpData()
    }, [])
    return (
        <div className="tutorial-list">
            <ul>
                {help.videos.length > 0 ?
                    help.videos.map((curElem, index) => {
                        return (
                            <li key={index}>
                                <a>
                                    <div className="tutorial-icon" onClick={() => playVideo(curElem.url)}><FaPlay /></div>
                                    <div className="tutorial-details">
                                        <h5>{curElem.title}</h5>
                                        {/* <p>01:20</p> */}
                                    </div>
                                </a>
                            </li>
                        )

                    }) : ""}
            </ul>

            <Modal className="VideoModal" show={show} onHide={closeModal} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose">
                        <IoCloseSharp />
                    </div>
                    <div className="modalVidWrap">
                        <div className="modalVid">
                            <iframe src={url} title="AIStaffs Walkthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TutorialVideos