import React, { useState } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { BiDotsHorizontalRounded, BiTrash } from 'react-icons/bi'
import { BsCardImage, BsCodeSlash, BsFillChatDotsFill, BsFire } from 'react-icons/bs'
import { MdEdit, MdOutlineRefresh } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineClose, AiFillClockCircle } from "react-icons/ai"
import WorkingHoursModal from './WorkingHoursModal'
import StaffHireModal from './HireModal/StaffHireModal'
import { useDispatch, useSelector } from 'react-redux'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import CommonAlert from '../../CommonComponents/CommonAlert'
import { FaTrash } from 'react-icons/fa6'
import { Tooltip } from "react-tooltip";

const StaffRows = ({ curElem, fetchStaffs, handleChange }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const company = useSelector(state => state.company)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Fire",
        loader: false

    })
    const [sweet1, setSweet1] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Fire",
        loader: false

    })
    const handleFireStaff = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Fire",
        })
    }

    const onDelete = () => {
        setSweet1({
            ...sweet1,
            enable: true,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Fire",
            loader: false
        })
    }

    const onCancelDelete1 = () => {
        setSweet1({
            ...sweet1,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id,
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Firing",
            loader: true
        })
        dispatch(onDeleteCampaign("fire-staff", data, false, false, setSweet, fetchStaffs))
    }

    const performDelete1 = () => {
        let data = {
            id: curElem.id,
        }
        setSweet1({
            ...sweet1,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-custom-staff", data, false, false, setSweet1, fetchStaffs))
    }

    const handleChat = () => {
        if (+curElem.isFire === 0) {
            navigate(`/chat-screen?cid=${curElem.cid}&sid=${curElem.id}&type=s`)
        }
    }


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);


    return (
        <div className="col-sm-6 col-lg-4 col-xl-3">
            <div className={`single-staff ${curElem.type === "youAiStaff" ? "intern" : ""}`}>
                <div className="staff-img">
                    <img alt="" src={curElem.image} />
                    {
                        curElem.type === "youAiStaff" ?
                            <span className="intern-tag">Custom Agent</span>
                            : ""
                    }
                </div>
                <div className="staff-txt">
                    <h6>{curElem.name}</h6>
                    <p>{curElem.category}</p>
                </div>
                <div className="single-staff-hov">
                    <div className="staff-name">
                        <div>
                            <h6>{curElem.name}</h6>
                            <p>{curElem.category}</p>
                        </div>
                        {curElem.type === "youAiStaff" ?
                            <div className="drop-style">
                                <Dropdown>
                                    <Dropdown.Toggle variant="t" id="dropdown-basic">
                                        <BiDotsHorizontalRounded />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            {+curElem.isFire === 0 ?
                                                <>
                                                    <li><Link to={`/embed-more?sid=${curElem.staffId}&hid=${curElem.id}`} ><BsCodeSlash /> Embed</Link></li>
                                                    {/* <li><a style={{ cursor: "pointer" }} onClick={handleShow3}><MdEdit /> Edit Details</a></li> */}
                                                    {+auth.user.isClientAccount === 1 ? null :
                                                        <>
                                                            <li><Link onClick={handleShow}><AiFillClockCircle /> Work Hours</Link></li>
                                                            <li><Link to={`/create-custom-ai?id=${curElem.id}&sid=${curElem.staffId}&cid=${company.activeCompany.id}`}><MdEdit /> Edit Details</Link></li>
                                                        </>
                                                    }
                                                </>
                                                :
                                                +auth.user.isClientAccount === 1 ? null :
                                                    <>
                                                        <li><Link to={`/create-custom-ai?id=${curElem.id}`}><MdEdit /> Edit Details</Link></li>
                                                        {+curElem.isFire === 1 ?
                                                            <li><a style={{ cursor: "pointer" }} onClick={onDelete}><FaTrash /> Delete</a></li> : ""}
                                                    </>
                                            }
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            :
                            +curElem.isFire === 0 ?
                                <div className="drop-style">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="t" id="dropdown-basic">
                                            <BiDotsHorizontalRounded />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ul>
                                                <>
                                                    <li><Link to={`/embed-more?sid=${curElem.staffId}&hid=${curElem.id}`} ><BsCodeSlash /> Embed</Link></li>
                                                    {+auth.user.isClientAccount === 1 ? null :
                                                        <>
                                                            <li><Link onClick={handleShow}><AiFillClockCircle /> Work Hours</Link></li>
                                                            <li><a style={{ cursor: "pointer" }} onClick={handleShow3}><MdEdit /> Edit Details</a></li>
                                                        </>
                                                    }
                                                </>

                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> : ""
                        }
                    </div>
                    <div className="staff-desc">
                        <p>{curElem.description}</p>
                    </div>
                    {+auth.user.isClientAccount === 1 ? null :
                        <div className="staff-btn">
                            {+curElem.isFire === 1 ?
                                <>
                                    <button
                                        onClick={handleShow3}
                                        className="theme-btn"
                                        style={{ background: company.list.length > 0 ? "" : "gray", cursor: company.list.length > 0 ? "" : "not-allowed" }}
                                        disabled={company.list.length > 0 ? false : true}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Please create a company to hire a staff."
                                    >
                                        <span> Hire</span>
                                    </button>
                                    {company.list.length > 0 ? null :
                                        <Tooltip id="my-tooltip" place="top-start" />
                                    }
                                </>
                                :
                                <>
                                    <button className="theme-btn" onClick={handleChat} ><span><BsFillChatDotsFill /> Chat</span></button>

                                    <button className="theme-btn bdr ms-2" onClick={handleFireStaff}><span><BsFire /> Fire</span></button>
                                </>}
                        </div>}
                </div>
            </div>

            <WorkingHoursModal
                curElem={curElem}
                show={show}
                handleClose={handleClose}
            />

            <StaffHireModal
                curElem={curElem}
                show={show3}
                handleClose={handleClose3}
                handleChange={handleChange}
            />

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to fire this staff?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />

            <CommonAlert
                show={sweet1.enable}
                message={"Are you sure you want to delete this staff?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet1.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete1}
                performDelete={performDelete1}
                alertLoader={sweet1.loader}
            />

        </div>
    )
}

export default StaffRows