import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom'
import CommonAlert from '../../CommonComponents/CommonAlert'
import { onDeleteData } from '../../../Redux/Actions/CommonActions'
import { useDispatch, useSelector } from 'react-redux'

import { IoChatbubblesOutline } from "react-icons/io5";
import { BiSolidEdit } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import CreateProjConvModal from './CreateProjConvModal'

const ProjectRows = ({ curElem }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const [active, setActive] = useState(false)

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (e) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteData("delete-project", data, "DELETE_PROJECT", setSweet))
    }

    const handleRedirect = () => {
        if (!active) {
            navigate(`/project-conversation?cid=${curElem.companyId}&pid=${curElem.id}`)
        }
    }

    return (
        <div className="col-sm-6 col-lg-4 col-xl-3">
            <div className="department-single alt" onClick={handleRedirect} style={{ cursor: "pointer" }}>
                <div className="department-top">
                    <span className={`pro-stat ${curElem.projectStatus === "completed" ? "green" : curElem.projectStatus === "active" ? "violet" : ""} text-capitalize`}>{curElem.projectStatus}</span>
                    {+auth.user.isClientAccount === 1 ? null :

                        <div className="drop-style" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
                            <Dropdown>
                                <Dropdown.Toggle variant="t" id="dropdown-basic">
                                    <BiDotsHorizontalRounded />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <ul>
                                        <>
                                            <li><a style={{ cursor: "pointer" }} onClick={handleShow}><IoChatbubblesOutline />Start Chat</a></li>
                                            <li><Link to={`/create-projects?cid=${curElem.companyId}&did=${curElem.departmentId}&pid=${curElem.id}`}><BiSolidEdit /> Edit Project</Link></li>
                                            <li><a style={{ cursor: "pointer" }} onClick={onDelete}><FiTrash2 /> Delete Project</a></li>
                                        </>
                                    </ul>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    }
                </div>
                <div className="department-single-txt">
                    <h4>{curElem.name}</h4>
                    <p>{curElem.description}</p>
                </div>
                <div className="user-stack">
                    {curElem.staff.length > 0 ?
                        curElem.staff.map((curElem, index) => {
                            return (
                                <div className="user-stack-single" key={index}>
                                    <img alt="" src={curElem.image} />
                                </div>
                            )
                        })
                        : ""}
                    <div className="user-stack-single last"><span>{curElem.staff.length > 4 ? "4+" : curElem.staff.length}</span></div>
                </div>
            </div>


            <CreateProjConvModal
                show={show}
                pid={curElem.id}
                cid={curElem.companyId}
                did={curElem.departmentId}
                handleClose={handleClose}
            />

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this project?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div>
    )
}

export default ProjectRows