import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { AiOutlineClose } from "react-icons/ai"
import { Modal } from 'react-bootstrap'
import TimezoneSelect from 'react-timezone-select'
import { useImmer } from 'use-immer'
import { FaSpinner } from 'react-icons/fa'
import { onSaveWorkingHours } from '../../../Redux/Actions/StaffActions'
import { useDispatch } from 'react-redux'

const WorkingHoursModal = ({ curElem, show, handleClose }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        save: false
    })
    const [data, setData] = useImmer({
        workingHours: [
            {
                day: "Sunday",
                enable: false,
                fromTime: "12:00",
                fromMeridiem: "AM",
                toTime: "12:00",
                toMeridiem: "PM"
            },
            {
                day: "Monday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "6:00",
                toMeridiem: "PM"
            },
            {
                day: "Tuesday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "6:00",
                toMeridiem: "PM"
            },
            {
                day: "Wednesday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "6:00",
                toMeridiem: "PM"
            },
            {
                day: "Thursday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "6:00",
                toMeridiem: "PM"
            },
            {
                day: "Friday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "6:00",
                toMeridiem: "PM"
            },
            {
                day: "Saturday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "6:00",
                toMeridiem: "PM"
            }
        ],
        timeZone: "Asia/Kolkata"
    })

    const handleChange = (e, index) => {
        let { name, value, checked } = e.target
        if (name === "enable") {
            value = checked
        }
        setData((draft) => {
            draft.workingHours[index] = {
                ...draft.workingHours[index],
                [name]: value
            }
        })
    }

    const selectTimeZone = (e) => {
        setData(draft => { draft.timeZone = e.value })
    }


    const handleSave = () => {
        setLoader({
            ...loader,
            save: true
        })
        let obj = { id: curElem.id, ...data }
        dispatch(onSaveWorkingHours(obj, handleClose, loader, setLoader))
    }

    useEffect(() => {
        if (curElem) {
            setData(draft => {
                draft.timeZone = curElem.timeZone
                if (curElem.workingHours) {
                    draft.workingHours = curElem.workingHours
                }
            })
        }
    }, [curElem])


    return (
        <Modal className="VideoModal modal-700" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="titleBar">
                    <div className="titleBar-profile">
                        <div className="titleBar-profile-img"><img alt="" src={curElem.image} /></div>
                        <div className="titleBar-left">
                            <h2>{curElem.name}</h2>
                            <p>{curElem.category}</p>
                        </div>
                    </div>
                    <span className="modalClose" onClick={handleClose}><AiOutlineClose /></span>
                </div>
                <div>
                    <div className="avl-works">
                        <div className="avl-works-left">
                            <h6>Available Working Hours</h6>
                        </div>
                        <div className="avl-works-right">
                            <label htmlFor="">Time zone</label>
                            <div className="select-style">
                                <TimezoneSelect
                                    value={data.timeZone}
                                    onChange={selectTimeZone}
                                />
                                {/* <Select options={options2} className="react-select-container" classNamePrefix="react-select" /> */}
                            </div>
                        </div>
                    </div>

                    <div className="working-hours">
                        {data.workingHours.length > 0 ?
                            data.workingHours.map((curElem, index) => {
                                return (
                                    <div className="working-hours-single" key={index}>
                                        <div className="working-hours-left">
                                            <div className="switch-btn">
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        name='enable'
                                                        checked={curElem.enable}
                                                        onChange={(e) => handleChange(e, index)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <label htmlFor="" className="ps-2">{curElem.day}</label>
                                        </div>
                                        {curElem.enable ?
                                            <div className="working-hours-right">
                                                <div className="form">
                                                    <span>From</span>
                                                    <span>
                                                        <select
                                                            className="inp"
                                                            name='fromTime'
                                                            value={curElem.fromTime}
                                                            onChange={(e) => handleChange(e, index)}
                                                        >
                                                            <option value="">Select Time</option>
                                                            <option value="1:00">1:00</option>
                                                            <option value="2:00">2:00</option>
                                                            <option value="3:00">3:00</option>
                                                            <option value="4:00">4:00</option>
                                                            <option value="5:00">5:00</option>
                                                            <option value="6:00">6:00</option>
                                                            <option value="7:00">7:00</option>
                                                            <option value="8:00">8:00</option>
                                                            <option value="9:00">9:00</option>
                                                            <option value="10:00">10:00</option>
                                                            <option value="11:00">11:00</option>
                                                            <option value="12:00">12:00</option>
                                                        </select>
                                                    </span>
                                                    <span>
                                                        <select
                                                            className="inp"
                                                            name='fromMeridiem'
                                                            value={curElem.fromMeridiem}
                                                            onChange={(e) => handleChange(e, index)}
                                                        >
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </span>
                                                </div>
                                                <div className="to">
                                                    <span>To</span>
                                                    <span>
                                                        <select
                                                            className="inp"
                                                            name='toTime'
                                                            value={curElem.toTime}
                                                            onChange={(e) => handleChange(e, index)}
                                                        >
                                                            <option value="">Select Time</option>
                                                            <option value="1:00">1:00</option>
                                                            <option value="2:00">2:00</option>
                                                            <option value="3:00">3:00</option>
                                                            <option value="4:00">4:00</option>
                                                            <option value="5:00">5:00</option>
                                                            <option value="6:00">6:00</option>
                                                            <option value="7:00">7:00</option>
                                                            <option value="8:00">8:00</option>
                                                            <option value="9:00">9:00</option>
                                                            <option value="10:00">10:00</option>
                                                            <option value="11:00">11:00</option>
                                                            <option value="12:00">12:00</option>
                                                        </select>
                                                    </span>
                                                    <span>
                                                        <select
                                                            className="inp"
                                                            name='toMeridiem'
                                                            value={curElem.toMeridiem}
                                                            onChange={(e) => handleChange(e, index)}
                                                        >
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </div> : ""}
                                    </div>
                                )
                            })
                            : ""}
                    </div>

                    <div className="input-wrap text-end">
                        <button className="theme-btn" onClick={handleSave}><span>{loader.save ? <>Saving <FaSpinner className='spin' /></> : "Save Changes"}</span></button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default WorkingHoursModal