import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateProject = (url, data, handleActiveTab, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (url === "create-project") {
                    dispatch({ type: "ON_CHANGE_PROJECT", payload: { name: "id", value: res.data } })
                }
                handleActiveTab("second")
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onSubmitKd = (url, data, fetchData, setData, loader, setLoader, setFiles) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (data.type === "write") {
                    setData({
                        text: "",
                        title: ""
                    })
                } else if (data.type === "upload") {
                    setData([])
                    setFiles([])
                } else if (data.type === "website") {
                    setData([])
                }
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onUpdateKd = (url, data, fetchData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchData()
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onCheckStatus = (url, data, fetchData, interval) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let status = +res.data[0].status
                if (status === 2 || status === 3) {
                    fetchData()
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onCreateConversation = (data, navigate, handleClose, loader, setLoader, fetchData) => (dispatch, getState) => {
    commonAxios("create-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (fetchData) {
                    fetchData()
                } else {
                    if (data.type === "single") {
                        navigate(`/chat-screen?convId=${res.data}&sid=${data.hireId}&type=s`)
                    } else {
                        navigate(`/chat-screen?convId=${res.data}&cid=${data.cid}&did=${data.did}&pid=${data.pid}&type=p`)
                    }
                }
                dispatch(setAlert(res.msg, "success"))
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                create: false
            })
            console.log(err)
        })
}


export const onUpdateConvName = (data, setIsEdit, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                setIsEdit(false)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
            console.log(err)
        })
}


export const onFetchMessages = (data, setMessages, setActiveUserJoin, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-messages", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setMessages(res.data)
            } else {
                setMessages([])
            }
            setActiveUserJoin(true)
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
        })
}


export const onInternetAccess = (data, setTypingActive, setShowIntTyping, setActiveBlink, setMessages, assistant) => (dispatch, getState) => {
    commonAxios("access-internet", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let date = new Date()
                let obj = {
                    message: res.data.message.replaceAll(/</g, "&lt;").
                        replaceAll(/>/g, "&gt")
                        .replaceAll("```vba", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`).
                        replaceAll("```html", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`).
                        replaceAll("```", "</pre>").
                        replaceAll("\n", "<br />")
                        .replaceAll("```html", `</pre>`)
                    ,
                    sources: res.data.source,
                    type: "assistant",
                    staff: [assistant],
                    assistant,
                    graphics: [],
                    staffId: assistant.id,
                    created: date.toLocaleString()
                }
                const pattern = /\((.*?)\)/g;
                let matches = obj.sources.link.match(pattern);
                if (matches) {
                    obj.sources.link = matches[0].replace("(", "").replace(")", "")
                }

                setActiveBlink(true)
                setMessages(prevMessages => {
                    const updatedMessages = [...prevMessages];
                    const lastIndex = updatedMessages.length;
                    if (lastIndex >= 0) {
                        updatedMessages[lastIndex] = obj
                    }
                    return updatedMessages;
                });

            } else {
                dispatch(setAlert(res.data, "danger"))
            }

            setTypingActive(false)
            setShowIntTyping(false)
        }).catch((err) => {
            setTypingActive(false)
            setShowIntTyping(false)
            console.log(err)
        })
}

export const onReadAloud = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let aud = new Audio(res.data.file_url)
                aud.play()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                generate: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                generate: false
            })
            console.log(err)
        })
}

export const onUpdateConvFile = (data, handleClose, setFile, setMyFile, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-conversation-files", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setMyFile({
                    type: "",
                    url: ""
                })
                setFile({
                    type: data.type,
                    url: data.url
                })
            }
            setLoader({
                ...loader,
                submit: false
            })
            handleClose()
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
            console.log(err)
        })
}


export const onUpdateProjectStaff = (obj, handleClose) => (dispatch, getState) => {
    dispatch(updateProjStaff(obj))
    if (handleClose) {
        handleClose()
    }
    commonAxios("update-project", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
            }
        }).catch((err) => {
            console.log(err)
        })
}


// No Axios Here

export const onChangeProject = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_PROJECT", payload: { name, value } })
}

export const updateProjStaff = (obj) => (dispatch) => {
    dispatch({ type: "UPDATE_PROJECT_STAFFS", payload: obj.staff })
}

export const unmountCreateProject = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_CREATE_PROJECT" })
}

export const onActiveConv = (con) => (dispatch) => {
    dispatch({ type: "ACTIVE_CONVERSATION", payload: con })
}

export const defaultActiveConv = (id) => (dispatch) => {
    dispatch({ type: "DEFAULT_CONV_ACTIVE", payload: id })
}

export const updateActiveConvVoice = (name, value) => (dispatch) => {
    dispatch({ type: "UPDATE_ACTIVE_CONV_VOICE", payload: { name, value } })
}


export const activeGodMode = () => (dispatch) => {
    dispatch({ type: "ACTIVE_GODMODE" })
}

export const deactiveGodMode = () => (dispatch) => {
    dispatch({ type: "DEACTIVE_GODMODE" })
}

export const unmountChatScreen = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_CHAT_SCREEN" })
}