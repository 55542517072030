import React, { useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import CommonAlert from '../../CommonComponents/CommonAlert'
import { onDeleteData } from '../../../Redux/Actions/CommonActions'
import { useDispatch, useSelector } from 'react-redux'

const CompanyRows = ({ curElem }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteData("delete-company", data, "DELETE_COMPANY", setSweet))
    }

    return (
        <li>
            <div className="company-opt-left">
                <div className="company-opt-img" style={{ height: 50 }}>
                    <img style={{ height: "100%" }} alt="" src={curElem.image} />
                </div>
                <div className="company-opt-details">
                    <h6>{curElem.name}</h6>
                    {/* <p>Started at 10 Sep 2023 - 09:30AM</p> */}
                    <p>{curElem.created}</p>
                </div>
            </div>
            <div className="company-opt-right">
                {+auth.user.isClientAccount === 1 ? null :
                    <>
                        <Link to={`/create-company?cid=${curElem.id}`} className="theme-btn bdr"><span><FaEdit /></span></Link>
                        <a onClick={onDelete} className="theme-btn bdr mx-1"><span><FaTrash /></span></a>
                    </>}
                <Link to={`/departments?cid=${curElem.id}`} className="theme-btn bdr"><span>Launch</span></Link>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this company?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </li>
    )
}

export default CompanyRows