import React, { useState } from "react";

import BackHeader from "../../Header/BackHeader";
import Footer from "../../Footer/Footer";

import ChatHeaderAuto from "../../Header/ChatHeaderAuto";

import{BiTrash} from "react-icons/bi"
import{BsCodeSlash, BsCardImage} from "react-icons/bs"
import{ AiOutlineClose} from "react-icons/ai"

import { FiEye, FiDownload } from "react-icons/fi";

import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

function ConversationFiles() {

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    return(
        <>
        <ChatHeaderAuto></ChatHeaderAuto>

        <div className="site-wrapper">
           <div className="site-container">
               <div className="embed-wrap">
                <div className="titleBar">
                    <div className="titleBar-left">
                      <h2>Chat File Storage</h2>
                    </div>
                </div>

                    

               <div className="embed-list">

                <div className="embed-single auto">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    
                    <div className="d-flex">
                        <Link to=""><div className="embed-user-icon no-bdr"><FiEye /></div></Link>
                        <Link to=""><div className="embed-user-icon no-bdr ms-2"><FiDownload /></div></Link>
                        <Link to=""><div className="embed-user-icon no-bdr ms-2"><BiTrash/></div></Link>
                    </div>
                </div>

                <div className="embed-single auto">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    
                    <div className="d-flex">
                        <Link to=""><div className="embed-user-icon no-bdr"><FiEye /></div></Link>
                        <Link to=""><div className="embed-user-icon no-bdr ms-2"><FiDownload /></div></Link>
                        <Link to=""><div className="embed-user-icon no-bdr ms-2"><BiTrash/></div></Link>
                    </div>
                </div>

            </div>
               </div>
           </div>
        </div>
          
        <Footer></Footer>

        </>
    )
}

export default ConversationFiles;