import React, { useEffect, useState } from "react";
import ChatHeader from "./ChatHeader/ChatHeader";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onFetchById } from "../../../Redux/Actions/CommonActions";
import TitleBar from "../../CommonComponents/TitleBar";
import Messages from "./Messages/Messages";
import { defaultActiveConv, onFetchMessages, unmountChatScreen } from "../../../Redux/Actions/ProjectActions";
import { useImmer } from "use-immer";
import { io } from "socket.io-client";
import { fetchChatConvData, onCreateFirstConv } from "../../../Redux/Actions/StaffActions";


const ChatScreen = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { convId, sid, pid, cid, did, type } = queryString.parse(location.search)

    const auth = useSelector(state => state.auth)

    const project = useSelector(state => state.project)
    const [convNewId, setConvNewId] = useState(false)

    const [socket, setSocket] = useState(false)
    const [activeUserJoin, setActiveUserJoin] = useState(false)
    const [createConv, setCreateConv] = useState(false)

    const [messages, setMessages] = useImmer([])
    const [assistant, setAssistant] = useState(false)

    const [loader, setLoader] = useState({
        fetch: false
    })
    const fetchProject = () => {
        let obj = {
            id: pid
        }
        dispatch(onFetchById("fetch-project", obj, "EDIT_PROJECT"))
    }

    useEffect(() => {
        if (pid) {
            fetchProject()
        }
    }, [pid])


    const fetchConversation = () => {
        let obj = {
            pid,
            cid,
            did,
            type: type === "s" ? "single" : "group",
            hireId: sid
        }
        dispatch(fetchChatConvData(obj, setCreateConv, setLoader, loader))
    }

    const fetchMessages = () => {
        let data = {
            conversationId: project.activeConv.id
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onFetchMessages(data, setMessages, setActiveUserJoin, loader, setLoader))
    }

    const createFirstConv = () => {
        let obj = {
            name: "Conversation",
            type: type === "s" ? "single" : "group",
            hireId: sid,
            pid,
            cid,
            did,
            tone: "",
            respose: "",
            language: ""
        }
        dispatch(onCreateFirstConv(obj, setCreateConv, fetchConversation))
    }

    useEffect(() => {
        if (type === "s" && createConv && sid) {
            createFirstConv()
        } else if (type === "p" && createConv && cid && did && pid) {
            createFirstConv()
        }
    }, [createConv])


    useEffect(() => {
        if (project.activeConv) {
            fetchMessages()
        }
    }, [project.activeConv])

    useEffect(() => {
        if (convNewId) {
            dispatch(defaultActiveConv(convNewId))
        }
        else if (type === "s" && cid && sid) {
            dispatch(defaultActiveConv(false))
        }
    }, [convNewId, project.conversationList, sid])

    useEffect(() => {
        if (type) {
            fetchConversation()
        }

        return () => {
            dispatch(unmountChatScreen())
        }
    }, [type])


    useEffect(() => {
        if (socket && activeUserJoin && type === "p") {
            socket.emit("new-user-joined", {
                conveArr: messages,
                userId: auth.user.id,
                cid: project.activeConv.id,
                staffId: "",
                staffType: "",
                assistantId: "",
                threadId: project.activeConv.threadId,
                fileType: "",
                fileUrl: ""
            })
            setActiveUserJoin(false)

            return () => {
                socket.off('new-user-joined');
            };
        }
    }, [socket, activeUserJoin, type])


    useEffect(() => {
        if (socket && activeUserJoin && type === "s" && assistant) {
            socket.emit("new-user-joined", {
                conveArr: messages,
                userId: auth.user.id,
                cid: project.activeConv.id,
                staffId: assistant.id,
                staffType: assistant.category,
                assistantId: assistant.assistantId,
                threadId: project.activeConv.threadId,
                fileType: "",
                fileUrl: ""
            })
            setActiveUserJoin(false)

            return () => {
                socket.off('new-user-joined');
            };
        }
    }, [socket, activeUserJoin, type, assistant])



    useEffect(() => {
        if (convId) {
            let isConvAva = project.conversationList.findIndex(({ id }) => +id === +convId)
            if (isConvAva === -1) {
                dispatch(defaultActiveConv(false))
            } else {
                setConvNewId(convId)
            }
        }
    }, [convId, project.conversationList])

    useEffect(() => {
        const so = io('wss://abhiai.com:6002', { transports: ['websocket'] });
        setSocket(so)

        return () => {
            so.disconnect();
        }
    }, [])




    return (
        <>
            <TitleBar title="Chat" />
            <ChatHeader
                fetchConversation={fetchConversation}
                fetchMessages={fetchMessages}
                assistant={assistant}
                setAssistant={setAssistant}
                createFirstConv={createFirstConv}
                setConvNewId={setConvNewId}
                convNewId={convNewId}
            />
            <Messages
                messages={messages}
                setMessages={setMessages}
                loader={loader}
                fetchMessages={fetchMessages}
                assistant={assistant}
                setAssistant={setAssistant}
                type={type}
                socket={socket}
            />
        </>
    )
}

export default ChatScreen;