import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateCompany = (url, data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate('/company')
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onCreateCat = (data, fetchData, handlClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-category", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handlClose()
                dispatch({ type: "ON_CHANGE_COMPANY", payload: { name: "category", value: data.name } })
                dispatch(onChangeCompany())
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchDbData = (data, setDbData) => (dispatch, getState) => {
    commonAxios("fetch-dashboard-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setDbData(res.data)
            }
        }).catch((err) => {
            console.log(err)

            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchProfileStates = (setData) => (dispatch, getState) => {
    commonAxios("user-statics", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)
            }
        }).catch((err) => {
            console.log(err)

            dispatch(setAlert(err.msg, "danger"))
        })
}

export const fetchUsedCredits = (obj, setData) => (dispatch, getState) => {
    commonAxios("fetch-credits", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)
            }
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}



// No Axios Here

export const onChangeCompany = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_COMPANY", payload: { name, value } })
}

export const unmountCreateCompany = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_CREATE_COMPANY" })
}

export const onActiveCompany = (data) => (dispatch) => {
    dispatch({ type: "ACTIVE_COMPANY", payload: data })
}

export const onDeactiveCompany = () => (dispatch) => {
    dispatch({ type: "DEACTIVE_COMPANY" })
}



