import React from 'react'
import SidePanel from './SidePanel'

import { useSelector } from 'react-redux'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const MainMenu = ({ children }) => {
    const extra = useSelector(state => state.extra.data)
    return (
        <>
            {/* <div className={`dashboard  ${extra.toggleMenu ? "toggleClass" : ""}`}> */}
            {/* <SidePanel /> */}
            <Header />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="dashboard-in">
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
            {/* </div> */}
        </>
    )
}

export default MainMenu