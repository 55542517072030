import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
import { whiteLabelStatus } from "./ExtraActions"

export const onWhiteLabel = (url, data, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchWhiteLabel = (data, setData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-whitelabel", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data[0])
                dispatch(whiteLabelStatus(true))
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onCreateMembership = (url, data, fetchMembership, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchMembership()
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onUpdateWlUserProfile = (data, fetchUser, loader, setLoader) => (dispatch, getState) => {
    commonAxios("change-wl-profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchUser()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitProf: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submitProf: false
            })
        })
}

export const onUpdateWlUserPassword = (data, fetchUser, loader, setLoader) => (dispatch, getState) => {
    commonAxios("change-wl-password", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchUser()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitPass: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submitPass: false
            })
        })
}