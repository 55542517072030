import React, { useEffect, useState } from 'react'
import { FaFile, FaRegTrashAlt } from 'react-icons/fa'
import { FiEye } from 'react-icons/fi'
import { GoPencil } from 'react-icons/go'
import CommonAlert from '../../../CommonComponents/CommonAlert'
import { useDispatch } from 'react-redux'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions'
import EditKnowledge from './EditKnowledge'
import { onCheckStatus } from '../../../../Redux/Actions/ProjectActions'

const UploadedRows = ({ curElem, fetchFunction, type }) => {
    const dispatch = useDispatch()
    const [name, setName] = useState("")
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)


    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })

        let url = "delete-knowledgebase"
        if (type === "directory") {
            url = "delete-custom-knowledgebase"
        }

        dispatch(onDeleteCampaign(url, data, false, false, setSweet, fetchFunction))
    }


    const handleActions = (type) => {
        if (type === "view") {
            window.open(curElem.url)
        } else if (type === "edit") {
            handleShow()
        } else if (type === "delete") {
            onDelete()
        }
    }

    const checkStatus = (interval) => {
        let data = {
            id: curElem.id
        }
        let url = "check-status"
        if (type === "directory") {
            url = "check-custom-status"
        }
        dispatch(onCheckStatus(url, data, fetchFunction, interval))
    }

    useEffect(() => {
        if (curElem.type === "write") {
            setName(curElem.data)
        } else {
            setName(curElem.url)
        }

        if (+curElem.status === 1) {
            const interval = setInterval(() => {
                checkStatus(interval)
            }, 5000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [curElem])



    return (
        <div className="doc-list mt-4">
            <div className="row align-items-center">
                <div className="col">
                    <div className="row">
                        <div className="col-auto">
                            <div className="doc-icon"><FaFile /></div>
                        </div>
                        <div className="col">
                            <h6>{name.slice(0, 50)}...</h6>
                            <p><span style={{ color: +curElem.status === 1 ? "purple" : +curElem.status === 2 ? "green" : "red" }}>{+curElem.status === 1 ? "In Progress" : +curElem.status === 2 ? "Completed" : "Failed"}</span> Created - {curElem.created}</p>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="icongroup">
                        {curElem.type === "write" ? null :
                            <span onClick={() => handleActions("view")} title='View'><FiEye /></span>
                        }
                        {curElem.data ?
                            <span onClick={() => handleActions("edit")} title='Edit'><GoPencil /></span> : null}
                        <span onClick={() => handleActions("delete")} title='Delete'><FaRegTrashAlt /></span>
                    </div>
                </div>
            </div>

            <EditKnowledge
                curElem={curElem}
                show={show}
                handleClose={handleClose}
                fetchFunction={fetchFunction}
                type={type}
            />

            <CommonAlert
                show={sweet.enable}
                message={`Are you sure you want to delete this ${curElem.type} data?`}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />

        </div>
    )
}

export default UploadedRows