import React from 'react'

const DfyLeadMagnets = () => {
    return (
        <div className="upgrade-tab-in">
            <div className="upgrade-tab-block">
                <h6>DFY Lead Magnets</h6>
            </div>
            <div className="upgrade-tab-block">
                <div className='row text-center'>
                    <div className='col-md-12'>
                        <a
                            className="theme-btn mt-3"
                            href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <span>Download</span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DfyLeadMagnets