import React, { useEffect, useState } from "react";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import ProfileSidebar from "../ProfileSidebar";
import TitleBar from "../../../CommonComponents/TitleBar";
import CreateMemModal from "./CreateMemModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../../Redux/Actions/CommonActions";
import { FaSpinner } from "react-icons/fa";
import MemberRows from "./MemberRows";

const WhiteLabelMember = () => {
    const dispatch = useDispatch()
    const isWhiteLabel = useSelector(state => state.extra.data.isWhiteLabel)
    const [memberships, setMemberships] = useState([])

    const [loader, setLoader] = useState({
        fetch: true
    })

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const fetchMemberships = () => {
        let data = {}
        dispatch(fetchData("fetch-membership", data, setMemberships, loader, setLoader))
    }

    useEffect(() => {
        fetchMemberships()
    }, [])

    return (
        <>
            <TitleBar title="White Label" />
            <Header />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="account-wrap">
                        <ProfileSidebar />
                        <div className="account-right">
                            <div className="titleBar">
                                <div className="titleBar-left">
                                    <h2>Membership</h2>
                                    <p>Create and Manage Memberships</p>
                                </div>
                                <div className="titleBar-right">
                                    <button
                                        className="theme-btn"
                                        onClick={handleShow}
                                        disabled={!isWhiteLabel}
                                        style={{ background: isWhiteLabel ? "" : "gray", cursor: isWhiteLabel ? "" : "not-allowed" }}

                                    ><span>Create Membership</span></button>
                                </div>
                            </div>

                            <div className="dashboard-block">
                                <div className="titleBar pb-4">
                                    <div className="titleBar-left">
                                        <h2>Members</h2>
                                    </div>
                                </div>
                                <table className="table table-bordered theme-table">
                                    <tr>
                                        <th>Name</th>
                                        <th>Price ($)</th>
                                        <th className="text-end">Actions</th>
                                    </tr>
                                    {memberships.length > 0 ?
                                        memberships.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <MemberRows
                                                        curElem={curElem}
                                                        fetchMemberships={fetchMemberships}
                                                        memberships={memberships}
                                                        setMemberships={setMemberships}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <tr className="text-center mt-2">
                                            <td colSpan={3}>
                                                {loader.fetch ?
                                                    <FaSpinner size={25} className="spin p-0" /> : "You do not have membership created!"}
                                            </td>
                                        </tr>
                                    }
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <CreateMemModal
                show={show}
                handleClose={handleClose}
                fetchMemberships={fetchMemberships}
            />

            <Footer />
        </>
    )
}

export default WhiteLabelMember;
