import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onChangeCompany, onCreateCompany, unmountCreateCompany } from "../../../Redux/Actions/CompanyActions";
import { fetchData, onFetchById, onUploadMedia } from "../../../Redux/Actions/CommonActions";
import Swal from "sweetalert2";
import { FaEdit, FaSpinner, FaTrash } from "react-icons/fa";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import TitleBar from "../../CommonComponents/TitleBar";
import queryString from "query-string";
import CreateCatModal from "./CreateCatModal";
import Header from "../../Header/Header";
import { IoIosArrowBack } from "react-icons/io";
const CreateCompany = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const { cid } = queryString.parse(location.search)
    const create = useSelector(state => state.company.create)

    const [percent, setPercent] = useState(0)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [image, setImage] = useState("https://reeelapps-app.s3.us-west-2.amazonaws.com/AiOffices1/1727360484_TAkWKxb8k97SYwLZaYvTYJAiOffices1.png")
    const [loader, setLoader] = useState({
        upload: false,
        create: false,
        fetch: false
    })
    const [category, setCategory] = useState([])

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "category" && value === "create00") {
            handleShow()
        } else {
            dispatch(onChangeCompany(name, value))
        }
    }

    const onCompeleteUpload = (url) => {
        setImage(url)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = { ...create }
        obj.image = image
        if (obj.image) {
            setLoader({
                ...loader,
                create: true
            })
            let url = "create-company"
            if (cid) {
                url = "update-company"
            }
            dispatch(onCreateCompany(url, obj, navigate, loader, setLoader))
        } else {
            dispatch(setAlert("Please provide image for your company.", "danger"))
        }
    }

    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 5000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    const fetchCategory = () => {
        dispatch(fetchData("fetch-company-category", {}, setCategory, false, false, false, "shortDataLg"))
    }

    const fetchCompany = () => {
        let data = {
            id: cid
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onFetchById("fetch-company", data, "EDIT_COMPANY", loader, setLoader))
    }


    useEffect(() => {
        if (create.id) {
            setImage(create.image)
        }
    }, [create.id])

    useEffect(() => {
        if (cid) {
            fetchCompany()
        }
    }, [cid])

    useEffect(() => {
        fetchCategory()

        return () => {
            dispatch(unmountCreateCompany())
        }
    }, [])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div> :
            <>
                <TitleBar title="Company" />
                {/* <CompanyHeader /> */}
                <Header />

                <div className="site-wrapper">
                    <div className="site-container">
                        <div className="theme-btn" onClick={() => navigate(-1)}>
                            <span><IoIosArrowBack size={25} />Back</span>
                        </div>
                        <div className="company-empty">

                            <div className="company-block">
                                <div className="company-block-title">
                                    <div>
                                        <h3>{cid ? "Update" : "Create New"} Company</h3>
                                        <p>Fill the company details</p>
                                    </div>
                                </div>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="input-wrap">
                                    <label htmlFor="">Company Name</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp"
                                            type="text"
                                            placeholder="Type Company Name"
                                            value={create.name}
                                            name="name"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Description</label>
                                    <div className="inp-outer">
                                        <textarea
                                            className="inp"
                                            name="description"
                                            placeholder="Type Company Description"
                                            value={create.description}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Company Email</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp"
                                            type="email"
                                            name="email"
                                            value={create.email}
                                            placeholder="Company Email"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Company Website</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp"
                                            type="url"
                                            name="website"
                                            value={create.website}
                                            placeholder="https://www.example.com"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Company Type</label>
                                    <div className="inp-outer">
                                        <select
                                            name="category"
                                            className="inp"
                                            onChange={handleChange}
                                            value={create.category}
                                            required
                                        >
                                            <option value="">Select Category</option>
                                            {category.length > 0 ?
                                                category.map((curElem, index) => {
                                                    return (
                                                        <>
                                                            <option key={index} value={curElem.name}>
                                                                {curElem.name}
                                                            </option>
                                                        </>
                                                    )
                                                })
                                                : ""}
                                            <option value="create00" style={{ fontWeight: "bold", cursor: "pointer", background: "linear-gradient(90deg, #9461fc 0.08%, #ef1564 101.7%)" }}>Others</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="input-wrap">
                                    <div className="file-field">
                                        <div className="me-3 d-flex align-items-center justify-content-center flImg" style={{ height: 50 }}>
                                            {loader.upload ?
                                                <FaSpinner className="spin" size={25} />
                                                :
                                                <img alt="" src={image} style={{ maxHeight: "100%" }} />
                                            }
                                        </div>
                                        <div>
                                            <h6>Click to Upload Company Image</h6>
                                            <p>JPG, PNG, JPEG upto 5MB is allowed</p>
                                        </div>
                                        {loader.upload ? null :
                                            <input
                                                type="file"
                                                onChange={onInputImage}
                                                accept="image/jpg, image/png, image/jpeg"
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    {cid ?
                                        <button
                                            className="theme-btn full" type="submit"
                                            style={{
                                                background: create.name && create.description && create.email && create.website && create.category ? "" : "gray",
                                                cursor: create.name && create.description && create.email && create.website && create.category ? "" : "not-allowed"
                                            }}
                                            disabled={create.name && create.description && create.email && create.website && create.category ? false : true}
                                        >
                                            <span>{loader.create ? <>Updating <FaSpinner className="spin" /></> : "Update Company"}</span>
                                        </button>
                                        :
                                        <button
                                            className="theme-btn full"
                                            type="submit"
                                            style={{
                                                background: create.name && create.description && create.email && create.website && create.category ? "" : "gray",
                                                cursor: create.name && create.description && create.email && create.website && create.category ? "" : "not-allowed"
                                            }}
                                            disabled={create.name && create.description && create.email && create.website && create.category ? false : true}
                                        >
                                            <span>{loader.create ? <>Creating <FaSpinner className="spin" /></> : "Create Company"}</span>
                                        </button>
                                    }
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

                <CreateCatModal
                    show={show}
                    handleClose={handleClose}
                    fetchCategory={fetchCategory}
                />

                <Footer />
            </>
    )
}

export default CreateCompany;