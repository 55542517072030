import React from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { CgAdd } from 'react-icons/cg'
import { onUpdateProjectStaff } from '../../../../Redux/Actions/ProjectActions'

const AddStaffModal = ({ show, handleClose, addedStaffs, depart, create }) => {
    const dispatch = useDispatch()

    const handleAdd = (curElem) => {
        let obj = { ...create }
        obj.staff = [...obj.staff, curElem]
        dispatch(onUpdateProjectStaff(obj, handleClose))
    }


    return (
        <Modal className="VideoModal modal-700" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="titleBar">
                    <div className="titleBar-profile">
                        <div className="titleBar-left">
                            <h2 className='text-capitalize'>Add Staffs</h2>
                        </div>
                    </div>
                    <span onClick={handleClose} style={{ cursor: "pointer" }}><AiOutlineClose size={22} /></span>
                </div>

                <div className='pt-3'>
                    {depart ? depart.staff.length > 0 ?
                        depart.staff.filter((curElem) => {
                            let sta = !addedStaffs.find(({ id }) => +id === +curElem.id)
                            return sta
                        }).map((curElem, index) => {
                            return (
                                <div className='addStaff' key={index}>
                                    <div className='row align-items-center'>
                                        <div className='col'>
                                            <div className='d-flex align-items-center gap-3'>
                                                <div className='addStaff-img'><img src={curElem.image} alt='' /></div>
                                                <p><strong>{curElem.name}</strong></p>
                                            </div>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='drop-icon'><CgAdd title='Add' style={{ cursor: "pointer" }} onClick={() => handleAdd(curElem)} /></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : "" : ""}
                </div>

            </Modal.Body>
        </Modal >
    )
}

export default AddStaffModal