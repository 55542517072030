import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { BsFillChatDotsFill } from 'react-icons/bs'
import { HiDotsVertical } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { onDeleteCampaign, onViewMessageForPdf } from '../../../../Redux/Actions/CommonActions'
import CommonAlert from '../../../CommonComponents/CommonAlert'
import { FiTrash2 } from "react-icons/fi";
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { LuDownload } from "react-icons/lu";
import { FaSpinner } from 'react-icons/fa'

const SingleConv = ({ curElem, fetchConv, type }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        donwload: false
    })
    const [active, setActive] = useState(false)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-conversation", data, false, false, setSweet, fetchConv))
    }

    const handleRedirect = () => {

        if (!active && +auth.user.isClientAccount !== 1 && +curElem.embedId === 0) {
            if (type === "single") {
                navigate(`/chat-screen?convId=${curElem.id}&cid=${curElem.companyId}&sid=${curElem.staff[0].id}&type=s`)
            }
            if (type === "group") {
                navigate(`/chat-screen?convId=${curElem.id}&cid=${curElem.companyId}&did=${curElem.departmentId}&pid=${curElem.projectId}&type=p`)
            }
        }
    }

    const handleDownload = () => {
        let data = { conversationId: curElem.id }
        setLoader({ ...loader, donwload: true })
        dispatch(onViewMessageForPdf(data, curElem.name, html2pdf, loader, setLoader))
    }


    return (
        <div className="col-sm-6 col-lg-4 col-xl-3">
            <div className="chat-box" onClick={handleRedirect} style={{ cursor: +auth.user.isClientAccount !== 1 ? "pointer" : "" }}>
                <div className="chat-box-top">
                    {type === "group" ?
                        <div className="user-stack">
                            {curElem.staff.length > 0 ?
                                curElem.staff.slice(0, 3).map((curElem, index) => {
                                    return (
                                        <div className="user-stack-single" key={index}><img src={curElem.image} alt="" /></div>
                                    )
                                })

                                : ""}
                            <div className="user-stack-single last"><span>{curElem.staff.length > 4 ? "4+" : curElem.staff.length}</span></div>
                        </div> :
                        curElem.staff.length > 0 ?
                            <div className="chat-box-top-in">
                                <div className="chat-box-img"><img alt="" src={curElem.staff[0].image} /></div>
                                <div className="chat-box-icon"><BsFillChatDotsFill /></div>
                            </div> : ""
                    }
                    <div className="user-nav" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
                        <Dropdown>
                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                <div className="drop-icon"><HiDotsVertical /></div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul>
                                    <li><a onClick={handleDownload}>{loader.donwload ? <FaSpinner className='spin' /> : <LuDownload />} Download</a></li>
                                    {+auth.user.isClientAccount === 1 ? null :
                                        <li><a style={{ cursor: "pointer" }} onClick={onDelete}><FiTrash2 /> Delete</a></li>}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="chat-box-bottom">
                    <h6>{curElem.name}</h6>
                    <p>{curElem.created}</p>
                </div>
            </div>
            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this conversation?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div>
    )
}

export default SingleConv