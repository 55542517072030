import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Header from '../../../Header/Header';
import Footer from '../../../Footer/Footer';
import TrainingNav from './TrainingNav';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { fetchArticles, fetchVideos } from '../../../../Redux/Actions/HelpActions';
import { FaSpinner } from 'react-icons/fa';
import TitleBar from '../../../CommonComponents/TitleBar';

const HelpDetails = () => {
    let location = useLocation();
    const dispatch = useDispatch()

    const articleId = queryString.parse(location.search).id;
    const article = useSelector(state => state.help.article);
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    useEffect(() => {
        fetchHelpData()
    }, [])
    return (
        <>
         <TitleBar title="Help & Support" />
            <Header />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="outer-container pb-5">
                        <div className="titleBar">
                            <div className="titleBar-left">
                                <h2>Help</h2>
                                <p>Select from Popular Topics</p>
                            </div>
                        </div>
                    </div>
                    <div className="account-wrap for-post">
                        <Tab.Container id="left-tabs-example">
                            <div className="account-left">
                                <TrainingNav
                                    article={article}
                                    articleId={articleId}
                                />
                            </div>
                            <div className="account-right">
                                <div className="dashboard-block mt-0">
                                    <Tab.Content>
                                        {article.length > 0 ?
                                            article.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Tab.Pane className='fade show active'>
                                                            {+curElem.id === +articleId ?
                                                                curElem.items.map((item, ind) => {
                                                                    return (
                                                                        <div className="post-block alt" key={ind}>
                                                                            <Link to={`/support-article?id=${articleId}&art_id=${item.id}`}>
                                                                                <div className="upgrade-tab-block">
                                                                                    <h2> {item.title}</h2>
                                                                                    <hr />
                                                                                    <div className="post-block-img alt">
                                                                                        <img src={item.feature_url} className="img-fluid" alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                }) : ""}
                                                        </Tab.Pane>
                                                    </React.Fragment>
                                                )
                                            }) :
                                            <div className="spinner d-flex justify-content-center" style={{ width: '100%', height: "900px" }}>
                                                <div className="spinner_tag">
                                                    <FaSpinner className='spin' style={{ color: "#000", fontSize: "30px" }} />
                                                </div>
                                            </div>
                                        }
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default HelpDetails