import React from 'react'

const DfyArticle = () => {
    return (
        <div className="upgrade-tab-in">
            <div className="upgrade-tab-block">
                <h6>DFY Articles</h6>
            </div>
            <div className="upgrade-tab-block">
                <div className="row  d-flex justify-content-center">

                    <div className="col-xl-6">
                        <div className="upgrade-box-content text-center  text-white">
                            <div className="upgrade-para p-3">
                                <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack1.zip"
                                    target="_blank"
                                    className="theme-btn mt-3"
                                >
                                    <span> Article Pack 1 </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="upgrade-box-content text-center  text-white">
                            <div className="upgrade-para p-3">
                                <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack6.zip"
                                    target="_blank"
                                    className="theme-btn mt-3"
                                >
                                    <span> Article Pack 6 </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row  d-flex justify-content-center  mt-3">
                    <div className="col-xl-6">
                        <div className="upgrade-box-content text-center  text-white">
                            <div className="upgrade-para p-3">
                                <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                    target="_blank"
                                    className="theme-btn mt-3"
                                >
                                    <span> Article Pack 2 </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="upgrade-box-content text-center  text-white">
                            <div className="upgrade-para p-3">
                                <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack7.zip"
                                    target="_blank"
                                    className="theme-btn mt-3"
                                >
                                    <span> Article Pack 7 </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row  d-flex justify-content-center  mt-3">
                    <div className="col-xl-6">
                        <div className="upgrade-box-content text-center  text-white">
                            <div className="upgrade-para p-3">
                                <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                    target="_blank"
                                    className="theme-btn mt-3"
                                >
                                    <span> Article Pack 3 </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="upgrade-box-content text-center  text-white">
                            <div className="upgrade-para p-3">
                                <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                    target="_blank"
                                    className="theme-btn mt-3"
                                >
                                    <span> Article Pack 8 </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row  d-flex justify-content-center  mt-3">
                    <div className="col-xl-6">
                        <div className="upgrade-box-content text-center  text-white">
                            <div className="upgrade-para p-3">
                                <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack4.zip"
                                    target="_blank"
                                    className="theme-btn mt-3"
                                >
                                    <span> Article Pack 4</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="upgrade-box-content text-center  text-white">
                            <div className="upgrade-para p-3">
                                <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                    target="_blank"
                                    className="theme-btn mt-3"
                                >
                                    <span> Article Pack 8 </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row  d-flex justify-content-center  mt-3">
                    <div className="col-xl-6">
                        <div className="upgrade-box-content text-center  text-white">
                            <div className="upgrade-para p-3">
                                <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack5.zip"
                                    target="_blank"
                                    className="theme-btn mt-3"
                                >
                                    <span> Article Pack 5 </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="upgrade-box-content text-center  text-white">
                            <div className="upgrade-para p-3">
                                <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack10.zip"
                                    target="_blank"
                                    className="theme-btn mt-3"
                                >
                                    <span> Article Pack 10 </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DfyArticle