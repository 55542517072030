import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import WriteDoc from './Write/WriteDoc'
import UploadDoc from './Upload/UploadDoc'
import ImportDoc from './Import/ImportDoc'
import { fetchData } from '../../../../Redux/Actions/CommonActions'

const KnowledgeBase = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const project = useSelector(state => state.project)
    const { pid, did, cid } = queryString.parse(location.search)
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [kdData, setKdData] = useState([])
    const ids = {
        projectId: pid ? pid : project.create.id,
        departmentId: did,
        companyId: cid,
    }

    const fetchKdData = () => {

        let obj = { pid: pid ? pid : project.create.id, did, cid }
        dispatch(fetchData("fetch-knowledgebase", obj, setKdData, loader, setLoader))
    }

    useEffect(() => {
        fetchKdData()
    }, [])

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="second">
            <Nav variant="b" className="navStyle navStyle-3">
                <Nav.Item>
                    <Nav.Link eventKey="first" >
                        <h6>Write</h6>
                        <p>Write project information</p>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="second">
                        <h6>Upload</h6>
                        <p>Upload your document</p>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="third">
                        <h6>Import Website</h6>
                        <p>Import from website URL</p>
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content className="pt-5">
                <Tab.Pane eventKey="first">
                    <WriteDoc
                        ids={ids}
                        fetchData={fetchKdData}
                        url="create-manual"
                        kdData={kdData}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                    <UploadDoc
                        ids={ids}
                        fetchData={fetchKdData}
                        url="extract-files"
                        kdData={kdData}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                    <ImportDoc
                        ids={ids}
                        fetchData={fetchKdData}
                        url="create-knowledge"
                        kdData={kdData}
                    />
                </Tab.Pane>

            </Tab.Content>

        </Tab.Container>
    )
}

export default KnowledgeBase