import React, { useEffect, useRef, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { MdCheck, MdOutlineEdit } from 'react-icons/md'
import CommonAlert from '../../../CommonComponents/CommonAlert'
import { onDeleteCampaign, onDeleteData } from '../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { onActiveConv, onUpdateConvName } from '../../../../Redux/Actions/ProjectActions'
import { setAlert } from '../../../../Redux/Actions/AlertActions'
import { FaSpinner } from 'react-icons/fa6'

const ConversationRows = ({ curElem, activeConv, fetchConv, convNewId, setConvNewId, convList }) => {
    const dispatch = useDispatch()
    const inputRef = useRef(null)
    const [loader, setLoader] = useState({
        submit: false
    })
    const [isEdit, setIsEdit] = useState(false)
    const [text, setText] = useState("")
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (e) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        if (data.id === convNewId) {
            setConvNewId(false)
        }
        dispatch(onDeleteCampaign("delete-conversation", data, false, false, setSweet, fetchConv))
        // dispatch(onDeleteData("delete-conversation", data, "ON_DELETE_CONV", setSweet))
    }

    const handleActive = (e) => {
        e.stopPropagation()
        if (!isEdit) {
            dispatch(onActiveConv(curElem))
        }
    }

    const handleEdit = (e) => {
        e.stopPropagation()
        setIsEdit(true)
        inputRef.current.focus()
    }

    const handleSubmit = (e) => {
        e.stopPropagation()
        if (text) {
            let data = {
                cid: curElem.id,
                name: text
            }
            setLoader({
                ...loader,
                submit: true
            })
            dispatch(onUpdateConvName(data, setIsEdit, loader, setLoader))
        } else {
            dispatch(setAlert("Please provide a conversation name!", "danger"))
        }
    }

    useEffect(() => {
        if (curElem) {
            setText(curElem.name)
        }
    }, [curElem])

    return (
        <li>
            <div className={`flexLink ${activeConv?.id === curElem.id ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={(e) => handleActive(e)}>
                <p>
                    <input
                        ref={inputRef}
                        value={text}
                        style={{ background: "transparent", border: "none", cursor: isEdit ? "" : "pointer" }}
                        onChange={(e) => setText(e.target.value)}
                        readOnly={!isEdit}
                    />
                    {/* {curElem.name} */}
                </p>
                <div className="flexLink-icon" >
                    {isEdit ?
                        <button type='button' onClick={handleSubmit}>{loader.submit ? <FaSpinner className='spin' /> : <MdCheck />}</button>
                        :
                        <button type="button" onClick={(e) => handleEdit(e)}><MdOutlineEdit /></button>
                    }
                    {convList.length === 1 ? null :
                        <button type="button" onClick={(e) => onDelete(e)}><BiTrash /></button>
                    }
                </div>
            </div>
            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this conversation?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </li>
    )
}

export default ConversationRows