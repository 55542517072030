import React from 'react'
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from 'react-router-dom';

const TrainingNav = ({ article, articleId }) => {
    const navigate = useNavigate()
    const handleNavigate = (id) => {
        navigate(`/help-details?id=${id}`)
    }
    return (
        <Nav variant="pillsn" className="flex-column tabNav">
            {article.length > 0 ?
                article.map((curElem, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Nav.Item onClick={() => handleNavigate(curElem.id)}>
                                <Nav.Link eventKey={`tab-${index}`} className={`nav-link ${articleId === curElem.id ? 'active' : ''}`}>
                                    {curElem.name}
                                </Nav.Link>
                            </Nav.Item>
                        </React.Fragment>
                    )
                }) : ""
            }
        </Nav>
    )
}

export default TrainingNav