import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose, AiOutlineEyeInvisible } from 'react-icons/ai'
import Select from 'react-select'
import { addAccData, onEditClient } from '../../../../Redux/Actions/AccountManagements'
import { useDispatch } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'

const CreateTeamModal = ({ show, handleClose, curElem, fetchMembers }) => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [view, setView] = useState(false)
    const [details, setDetails] = useState({
        name: "",
        email: "",
        password: "",
        type: "team"
    })

    const option = [
        { label: "Client", value: "client" },
        { label: "Team Member", value: "team" },
    ]

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setDetails({
            ...details,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (details.password) {
            if (pattern.test(details.password)) {
                setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                setLoader(true);
                let url = "create-client"
                if (curElem) {
                    url = "edit-client-account"
                }
                dispatch(onEditClient(url, details, setDetails, fetchMembers, handleClose, setLoader))
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        } else {
            setLoader(true);
            dispatch(onEditClient("edit-client-account", details, setDetails, fetchMembers, handleClose, setLoader))
        }
    }

    useEffect(() => {
        if (curElem) {
            setDetails({
                ...details,
                name: curElem.name,
                email: curElem.email,
                id: curElem.id,
                type: +curElem.isClientAccount === 1 ? "client" : "team"
            })
        }
    }, [curElem])

    useEffect(() => {
        if (!show) {
            setPasswordMsg({
                ...passwordMsg,
                msg: "",
                validation: false
            })
        }
    }, [show])

    return (
        <Modal className="VideoModal sm-modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="titleBar">
                    <div className="titleBar-left">
                        <h2>{curElem ? "Edit" : "Create"} Accounts</h2>
                        <p>Manage memebers account</p>
                    </div>
                    <span className="modalClose" onClick={handleClose}><AiOutlineClose /></span>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="input-wrap">
                        <label htmlFor="">Account Type</label>
                        <div className="inp-outer">
                            {curElem ?
                                <input
                                    value={details.type === "team" ? "team member" : details.type}
                                    readOnly
                                    className='inp alt text-capitalize'
                                />
                                :
                                <select
                                    className='inp alt'
                                    name='type'
                                    value={details.type}
                                    onChange={handleChange}
                                >
                                    <option value={"client"}>Client</option>
                                    <option value={"team"}>Team Member</option>
                                </select>}
                        </div>
                        {/* <div className="select-style square">
                            <Select
                                options={option}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={details.type}
                                onChange={handleChange}
                            />
                        </div> */}
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="">Account Name</label>
                        <div className="inp-outer">
                            <input
                                className="inp alt"
                                type="text"
                                name='name'
                                value={details.name}
                                onChange={handleChange}
                                placeholder='Enter Name Here'
                                required
                            />
                        </div>
                    </div>

                    <div className="input-wrap">
                        <label htmlFor="">Account Email</label>
                        <div className="inp-outer">
                            <input
                                className="inp alt"
                                type="email"
                                name='email'
                                value={details.email}
                                onChange={handleChange}
                                placeholder='Enter Email Here'
                                required
                            />
                        </div>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="">Password</label>
                        <div className="inp-outer">
                            <input
                                className="inp alt"
                                type={view ? "text" : "password"}
                                name='password'
                                placeholder='Enter Password'
                                value={details.password}
                                onChange={handleChange}
                                required={curElem ? false : true}

                            />
                            <span
                                className="inp-icon"
                                onMouseUp={() => setView(false)}
                                onMouseLeave={() => setView(false)}
                                onMouseDown={() => setView(true)}
                                style={{ cursor: "pointer" }}
                            >
                                <AiOutlineEyeInvisible />
                            </span>
                        </div>
                    </div>
                    <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                    <div className="input-wrap text-center">
                        {curElem ?
                            <button className="theme-btn"><span>{loader ? <>Updating <FaSpinner className="spin" /></> : "Update Member"}</span></button>
                            :
                            <button className="theme-btn"><span>{loader ? <>Adding <FaSpinner className="spin" /></> : "Add Member"}</span></button>
                        }
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateTeamModal