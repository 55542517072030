import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { fetchData } from '../../../../Redux/Actions/CommonActions'
import { useDispatch, useSelector } from 'react-redux'
import { onCreateConversation } from '../../../../Redux/Actions/ProjectActions'
import { FaSpinner } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const CreateProjConvModal = ({ show, handleClose, fetchConv, proj, setProj }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const activeCompany = useSelector(state => state.company.activeCompany)
    const [loader, setLoader] = useState({
        create: false
    })
    const [data, setData] = useState({
        name: "",
        type: "group",
        pid: "",
        cid: "",
        did: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "pid") {
            let pjData = proj.find(({ id }) => +id === +value)
            setData({
                ...data,
                pid: value,
                cid: pjData.companyId,
                did: pjData.departmentId
            })
        } else {
            setData({
                ...data,
                [name]: value
            })
        }

    }

    const fetchProjects = () => {
        let obj = { cid: activeCompany.id }
        dispatch(fetchData("fetch-project-list", obj, setProj, false, false, false, "shortDataLg"))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        dispatch(onCreateConversation(data, navigate, handleClose, loader, setLoader, false))
    }

    useEffect(() => {
        if (activeCompany) {
            fetchProjects()
        }
    }, [activeCompany])

    useEffect(() => {
        if (show) {
            setData({
                ...data,
                name: "",
                type: "group",
                pid: "",
                cid: "",
                did: ""
            })
        }
    }, [show])

    return (
        <Modal className="VideoModal modal-700" show={show} onHide={handleClose} centered>

            <span className="modalClose" onClick={handleClose}><AiOutlineClose /></span>

            <Modal.Body className="p-5">
                <form onSubmit={handleSubmit}>
                    <div className="company-block-title">
                        <div>
                            <h3>Create New Conversation</h3>
                            <p>Give a chat name to start a new conversation</p>
                        </div>

                    </div>
                    <div className="input-wrap">
                        <label htmlFor="">Conversation Name</label>
                        <div className="inp-outer">
                            <input
                                className="inp"
                                type="text"
                                name="name"
                                placeholder="Type Chat Name"
                                value={data.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="input-wrap">
                        <label htmlFor="">Select A Project</label>
                        <div className="inp-outer">
                            <select
                                className="inp"
                                name="pid"
                                value={data.pid}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Project</option>
                                {proj.length > 0 ?
                                    proj.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.id}>{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>
                        </div>
                    </div>

                    <div className="input-wrap">
                        <button className="theme-btn full" type='submit'><span>{loader.create ? <>Creating <FaSpinner className='spin' /></> : "Create New Conversation"}</span></button>
                    </div>
                </form>
            </Modal.Body>

        </Modal>
    )
}

export default CreateProjConvModal