import React, { useEffect, useState } from 'react'
import { BsImages, BsStars, BsUpload } from 'react-icons/bs'
import { FiSend } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { onEnterGoalInfo, onStartGodMode } from '../../../../../Redux/Actions/GodModeAction'
import { setAlert } from '../../../../../Redux/Actions/AlertActions'

const AutoModeInput = ({ assistant, message, setMessage, transcript }) => {
    const dispatch = useDispatch()
    const godMode = useSelector(state => state.godMode.data)
    const projectGodStatus = useSelector(state => state.project.godModeStatus)
    const [loader, setLoader] = useState(false)

    const handleSubmit = () => {
        // startInterval()
        if (godMode.maxTask > 0 && godMode.goal) {
            setLoader(true)
            dispatch(onStartGodMode(godMode, message, setMessage, assistant, setLoader))
            dispatch(onEnterGoalInfo("goal", ""))
        }else{
            let errMsg = "Please Provide task limit more than 0!" 
            if(godMode.goal === ""){
                errMsg = "Please provide goal to start!"
            }
            dispatch(setAlert(errMsg, "danger"))
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onEnterGoalInfo(name, value))
    }

    useEffect(() => {
        if (transcript && projectGodStatus) {
            dispatch(onEnterGoalInfo("goal", transcript))
        }
    }, [transcript])

    return (
        <div className="inp-outer auto-input">
            <div className="auto-input-icon">
                <span className="inp-iconN">
                    {/* <div className="user-nav">
                        <Dropdown>
                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                <div className="drop-icon"><GrAttachment /></div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="act-menu">
                                    <div className="embed-user-icon"><BsImages /></div>
                                    <div className="act-menu-txt">
                                        <h6>Image Prompt</h6>
                                        <p>Generate image reply</p>
                                    </div>
                                </div>
                                <div className="act-menu">
                                    <div className="embed-user-icon"><MdAlternateEmail /></div>
                                    <div className="act-menu-txt">
                                        <h6>Mention Staff</h6>
                                        <p>Mention Staff for reply</p>
                                    </div>
                                </div>
                                <div className="act-menu">
                                    <div className="embed-user-icon"><BsUpload /></div>
                                    <div className="act-menu-txt">
                                        <h6>Upload Image</h6>
                                        <p>Input image in prompt</p>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div> */}
                </span>

                <span className="inp-iconN custom-tool-hover">
                    <div className="drop-icon auto"><BsStars /></div>
                    <div className="custom-tool">
                        <div><BsStars /></div>
                        <div>
                            <h5>Auto Mode Enabled</h5>
                            <h6>Enter Number of Tasks</h6>
                        </div>
                    </div>
                </span>

                <div className="taskInp">
                    <label htmlFor="">Task</label>
                    <input
                        type="number"
                        placeholder="10"
                        value={godMode.maxTask}
                        name='maxTask'
                        onChange={(e) => handleChange(e)}
                        required
                    />
                </div>

            </div>
            <input
                type="text"
                className="inp alt"
                placeholder="Enter Your Goal"
                name='goal'
                value={godMode.goal}
                onChange={(e) => handleChange(e)}
                required
            />

            <div className="chat-icon">
                {/* <span><PiMicrophoneBold /></span> */}
                <span onClick={handleSubmit}><FiSend /></span>
            </div>
        </div>
    )
}

export default AutoModeInput