import React, { useEffect, useState } from 'react';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import BasicInformation from './BasicInformation';
import { useDispatch, useSelector } from 'react-redux';
import { onHireStaff, onSetStaffToHire, onUnmountStaffToHire } from '../../../../Redux/Actions/StaffActions';
import AvaliableVoices from './AvaliableVoices';
import CloneVoices from './CloneVoices';
import { FaSpinner } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const StaffHireModal = ({ curElem, show, handleClose, handleChange }) => {
    const dispatch = useDispatch()
    const hire = useSelector(state => state.staff.hire)
    const elevenlabs = useSelector(state => state.social.elevenlabs)
    const activeCompany = useSelector(state => state.company.activeCompany)
    const [loader, setLoader] = useState({
        submit: false
    })

    const [tabs, setTabs] = useState({
        first: true,
        second: false,
    })

    const handleSubmit = () => {
        let data = { ...hire }

        setLoader({
            ...loader,
            submit: true
        })
        let url = "hire-staff"
        if (data.staffId) {
            url = "update-hire-staff"
        } else {
            data.cid = activeCompany.id
        }
        dispatch(onHireStaff(url, data, handleClose, handleChange, loader, setLoader))
    }

    const handleActiveTab = (type) => {
        if (type === "first") {
            setTabs({
                first: true,
                second: false
            })
        }
        if (type === "second") {
            setTabs({
                first: false,
                second: true
            })
        }
    }

    useEffect(() => {
        if (show) {
            dispatch(onSetStaffToHire(curElem))
        } else {
            dispatch(onUnmountStaffToHire(curElem))
        }
    }, [show])

    return (
        <Modal className="VideoModal modal-800" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <span className="modalClose abs" onClick={handleClose}><AiOutlineClose /></span>

                <div className="site-wrapperN" style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <div className="site-containerN">
                        <div className="embed-wrap">
                            <div className="titleBar">
                                <div className="titleBar-left full text-center">
                                    <h2>Hire Your Staff</h2>
                                    <p>Give your staff a suitable name, description and avatar </p>
                                </div>
                            </div>

                            <div>
                                <Tab.Container id="left-tabs-example">
                                    <Nav variant="b" className="navStyle-short">
                                        <div className="navStyle-short-wrap">
                                            <Nav.Item>
                                                <Nav.Link onClick={() => handleActiveTab("first")} className={tabs.first ? 'active' : ""}>
                                                    <h6>Basic Information</h6>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link onClick={() => handleActiveTab("second")} className={tabs.second ? 'active' : ""}>
                                                    <h6>Staff Voice</h6>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </div>
                                    </Nav>
                                    <Tab.Content className="pt-0">
                                        <Tab.Pane className={`pt-4 ${tabs.first ? "active show" : ""}`}>
                                            <BasicInformation
                                                handleClose={handleClose}
                                                handleActiveTab={handleActiveTab}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane className={`${tabs.second ? "active show" : ""}`}>
                                            <div>
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">


                                                    <Nav variant="b" className="navStyle">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first">
                                                                <h6>Select a Voice</h6>
                                                                <p>Select a voice for your Staff</p>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second">
                                                                <h6>Clone Your Voice</h6>
                                                                <p>Connect your ElevenLabs account</p>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>


                                                    <Tab.Content className="pt-5">
                                                        <Tab.Pane eventKey="first">
                                                            <AvaliableVoices />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="second">
                                                            {elevenlabs.length === 0 ?
                                                                <div className="alert alert-warning p-3 mt-2" role="alert">
                                                                    Please go to the integrations tab to connect your ElevenLabs account. <Link to="/integrations">Click Here</Link>
                                                                </div> :
                                                                <CloneVoices />
                                                            }
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                                <div className="input-wrap">
                                                    {+hire.isFire === 0 ?
                                                        <button className="theme-btn full" onClick={handleSubmit}><span>{loader.submit ? <>Updating <FaSpinner className='spin' /></> : "Update"}</span></button>
                                                        :
                                                        <button className="theme-btn full" onClick={handleSubmit}><span>{loader.submit ? <>Hiring <FaSpinner className='spin' /></> : "Hire Now"}</span></button>
                                                    }
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default StaffHireModal;
