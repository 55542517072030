import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BiDotsHorizontalRounded, BiFolderPlus } from 'react-icons/bi';
import { BsFillCloudArrowUpFill, BsLink45Deg } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { onDeleteData } from '../../../../Redux/Actions/CommonActions';
import CommonAlert from '../../../CommonComponents/CommonAlert';
import { useDispatch, useSelector } from 'react-redux';

import { AiOutlineEdit } from "react-icons/ai";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEdit, BiSolidEdit } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";

const DirectoryRows = ({ curElem }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const [fileCount, setFileCount] = useState({
        upload: 0,
        website: 0
    })
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (dirId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: dirId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteData("delete-directory", data, "DELETE_DIRECTORY", setSweet))
    }

    useEffect(() => {
        if (curElem.data.length > 0) {
            let ul = 0, wl = 0
            curElem.data.forEach((curElem) => {
                if (curElem.type === "website") {
                    wl += 1
                }
                if (curElem.type === "upload") {
                    ul += 1
                }
            })
            setFileCount({
                ...fileCount,
                upload: ul,
                website: wl
            })
        }
    }, [curElem])

    return (
        <div className="col-sm-6 col-lg-4 col-xl-3">
            <div className="department-single alt">
                <div className="department-top">
                    <span className="embed-user-icon" ><BiFolderPlus /></span>
                    {+auth.user.isClientAccount === 1 ? null :
                        <div className="drop-style">
                            <Dropdown>
                                <Dropdown.Toggle variant="t" id="dropdown-basic">
                                    <BiDotsHorizontalRounded />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <ul>
                                        <li><Link to={`/update-directory?id=${curElem.id}`}><BiSolidEdit /> Edit</Link></li>
                                        <li ><a style={{ cursor: "pointer" }} onClick={() => onDelete(curElem.id)}><FiTrash2 /> Delete</a></li>
                                    </ul>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>}
                </div>
                <div className="department-single-txt">
                    <h4>{curElem.name}</h4>
                    <p>{curElem.description}</p>
                </div>
                <div className="department-bottom">
                    <h6><BsFillCloudArrowUpFill /> {fileCount.upload} Uploads</h6>
                    <h6><BsLink45Deg /> {fileCount.website} Websites Added</h6>
                </div>
            </div>
            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this directory?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div>
    );
}

export default DirectoryRows;
