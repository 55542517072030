import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { BsFiletypeDoc, BsFiletypePdf, BsImages } from 'react-icons/bs'
import { CiGlobe } from 'react-icons/ci'
import { FiCopy } from 'react-icons/fi'
import { HiDotsVertical } from 'react-icons/hi'
import { HiOutlineSpeakerWave } from 'react-icons/hi2'
import { onInternetAccess, onReadAloud } from '../../../../Redux/Actions/ProjectActions'
import { useDispatch, useSelector } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'
import { Document, Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { usePDF } from '@react-pdf/renderer';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { onGenerateImage } from '../../../../Redux/Actions/StaffActions'
import { appName } from '../../../../Global/Global'

const AssistantMessage = ({ curElem, socket, messages, setMessages, index, activeConv, assistant, setTypingActive, setShowIntTyping }) => {
    // console.log(curElem)
    let date = new Date()
    const dispatch = useDispatch()
    const [activeBlink, setActiveBlink] = useState(false)
    // const [selectedMsg, setSelectedMsg] = useState(false)
    const [pdf, setPdf] = useState(false)
    const rebrandData = useSelector(state => state.rebrand)

    const [loader, setLoader] = useState({
        generate: false,
        selectedIndex: -1,
        image: false
    })
    const [instance, updateInstance] = usePDF({
        document: pdf
    })
    const [activeText, setActiveText] = useState("")
    const [assiData, setAssiData] = useState({
        image: "",
        name: "",
        id: "",
        type: ""
    })

    const printHandle = (messageText) => {
        const element = document.createElement('div');
        element.innerHTML = `<pre >${displayTextWithLineBreaks(messageText, "20px Arial")}</pre><br><p style="text-align:right;z-index:1;position:absolute;bottom:10px;right:10px"><b style="color:black;">This Pdf is generated by ${rebrandData?.data?.name ? rebrandData?.data?.name : appName}</b></p>`;
        // element.innerHTML = `<pre >${formatText(messageText)}</pre><br><p style="text-align:right;z-index:1;position:absolute;bottom:10px;right:10px"><b style="color:black;">This Pdf is generated by AiStaff</b></p>`;
        element.style.fontFamily = 'Arial';
        element.style.border = "2px solid black"
        element.style.fontSize = '15px';
        element.style.color = 'black';
        element.style.padding = '10px';
        element.style.minHeight = "165vh"
        element.style.whiteSpace = 'pre-wrap';
        const options = {
            margin: 10,
            filename: "message",
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        html2pdf().set(options).from(element).save();
    }

    function displayTextWithLineBreaks(text, font) {
        let canvas = displayTextWithLineBreaks.canvas || (displayTextWithLineBreaks.canvas = document.createElement("canvas"));
        let context = canvas.getContext("2d");
        context.font = font;

        const maxLineWidth = 700; // 20px width constraint
        text = text.replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "")
        text = text.replaceAll(`<pre style="background-color:black;color:white">`, "")
        text = text.replaceAll("</pre>", "");
        //console.log(text);
        let words = text.split(' ');
        let lines = [];
        let currentLine = '';

        words.forEach(word => {
            const wordWidth = context.measureText(word).width;
            const currentLineWidth = context.measureText(currentLine).width;

            if (currentLine === '' || currentLineWidth + wordWidth < maxLineWidth) {
                currentLine += (currentLine === '' ? '' : ' ') + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        });

        lines.push(currentLine);
        //console.log(lines);
        for (let index = 0; index < lines.length; index++) {
            // if (lines[index].includes(`<pre style="background-color:black;color:white;width:100%,overflow-<br>x:scroll;border-radius:10px;padding:10px";>`)) {
            //     console.log(1);
            // } else {
            //     console.log(0);
            // }
            //console.log(lines[index]);
        }
        //console.log(lines.length);
        //let formattedText = lines.replaceAll(`style="background-color:black;color:white;width:100%,overflow-<br>x:scroll;border-radius:10px;padding:10px";`, "").replaceAll("</pre>;", "");
        let formattedText = lines.join(' <br /> ');
        //console.log(formattedText);


        return formattedText;
    }


    const handleAction = (type, ind) => {
        // let chatName = conversationData.find((ele) => +ele.id === +selectedcId)
        let copyText = document.getElementById(`message${index}`)
        if (type === "copy") {
            let p = document.createElement("textarea")
            let data = copyText.firstChild.innerHTML
            data = data.replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "").replaceAll(`<pre style="background-color:black;color:white">`, "").replaceAll("</pre>", "").replaceAll("&lt;", "<").replaceAll("&gt;", ">").replaceAll("<br>", "\n");
            p.value = data
            p.select();
            p.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(p.value);
            p.remove()
        } else if (type === "doc") {
            let documentName = activeConv.name
            let fileDownload = document.createElement("a");
            let data = copyText.firstChild.innerHTML
            data = data
                .replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "")
                .replaceAll(`<pre style="background-color:black;color:white">`, "")
                .replaceAll("</pre>", "").replaceAll("<br>", "\n")
                .replaceAll("&lt;", "\u2039").replaceAll("&gt;", "\u203A")
            fileDownload.href = `data:application/vnd.ms-word;charset=utf-8,` + encodeURIComponent(data)
            fileDownload.download = `${documentName}.doc`;
            fileDownload.click();

        } else {
            printHandle(copyText.firstChild.innerHTML);
        }
    }

    const InternetAccess = () => {
        if (index !== 0) {
            setTypingActive(true)
            setShowIntTyping(true)
            let data = {
                cid: activeConv.id,
                staffId: assiData.id,
                type: assiData.type,
                message: messages[index - 1].message
            }

            let myObj = {
                type: "user",
                message: messages[index - 1].message,
                staffId: assiData.id,
                fileType: "",
                fileUrl: "",
                graphics: [],
                staff: curElem.staff,
                assistant: { name: assiData.name },
                created: date.toLocaleString()
            }

            setMessages(prevMessages => {
                const updatedMessages = [...prevMessages];
                const lastIndex = updatedMessages.length;
                if (lastIndex >= 0) {
                    updatedMessages[lastIndex] = myObj
                }
                let scrollDiv = document.getElementById("chat-data-box")
                if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                    scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
                }
                setTimeout(() => {
                    setTypingActive(true)
                }, 3000)
                return updatedMessages;
            });

            dispatch(onInternetAccess(data, setTypingActive, setShowIntTyping, setActiveBlink, setMessages, assiData))
        }
    }

    const handleGenerateImage = (val, graphicIndex) => {
        let data = {
            id: val.id,
            prompts: val.messages ? val.messages : val.prompts,
            cId: activeConv.id,
            hireId: curElem.staff[0].id
        }
        setLoader({
            ...loader,
            image: true,
            selectedIndex: graphicIndex
        })
        dispatch(onGenerateImage(data, messages, setMessages, graphicIndex, loader, setLoader))
    }

    const handleDownloadImage = (e, val) => {
        e.stopPropagation()
        let a = document.createElement('a')
        a.href = val
        a.target = "_blank"
        a.download = 'Download.png';
        a.click()
    }

    const handleReadAloud = () => {
        if (!loader.generate) {
            let data = {
                text: curElem.message,
                hireId: assiData.id,
                langCode: activeConv.langCode
            }
            setLoader({
                ...loader,
                generate: true
            })
            dispatch(onReadAloud(data, loader, setLoader))
        }
    }

    useEffect(() => {
        if (socket) {
            let msg = "";
            const handleReceivedResponse = (res) => {
                switch (res.status) {

                    case "progress":
                        if (!activeBlink && (messages.length - 1 === index)) {
                            setActiveBlink(true);
                        }
                        msg += res.message;
                        setActiveText(prev => prev + res.message);
                        let scrollDiv = document.getElementById("chat-data-box")
                        if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                            scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
                        }
                        break;
                    case "complete":
                        setActiveBlink(false);
                        setActiveText("");
                        if (curElem.staffType === "Graphic Designer") {
                            setMessages(prevMessages => {
                                const updatedMessages = [...prevMessages];
                                const lastIndex = updatedMessages.length - 1;
                                if (lastIndex >= 0) {
                                    updatedMessages[lastIndex] = {
                                        ...updatedMessages[lastIndex],
                                        message: res.message,
                                        graphics: res.graphic.messages
                                    };
                                }
                                return updatedMessages;
                            });
                        } else {
                            setMessages(prevMessages => {
                                const updatedMessages = [...prevMessages];
                                const lastIndex = updatedMessages.length - 1;
                                if (lastIndex >= 0) {
                                    updatedMessages[lastIndex] = {
                                        ...updatedMessages[lastIndex],
                                        message: msg
                                    };
                                }
                                return updatedMessages;
                            });
                        }

                        break;
                    default:
                        break;
                }
            };

            socket.on("received-response", handleReceivedResponse);

            return () => {
                socket.off("received-response", handleReceivedResponse);
            };
        }
    }, [socket])


    useEffect(() => {
        if (curElem.staff.length > 0) {
            setAssiData({
                ...assiData,
                image: curElem.staff[0].image,
                name: curElem.staff[0].name,
                type: curElem.staff[0].type,
                id: curElem.staff[0].id
            })
        }
    }, [curElem])
    return (
        <div className="message-wrap">
            <div className="message-single">
                <div className="message-group">
                    <div className="user-photo"><img alt="" src={assiData.image} /></div>
                    <div className="message-head">
                        <span>{assiData.name}</span>
                        <span className='ms-2'>{curElem.created}</span>
                    </div>
                    <div className="message-body">
                        <div className="message-txt" id={`message${index}`}>
                            {curElem.message ? "" :
                                <div className='text-center'>
                                    <FaSpinner className='spin' />
                                </div>
                            }
                            <p dangerouslySetInnerHTML={{ __html: activeBlink && (messages.length - 1 === index) ? activeText.replaceAll('\n', `<br/>`) : curElem.message.replaceAll('\n', `<br/>`) }} />
                            {curElem.graphics.length > 0 ?
                                curElem.graphics.map((msg, ind) => {
                                    return (
                                        <div
                                            onClick={() => handleGenerateImage(msg, ind)}
                                            className='graphic-text my-3 p-2'
                                            style={{ cursor: "pointer", background: "#ededed" }}
                                            key={ind}
                                        >
                                            <span dangerouslySetInnerHTML={{ __html: msg.prompts ? msg.prompts.replaceAll('\n', `<br/>`) : msg.messages.replaceAll('\n', `<br/>`) }} />
                                            <br />
                                            <br />
                                            <div className='text-center my-2'>
                                                {loader.image && loader.selectedIndex === ind ?
                                                    <FaSpinner className='spin' style={{ color: "#000", fontSize: 20 }} />
                                                    :
                                                    msg.image !== "" && msg.image ?
                                                        <div>
                                                            <img src={msg.image} alt="" width={"70%"} /><br />
                                                            <button className='theme-btn mt-2' onClick={(e) => handleDownloadImage(e, msg.image)}><span>Download</span></button>
                                                        </div>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    )

                                }) : ""}


                            {curElem.sources && curElem.sources !== "" ?
                                curElem.sources.link !== "" ?
                                    <p className='mt-3 access-link'>
                                        <a href={curElem.sources.link} target='_blank' >Source 1</a>
                                    </p>
                                    : ""
                                : ""
                            }
                        </div>
                        {index === 0 ? null :
                            <div className="user-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="g" id="dropdown-basic">
                                        <div className="drop-icon"><HiDotsVertical /></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="act-menu sm" onClick={() => handleAction("copy")}>
                                            <div className="embed-user-icon"><FiCopy /></div>
                                            <div className="act-menu-txt">
                                                <h6>Copy Text</h6>
                                            </div>
                                        </div>
                                        <div className="act-menu sm" onClick={() => handleAction("doc")}>
                                            <div className="embed-user-icon"><BsFiletypeDoc /></div>
                                            <div className="act-menu-txt">
                                                <h6>Export to Doc</h6>
                                            </div>
                                        </div>
                                        <div className="act-menu sm" onClick={() => handleAction("pdf", index)}>
                                            <div className="embed-user-icon"><BsFiletypePdf /></div>
                                            <div className="act-menu-txt">
                                                <h6>Export to PDF</h6>
                                            </div>
                                        </div>
                                        <div className="act-menu sm" onClick={InternetAccess}>
                                            <div className="embed-user-icon"><CiGlobe /></div>
                                            <div className="act-menu-txt">
                                                <h6>Access Internet</h6>
                                            </div>
                                        </div>
                                        <div className="act-menu sm" onClick={handleReadAloud}>
                                            <div className="embed-user-icon">{loader.generate ? <FaSpinner className='spin' /> : <HiOutlineSpeakerWave />}</div>
                                            <div className="act-menu-txt">
                                                <h6>Read Aloud </h6>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssistantMessage