import React from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FiDownload } from 'react-icons/fi'

const ImagePreview = ({ show, handleClose, curElem }) => {

    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = curElem.fileUrl
        a.target = "_blank"
        a.click()
    }

    return (
        <Modal className="VideoModal modal-700 no-bg" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div>
                    <div className="closePrev"><span onClick={handleClose}>Close Preview <AiOutlineClose /></span></div>
                    <p style={{color: '#FFFFFF'}}>Image 2</p>
                    {/* <p style={{color: '#FFFFFF'}}>Dog driving on Bike, Realistic Image, Digital art, wearing helmet, Clean image </p> */}
                    <div className="modal-img"><img alt="" src={curElem.fileUrl} /></div>
                    <div className="modal-img-download">
                        <button className="theme-btn grey" onClick={handleDownload}><span><FiDownload /> Download Image</span></button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImagePreview