import React, { useEffect, useState } from "react";
import { RiRobot2Fill } from 'react-icons/ri'
import { AiOutlineEyeInvisible } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom";
import LoginHeader from "../../Header/LoginHeader";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, masterLoginUser } from "../../../Redux/Actions/AuthActions";
import { appName } from "../../../Global/Global";
import { FaSpinner } from "react-icons/fa";
import TitleBar from "../../CommonComponents/TitleBar";

const MasterLogin = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [view, setView] = useState(false)
    const rebrandData = useSelector(state => state.rebrand)

    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: "",
        useremail: ""
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(masterLoginUser(loginInfo, setLoader, navigate))
    }
    return (
        <>
            <TitleBar title="Master Login" />
            <div className="login-header"><LoginHeader /></div>

            <div className="login-wrap">

                <div className="login-left">
                    <h2>Welcome to <span>{rebrandData?.data?.name ? rebrandData?.data?.name : appName}</span></h2>
                    <p className="pt-3">Launch your very own AI HR Agency —fully white-label the platform, rebrand it under your business name, including the URL, and offer AI-powered teams as your own service to clients. Step into the future where AI innovation and new revenue streams are just a click away.</p>
                </div>

                <div className="login-right">

                    <div className="login-box">
                        <div className="symbol"><RiRobot2Fill /></div>
                        <h2>Master Login to {rebrandData?.data?.name ? rebrandData?.data?.name : appName}</h2>
                        <form className="login-form" onSubmit={handleSubmit}>
                            <div className="input-wrap">
                                <label htmlFor="">User Email Address</label>
                                <div className="inp-outer">
                                    <input
                                        className="inp"
                                        type="email"
                                        name="useremail"
                                        placeholder='User Email Address'
                                        value={loginInfo.useremail}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="input-wrap">
                                <label htmlFor="">Admin Email Address</label>
                                <div className="inp-outer">
                                    <input
                                        className="inp"
                                        type="email"
                                        name="email"
                                        placeholder='Admin Email Address'
                                        value={loginInfo.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="input-wrap">
                                <label htmlFor="">Passoword</label>
                                <div className="inp-outer">
                                    <input
                                        className="inp"
                                        type={view ? "text" : "password"}
                                        placeholder="Password"
                                        required
                                        onChange={handleChange}
                                        value={loginInfo.password}
                                        name="password"
                                    />
                                    <span
                                        className="inp-icon"
                                        onMouseUp={() => setView(false)}
                                        onMouseLeave={() => setView(false)}
                                        onMouseDown={() => setView(true)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <AiOutlineEyeInvisible />
                                    </span>
                                </div>
                            </div>
                            <div className="input-wrap">
                                <button className="theme-btn full" type="submit"><span>Login {loader ? <FaSpinner className="spin ms-1" /> : null}</span></button>
                            </div>
                            {/* <div className="input-wrap">
                    <div className="or"><span>OR</span></div>
                </div> */}

                            {/* <div className="social-sign">
                    <div className="social-sign-icon"><img alt="" src={require('../../../assets/images/icon/google-logo.svg').default} /></div>
                    <button>Sign up with Google</button>
                </div>
                <div className="social-sign">
                    <div className="social-sign-icon"><img alt="" src={require('../../../assets/images/icon/facebook-logo.svg').default} /></div>
                    <button>Sign up with Facebook</button>
                </div> */}

                        </form>

                        <div className="log-option text-center">Forgot your account details? <Link to="/forgot-password">Click here</Link></div>
                    </div>



                </div>
            </div>



            <div className="login-footer"><Footer /></div>
        </>
    )
}

export default MasterLogin