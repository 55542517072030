import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeHireStaff, onHireStaff } from '../../../../Redux/Actions/StaffActions';
import { FaSpinner } from 'react-icons/fa6';
import { onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';

const BasicInformation = ({ handleClose, handleActiveTab }) => {
    const dispatch = useDispatch()
    const hire = useSelector(state => state.staff.hire)
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        submit: false,
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeHireStaff(name, value))
    }

    const onCompeleteUpload = (url) => {
        dispatch(onChangeHireStaff("image", url))
    }


    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 50000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        handleActiveTab("second")
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="input-wrap mt-0">
                <label htmlFor="">Staff Name</label>
                <div className="inp-outer">
                    <input
                        className="inp"
                        type="text"
                        placeholder="Enter name here"
                        name='name'
                        value={hire.name}
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>
            <div className="input-wrap">
                <label htmlFor="">Give a description to your staff</label>
                <div className="inp-outer">
                    <textarea
                        className="inp"
                        name="description"
                        placeholder="Write Description"
                        value={hire.description}
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>

            <div className="input-wrap">
                <label htmlFor="">Negative Prompt</label>
                <div className="inp-outer">
                    <textarea
                        className="inp"
                        name='negativePrompt'
                        value={hire.negativePrompt}
                        onChange={handleChange}
                        placeholder="Write negative prompt to specify elements you want to avoid in the output"
                    />
                </div>
            </div>


            <div className="input-wrap">
                <div className="file-field">
                    <div className="me-3 d-flex align-items-center justify-content-center flImg" style={{ height: 50 }}>
                        {loader.upload ?
                            <FaSpinner className="spin" size={25} />
                            :
                            <img alt="" src={hire.image} style={{ maxHeight: "100%" }} />
                        }
                    </div>
                    <div>
                        <h6>Click to Upload Your Staff Image</h6>
                        <p>JPG, PNG, JPEG upto 5MB is allowed</p>
                    </div>
                    {loader.upload ? null :
                        <input
                            type="file"
                            onChange={onInputImage}
                            accept="image/jpg, image/png, image/jpeg"
                        />
                    }
                </div>

            </div>

            <div className="input-wrap">
                <button className="theme-btn full" type='submit'><span>Next</span></button>
            </div>
        </form>
    );
}

export default BasicInformation;
