import React, { useState } from "react";

import CompanyHeader from "../../Header/CompanyHeader";
import Footer from "../../Footer/Footer";

import { Link } from "react-router-dom";
import { BiTrash, BiSolidCopy, BiChevronLeft } from "react-icons/bi"
import { BsCardImage, BsGlobeEuropeAfrica } from "react-icons/bs"
import { AiOutlineClose } from "react-icons/ai"

import { Modal } from "react-bootstrap";

function CreateEmbedNew() {

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  return (
    <>
      <CompanyHeader></CompanyHeader>

      <div className="site-wrapper">
        <div className="site-container">
          <div className="company-empty">

            <div className="company-block">
              <div className="company-block-title">
                <div>
                  <h3>Create Embed Code</h3>
                  <p>Add Basic Embed Info for the staff </p>
                </div>
              </div>
            </div>

            <div>
              <div className="input-wrap">
                <label htmlFor="">Name</label>
                <div className="inp-outer">
                  <input className="inp" type="text" placeholder="Enter Embed Name" />
                </div>
              </div>

              <div className="input-wrap">
                <label htmlFor="">Website URL</label>
                <div className="inp-outer">
                  <input className="inp" type="text" placeholder="Enter website URL" />
                </div>
              </div>

              <div className="input-wrap">
                <label htmlFor="">Autoresponder</label>
                <div className="inp-outer">
                  <select name="" id="" className="inp">
                    <option value="">Select Autoresponder</option>
                  </select>
                </div>
              </div>

              <div className="input-wrap">
                <label htmlFor="">Autoresponder List</label>
                <div className="inp-outer">
                  <select name="" id="" className="inp">
                    <option value="">Select Autoresponder List</option>
                  </select>
                </div>
              </div>

              <div className="input-wrap">
                <div className="file-field">
                  <div className="me-3"><img alt="" src={require('../../../assets/images/icon/img.png')} /></div>
                  <div>
                    <h6>Click to Upload Embed Image</h6>
                    <p>JPG, PNG, JPEG upto 5MB is allowed</p>
                  </div>
                  <input type="file" />
                </div>
              </div>
              <div className="input-wrap">
                <button onClick={handleShow2} className="theme-btn full"><span>Create Embed</span></button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <Footer></Footer>



      <Modal className="VideoModal modal-700" show={show2} onHide={handleClose2} centered>
        <Modal.Body>
          <span className="modalClose abs" onClick={handleClose2}><AiOutlineClose /></span>
          <div className="">
            <div className="pt-4">

              <div className="confirmation-cont text-center">
                <div className="modalIcon"><BsCardImage /></div>
                <h5 className="pt-4">Your Embed “Johnsondoe”Create Successfully</h5>
                <p>You can copy and paste Code now</p>
              </div>

              <div className="">
                <div className="embed-block">
                  <div className="embed-block-top">
                    <div className="embed-block-top-left">
                      <h2>Header Code</h2>
                      <p>Insert this code in the header section between &#x3C;head&#x3E;&#x3C;/head&#x3E; tags in your page</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="embed-user-icon ms-2"><BiSolidCopy /></div>
                    </div>
                  </div>
                  <div className="code-block">
                    <p>&#x3C;script type=&#x22;text/Javascript&#x22; id=&#x22;ai_widget&#x22; src=&#x22;https://backend.aistaffs.com/front-end/chat-box/embed.js type=aiStaff&#x26;staffId=26&#x26;uId=3180&#x26;arId=0&#x26;arListId=0&#x26;icn=https://reeelapps-app.s3.us-west-2.amazonaws.com/aistaff/embed_logo/Embed_logo.png&#x26;webUrl=https://google.com&#x26;embId=158&#x22;&#x3E;&#x3C;/script&#x3E; </p>
                  </div>
                </div>

                <div className="embed-block">
                  <div className="embed-block-top">
                    <div className="embed-block-top-left">
                      <h2>Body Code</h2>
                      <p>Insert this code in the header section between &#x3C;head&#x3E;&#x3C;/head&#x3E; tags in your page</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="embed-user-icon ms-2"><BiSolidCopy /></div>
                    </div>
                  </div>
                  <div className="code-block">
                    <p>&#x3C;div id = &#x22;aistaff_chat_box&#x22;&#x3E;&#x3C;/div &#x3E; </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default CreateEmbedNew;