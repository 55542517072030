import React, { useEffect, useState } from 'react';
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa6';
import VoiceRows from './VoiceRows';

const AvaliableVoices = () => {
    const dispatch = useDispatch()
    const hire = useSelector(state => state.staff.hire)
    const [voices, setVoices] = useState([])
    const [voicesShow, setVoicesShow] = useState([])

    const [language, setLanguage] = useState([])
    const [accent, setAccent] = useState([])
    const [tone, setTone] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const [filterObj, setFilterObj] = useState({
        lang: "",
        gender: "",
        accent: "",
        tone: "",
        langId: 0
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        console.log(name, value)
        if (name === "lang") {
            let languageId = language.find(({ name }) => name === value)
            console.log(languageId)
            setFilterObj({
                ...filterObj,
                [name]: value,
                langId: languageId ? languageId.id : 0
            })
        } else {
            setFilterObj({
                ...filterObj,
                [name]: value
            })
        }
    }

    const handleRemoveFilter = () => {
        setFilterObj({
            ...filterObj,
            lang: "",
            gender: "",
            accent: "",
            tone: "",
            langId: 0
        })
    }

    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchData("fetch-languages", data, setLanguage, false, false, false, "shortDataLg"))
    }

    const fetchTone = () => {
        let data = {}
        dispatch(fetchData("fetch-tone", data, setTone, false, false, false, "shortDataLg"))
    }

    const fetchAccents = () => {
        let data = {}
        dispatch(fetchData("fetch-accents", data, setAccent, false, false, false, "shortDataLg"))
    }
    const fetchVoices = () => {
        let data = {}
        dispatch(fetchData("fetch-voices", data, setVoices, loader, setLoader, false, "shortDataLg"))
    }


    useEffect(() => {
        if (voices.length > 0) {
            let dataArr
            if (filterObj.lang === "" && filterObj.gender === "" && filterObj.tone === "" && filterObj.accent === "") {
                dataArr = voices
            } else {
                dataArr = voices.filter((curElem) => {
                    return (filterObj.lang.includes(curElem?.language) || filterObj.lang === "") &&
                        (curElem.gender?.toLowerCase() === filterObj.gender.toLowerCase() || filterObj.gender === "") &&
                        (curElem.tone?.toLowerCase() === filterObj.tone.toLowerCase() || filterObj.tone === "") &&
                        (curElem?.accent.includes(filterObj.accent) || filterObj.accent.toLowerCase() === "")
                })
            }
            setVoicesShow(dataArr.filter((curElem) => {
                return curElem.url && curElem.image
            }))
        }
    }, [voices, filterObj])


    useEffect(() => {
        if (hire.gender) {
            setFilterObj({
                ...filterObj,
                gender: hire.gender,
            })
        }
    }, [hire.gender])

    useEffect(() => {
        fetchLanguage()
        fetchTone()
        fetchAccents()
        fetchVoices()
    }, [])
   
    return (
        <>
            <div className="titleBar">
                <div className="titleBar-left full">
                    <h2>Available Voices</h2>
                    <p>Select a voice that matches your staff</p>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col">
                    <div className="input-wrap">
                        <label>All Voice</label>
                        <div className="inp-outer"
                            style={{
                                background: (filterObj.lang === "" && filterObj.gender === "" && filterObj.tone === "" && filterObj.accent === "") ? "linear-gradient(90deg, #9461FC 0.08%, #EF1564 101.7%)" : "",
                                cursor: "pointer"
                            }}
                            onClick={handleRemoveFilter}
                        >
                            <input
                                className="inp"
                                value={"All"}
                                readOnly
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="input-wrap">
                        <label>Language</label>
                        <div className="inp-outer">
                            <select
                                className="inp"
                                onChange={handleChange}
                                value={filterObj.lang}
                                name='lang'
                            >
                                <option value="">All</option>
                                {language.length > 0 ?
                                    language.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.name}>{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="input-wrap">
                        <label>Gender</label>
                        <div className="inp-outer">
                            <select
                                className="inp"
                                onChange={handleChange}
                                value={filterObj.gender}
                                name='gender'
                            >
                                <option value="">All</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="input-wrap">
                        <label>Accent</label>
                        <div className="inp-outer">
                            <select
                                className="inp"
                                onChange={handleChange}
                                value={filterObj.accent}
                                name='accent'

                            >
                                <option value="">All</option>
                                {accent.length > 0 ?
                                    accent.filter((curElem) => +curElem.language_id === +filterObj.langId || filterObj.langId === 0).map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.name}>{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="input-wrap">
                        <label>Tone</label>
                        <div className="inp-outer">
                            <select
                                className="inp"
                                onChange={handleChange}
                                value={filterObj.tone}
                                name='tone'
                            >
                                <option value="">All</option>
                                {tone.length > 0 ?
                                    tone.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.name}>{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="embed-top">
                <span>Voice Name</span>
                <span>Language</span>
                <span>Tone</span>
                <span>Accent</span>
                <span style={{ opacity: 1 }}>Action</span>
            </div>
            <div className="embed-list" style={{ height: 500, overflowY: "auto" }}>
                {voicesShow.length > 0 ?
                    voicesShow.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <VoiceRows curElem={curElem} />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className='text-center mt-4'>
                        {loader.fetch ?
                            <FaSpinner className='spin' size={25} /> : "No voice found!"}
                    </div>
                }
            </div>
        </>
    );
}

export default AvaliableVoices;
