import produce from "immer"
const initialState = {
    article: [],
    videos: []
}

export const HelpReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_ARTICLE":
            return produce(state, (draft) => {
                draft.article = action.payload
            })
        case "ADD_VIDEO":
            return produce(state, (draft) => {
                draft.videos = action.payload
            })

        default:
            return state
    }
}