import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsFillGridFill, BsFillLayersFill, BsChatDotsFill } from "react-icons/bs"
import { TiUser } from "react-icons/ti"
import { RiRobot2Fill } from "react-icons/ri"
import { AiOutlineMenu } from "react-icons/ai"
import UserNav from "../CommonComponents/UserNav";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataRedux } from "../../Redux/Actions/CommonActions";
import { FaCirclePlus } from "react-icons/fa6";
import queryString from "query-string";
import { FaEye } from "react-icons/fa";
import { onActiveCompany, onDeactiveCompany } from "../../Redux/Actions/CompanyActions";
import { onToggleMenu } from "../../Redux/Actions/ExtraActions";
import { AiOutlineClose } from "react-icons/ai";
const Header = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { cid } = queryString.parse(location.search)
    const auth = useSelector(state => state.auth)
    const company = useSelector(state => state.company.list)
    const toggleMenu = useSelector(state => state.extra.data.toggleMenu)
    const activeCompany = useSelector(state => state.company.activeCompany)
    const rebrandData = useSelector(state => state.rebrand)

    const fetchCompany = () => {
        dispatch(fetchDataRedux("fetch-company-list", {}, "FETCH_COMPANY_LIST", false, false))
    }
    const handleSelectCompany = (data) => {
        if (+activeCompany.id !== +data.id) {
            dispatch(onActiveCompany(data))
            const url = new URL(window.location.href);
            url.searchParams.set('cid', data.id);
            window.history.pushState(null, '', url.toString());
        }
    }

    const handleMenu = () => {
        dispatch(onToggleMenu(!toggleMenu))
    }

    const handleClickLink = () => {
        if (toggleMenu) {
            dispatch(onToggleMenu(false))
        }
    }

    useEffect(() => {
        if (cid) {
            let data = company.find(({ id }) => +id === +cid)
            if (data) {
                dispatch(onActiveCompany(data))
            }
        }
        else {
            if (company.length > 0) {
                dispatch(onActiveCompany(company[0]))
            } else {
                dispatch(onDeactiveCompany())
            }
        }
    }, [cid, company])

    useEffect(() => {
        fetchCompany()
    }, [])

    return (
        <>
            <header className="site-header">
                <div className="site-container">
                    <div className="site-header-main">
                        <div className="logo">
                            <Link to={activeCompany ? `/dashboard?cid=${activeCompany.id}` : "/dashboard"}>
                                <img alt="" src={rebrandData?.data?.logo ? rebrandData?.data?.logo : require('../../assets/images/logo.png')} />
                            </Link>
                        </div>
                        <div className="header-right">
                            <div className={`site-nav  ${toggleMenu ? "act" : ""}`}>
                                {toggleMenu ? <AiOutlineClose style={{ position: "absolute", top: 17, right: 17, fontSize: 25, cursor: "pointer" }} onClick={handleMenu} /> :
                                    ""
                                }
                                <ul>
                                    <li><NavLink onClick={handleClickLink} to={activeCompany ? `/dashboard?cid=${activeCompany.id}` : "/dashboard"}><span><BsFillGridFill /></span> Dashboard</NavLink></li>
                                    <li><NavLink onClick={handleClickLink} to={activeCompany ? `/staffs?cid=${activeCompany.id}` : "/staffs"}><span><TiUser /></span> Staffs</NavLink></li>
                                    <li><NavLink onClick={handleClickLink} to={activeCompany ? `/youai?cid=${activeCompany.id}` : "/youai"}><span><RiRobot2Fill /></span> Custom Agent</NavLink></li>
                                    <li><NavLink onClick={handleClickLink} to={activeCompany ? `/conversation?cid=${activeCompany.id}` : "/conversation"}><span><BsChatDotsFill /></span> Conversation</NavLink></li>
                                    <li><NavLink onClick={handleClickLink} to={activeCompany ? `/departments?cid=${activeCompany.id}` : "/departments"}><span><BsFillLayersFill /></span> Departments</NavLink></li>
                                </ul>
                            </div>
                            <div className="profile-link">
                                <div className="company-link">
                                    <div className="company-link-in">
                                        {company.length > 0 ?
                                            <Dropdown>
                                                <Dropdown.Toggle variant="n" id="dropdown-basic">
                                                    <span className="company-logo"><img alt="" src={require('../../assets/images/settings.png')} /></span>
                                                    <span className="company-name">{activeCompany.name}</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <ul>
                                                        {+auth.user.isClientAccount === 1 ? null :
                                                            <li><Link to={`/create-company`}><FaCirclePlus size={16} /> <span>Create New Company</span></Link></li>}
                                                        {company.slice(0, 5).map((curElem, index) => {
                                                            return (
                                                                <li key={index}><a style={{ cursor: "pointer", background: +curElem.id === +activeCompany.id ? "linear-gradient(90deg, #9461FC 0.08%, #EF1564 101.7%)" : "" }} className="active" onClick={() => handleSelectCompany(curElem)}><img alt="" src={curElem.image} /> <span>{curElem.name}</span></a></li>
                                                            )
                                                        })}
                                                        <li><Link to={`/company`}><FaEye size={16} />  <span>View All</span></Link></li>
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            :
                                            <button type="button" aria-expanded="false" class="dropdown-toggle btn btn-n">
                                                <Link to={'/create-company'}> <span class="company-name">Create Company</span></Link>
                                            </button>
                                        }
                                    </div>
                                </div>

                                <UserNav />
                            </div>

                            <div className="mobMenu"><AiOutlineMenu style={{ cursor: "pointer" }} onClick={handleMenu} /></div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;