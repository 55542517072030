import React from 'react'
import { BsFillChatDotsFill, BsFillLayersFill } from 'react-icons/bs'
import { FiChevronRight } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const HomeDepart = () => {
    const departmentList = useSelector(state => state.department.list)
    const activeCompany = useSelector(state => state.company.activeCompany)
    const auth = useSelector(state => state.auth)
    return (
        <div className="dashboard-block departments">
            <h2>Departments</h2>
            <div className="recent-conv-list">
                <ul>
                    {departmentList.length > 0 ?
                        departmentList.map((curElem, index) => {
                            return (
                                <li key={index}>
                                    <a >
                                        <div className="recent-conv-left">
                                            <div className="recent-conv-icon">
                                                <BsFillLayersFill />
                                                <div className="user-symbol"><BsFillChatDotsFill /></div>
                                            </div>
                                            <div className="recent-conv-details">
                                                <h5>{curElem.name}</h5>
                                                <p>{curElem.staff.length} Members</p>
                                            </div>
                                        </div>
                                        <div className="recent-conv-right">
                                            <div className="next-icon"><FiChevronRight /></div>
                                        </div>
                                    </a>
                                </li>
                            )
                        })
                        :
                        <div className='text-center mt-4'>
                            {activeCompany && +auth.user.isClientAccount !== 1 ?
                                <>
                                    You do not have department for this company. <Link to={`/create-departments?cid=${activeCompany.id}`}>Click Here</Link> to create!
                                </>
                                :
                                <>
                                    You do not have any departments created.
                                </>
                            }
                        </div>
                    }
                </ul>
            </div>
        </div>
    )
}

export default HomeDepart