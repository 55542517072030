import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { BiDotsHorizontalRounded, BiSearch } from 'react-icons/bi'
import { RiUser3Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { fetchDataRedux } from '../../../Redux/Actions/CommonActions'
import { useDispatch, useSelector } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'

const StaffsList = ({ activeCompany }) => {
    const dispatch = useDispatch()
    const staffList = useSelector(state => state.staff.list)
    const [showStaff, setShowStaff] = useState([])
    const [q, setQ] = useState("")
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchStaffs = () => {
        let data = {
            cId: activeCompany.id
        }
        dispatch(fetchDataRedux("fetch-staff-credits", data, "FETCH_STAFF_LIST", loader, setLoader))
    }

    useEffect(() => {
        if (staffList.length > 0) {
            setShowStaff(staffList.filter((curElem) => +curElem.isFire === 0).filter((curElem) => curElem.name.toLowerCase().includes(q.toLowerCase())))
        }
    }, [staffList, q])
    useEffect(() => {
        if (activeCompany) {
            fetchStaffs()
        }
    }, [activeCompany])

    return (
        <div className="dashboard-block useage">
            <div className="useage-top">
                <div className="useage-top-left">
                    <h2>AIStaff Usage</h2>
                    <p>Check Staffs Performance and Usage</p>
                </div>
                <div className="useage-top-right">
                    <div className="select-style">
                        <div className="input-wrap alt mt-0">
                            <div className="inp-outer icon-left">
                                <input
                                    className="inp"
                                    type="text"
                                    placeholder="Search"
                                    value={q}
                                    onChange={(e) => setQ(e.target.value)}
                                />
                                <span className="inp-icon"><BiSearch /></span>
                            </div>
                        </div>
                        {/* <Select options={options} className="react-select-container" classNamePrefix="react-select" /> */}
                    </div>
                </div>
            </div>
            <div className="useage-data">
                {showStaff.length > 0 ?
                    showStaff.map((curElem, index) => {
                        console.log(curElem)
                        return (
                            <div className="useage-data-single" key={index}>
                                <div className="useage-data-left">
                                    <div className="useage-data-img"><img alt="" src={curElem.image} /></div>
                                    <div className="useage-data-cont">
                                        <h5>{curElem.name}</h5>
                                        <p><RiUser3Fill /> {curElem.category}</p>
                                    </div>
                                </div>
                                <div className="useage-data-right">
                                    <div>
                                        <h5>{curElem.usedCredits} <span>Used</span></h5>
                                        <p>Conversation Credits</p>
                                    </div>
                                    <div>
                                        <h5>0 <span>Used</span></h5>
                                        <p>Media Credits</p>
                                    </div>
                                    {/* <div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="t" id="dropdown-basic">
                                                <BiDotsHorizontalRounded />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <ul>
                                                    <li><Link to="/">Action</Link></li>
                                                    <li><Link to="/">Another action</Link></li>
                                                    <li><Link to="/">Something else</Link></li>
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> */}
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className="text-center mt-5">
                        {loader.fetch && activeCompany ?
                            <FaSpinner className="spin" size={25} /> :
                            "No Staff Used Yet!"
                        }
                    </div>
                }
            </div>
        </div>

    )
}

export default StaffsList