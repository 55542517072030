import React from "react";
import { useSelector } from "react-redux";

const LoginHeader = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <>
            <header className="site-header">
                <div className="site-container">
                    <div className="site-header-main">
                        <div className="logo"><img alt="" src={rebrand.logo ? rebrand.logo : require('../../assets/images/logo.png')} /></div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default LoginHeader;