import React from 'react'

const VideoFlix = () => {
    return (
        <div className="upgrade-tab-in">
            <div className="upgrade-tab-block">
                <h6>Video Flix</h6>
            </div>
            <div className="upgrade-tab-block">
                <h6>VideoFLIX - Netflix Style Video Site Builder</h6>
                <p className="pt-3">
                    VideoFLIX is a subscription-based video content
                    management app built on cloud. Using VideoFLIX you
                    will be able to create your own video content sharing
                    website similar to NetFlix, HBO-Go, DisneyPLUS etc;
                    sharing your own or others unique video content as
                    channels or series. You will be able to create free,
                    one-time or monthly payment plans for your customers
                    to access your video content and build your video
                    content subscription business!
                </p>
            </div>
            <div className="upgrade-tab-block">
                <div className='row text-center'>
                    <div className='col-md-6'>
                        <a
                            className="theme-btn mt-3"
                            rel="noreferrer"
                            href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix_2.2.zip"
                            target="_blank"
                        >
                            <span>Download VideoFlix</span>
                        </a>
                    </div>

                    <div className='col-md-6'>
                        <a
                            className="theme-btn mt-3"
                            rel="noreferrer"
                            href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix+Training+Docs+updated.zip"
                            target="_blank"
                        >
                            <span>Download Tutorial Docs</span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default VideoFlix