import React, { useEffect, useState } from 'react';
import { FaRegTrashCan, FaSpinner } from 'react-icons/fa6';
import { IoPause, IoPlay } from 'react-icons/io5';
import { TiUser } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeHireStaff } from '../../../../Redux/Actions/StaffActions';
import CommonAlert from '../../../CommonComponents/CommonAlert';
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions';
import { setAlert } from '../../../../Redux/Actions/AlertActions';
let music = false
const VoiceRows = ({ curElem, type, cloneVoices, setCloneVoices }) => {
    const dispatch = useDispatch()
    const hire = useSelector(state => state.staff.hire)
    const [play, setPlay] = useState(false)

    const [loader, setLoader] = useState({
        save: false,
        playRecording: false
    })
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false

    })

    const handleSelectVoice = (val) => {
        dispatch(setAlert("Voice applied successfully", "success"))
        dispatch(onChangeHireStaff("voice", { type: type ? type : "default", data: val }))
    }

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-clone-voice", data, cloneVoices, setCloneVoices, setSweet))
    }


    const handlePlay = () => {
        if (curElem.url) {
            // props.setSelectedPlay(curElem.url)
            if (music !== false) {
                music.pause()
                setPlay(false)
            }
            setLoader({
                ...loader,
                playRecording: true
            })
            music = new Audio(curElem.url)
            music.play()
                .then(r => {
                    setLoader({
                        ...loader,
                        playRecording: false
                    })
                    setPlay(true)
                }
                )
                .catch(err => console.log(err));

            music.onended = () => {
                setPlay(false)
            }
        }
    }

    const handlePause = () => {
        if (music !== false) {
            music.pause()
            setPlay(false)
            music = false
            // props.setSelectedPlay(false)
        }
    }



    useEffect(() => {
        return () => {
            if (music !== false) {
                music.pause();
            }
        }
    }, [])

    return (
        <div className="embed-single" >
            <div className="embed-user">
                <div className="switch-btn">
                    <label htmlFor="" className="switch">
                        <input
                            type="checkbox"
                            checked={+curElem.id === +hire.voice.data?.id}
                        />
                        <span className="slider round" onClick={() => handleSelectVoice(curElem)}></span>
                    </label>
                </div>
                {type === "clone" ? null :
                    <div className="embed-user-image ms-2"><img src={curElem.image} alt="" /></div>
                }
                <div className="embed-user-txt">
                    <h6>{curElem.name}</h6>
                    {type === "clone" ? null :
                        <p className="d-flex align-items-center gap-1"><TiUser /> {curElem.gender}</p>
                    }
                </div>
            </div>
            {type === "clone" ?
                <>
                    <div><span>{curElem.type}</span></div>
                    <div><span>{curElem.created}</span></div>
                </>
                :
                <>
                    <div><span>{curElem.language}</span></div>
                    <div><span>{curElem.tone}</span></div>
                    <div><span>{curElem.accent}</span></div>
                </>}
            <div className='d-flex gap-1'>
                {curElem.url ?
                    play ?
                        <div className="embed-user-icon" style={{ cursor: "pointer" }} onClick={handlePause}><IoPause /></div>
                        :
                        loader.playRecording ?
                            <div className="embed-user-icon"><FaSpinner className='spin' /> </div>
                            :
                            <div className="embed-user-icon" style={{ cursor: "pointer" }} onClick={handlePlay}><IoPlay /></div>
                    : null
                }
                {type === "clone" ?
                    <div className="embed-user-icon" style={{ cursor: "pointer" }} onClick={onDelete}><FaRegTrashCan /></div>
                    : ""}
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this voice?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div>
    );
}

export default VoiceRows;
