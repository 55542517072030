import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateDepartment = (url, data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate(`/departments?cid=${data.companyId}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchDpart = (data, setData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-department", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data[0])
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}

// No Axios Here

export const onChangeDepartment = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_DEPARTMENT", payload: { name, value } })
}

export const unmountCreateDepartment = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_CREATE_DEPARTMENT" })
}

export const unmountDepartmentList = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_DEPARTMENT_LIST" })
}