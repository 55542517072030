import React from "react";
import { AiOutlineMenu } from "react-icons/ai"
import UserNav from "../CommonComponents/UserNav";

const CompanyHeader = () => {

    return (
        <>
            <header className="site-header">
                <div className="site-container">
                    <div className="site-header-main">
                        <div className="logo"><img alt="" src={require('../../assets/images/logo.png')} /></div>
                        <div className="header-right">
                            <div className="profile-link">
                                <UserNav />
                            </div>

                            <div className="mobMenu"><AiOutlineMenu /></div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default CompanyHeader;