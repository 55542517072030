import React, { useEffect, useState } from 'react'
import { BsFillChatDotsFill } from 'react-icons/bs'
import { FiChevronRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { fetchData } from '../../../Redux/Actions/CommonActions'
import { useDispatch, useSelector } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'

const RecentConv = ({ activeCompany }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [conv, setConv] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })
    const fetchConv = () => {
        let data = {
            cid: activeCompany.id
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-conversation-all", data, setConv, loader, setLoader))
    }

    useEffect(() => {
        if (activeCompany) {
            fetchConv()
        }
    }, [activeCompany])

    return (
        <div className="dashboard-block recent-conv">
            <h2>Recent Conversations</h2>
            <div className="recent-conv-list">
                <ul>
                    {conv.length > 0 ?
                        conv.filter((curElem) => curElem.type === "single").length > 0 ?
                            conv.filter((curElem) => curElem.type === "single").slice(0, 5).map((curElem, index) => {
                                let image, name, category

                                if (curElem.staff.length > 0) {
                                    image = curElem.staff[0].image
                                    name = curElem.name
                                    category = curElem.staff[0].category
                                }
                                return (
                                    <li key={index}>
                                        {+auth.user.isClientAccount === 1 ?
                                            <a>
                                                <div className="recent-conv-left">
                                                    <div className="recent-conv-icon">
                                                        <img alt="" src={image} />
                                                        <div className="user-symbol"><BsFillChatDotsFill /></div>
                                                    </div>
                                                    <div className="recent-conv-details">
                                                        <h5 className='text-capitalize'>{name}</h5>
                                                        <p>{category}</p>
                                                    </div>
                                                </div>
                                                <div className="recent-conv-right">
                                                    <div className="next-icon"><FiChevronRight /></div>
                                                </div>
                                            </a> :
                                            <Link to={`/chat-screen?convId=${curElem.id}&cid=${curElem.companyId}&sid=${curElem.staff[0].id}&type=s`}>
                                                <div className="recent-conv-left">
                                                    <div className="recent-conv-icon">
                                                        <img alt="" src={image} />
                                                        <div className="user-symbol"><BsFillChatDotsFill /></div>
                                                    </div>
                                                    <div className="recent-conv-details">
                                                        <h5 className='text-capitalize'>{name}</h5>
                                                        <p>{category}</p>
                                                    </div>
                                                </div>
                                                <div className="recent-conv-right">
                                                    <div className="next-icon"><FiChevronRight /></div>
                                                </div>
                                            </Link>

                                        }
                                    </li>
                                )
                            })
                            :
                            <div className="text-center mt-4">
                                "No Recent Conversations!"
                            </div>
                        :
                        <div className="text-center mt-4">
                            {loader.fetch && !activeCompany ?
                                <FaSpinner className="spin" size={25} /> :
                                "No Recent Conversations!"
                            }
                        </div>

                    }


                </ul>
            </div>
        </div>
    )
}

export default RecentConv