import { produce } from "immer"

const initialState = {
    data: {
        name: "",
        logo: ""
    }
}

export const WhiteLabelData = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_WL_DATA":
            return produce(state, (draft) => {
                draft.data = action.payload
            })
        default:
            return state
    }
}