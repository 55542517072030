import React, { useState } from 'react'
import { baseURL } from '../../../../Global/Global'
import { useSelector } from 'react-redux'
import ConnectModal from './ConnectModal'
import ConnectRows from './ConnectRows'

const IntegrationAcc = (props) => {
    // console.log(props)
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleConnect = () => {
        if (props.type === "GC") {
            window.open(`${baseURL}google-calender-connect/${auth.user.id}`, "_blank")
        } else {
            handleShow()
        }
    }




    return (
        <div className="integrations-single">
            <div className="integrations-in">
                <div className="integration-left">
                    <div className="integration-img"><img alt="" src={props.image} /></div>
                    <div className="integration-txt">
                        <h6>{props.name}</h6>
                        <p>{props.desc}</p>
                    </div>
                </div>
                <div className="integration-right">
                    {/* use "connected" class to disable button */}
                    {props.type === "FB" ?
                        <></>
                        // <FacebookLogin
                        //     appId="780210132546000" //APP ID NOT CREATED YET
                        //     fields="name,email"
                        //     // scope="pages_manage_metadata,ads_management,business_management,instagram_basic,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                        //     scope="pages_manage_posts,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                        //     callback={(e) => responseFacebook(e)}
                        //     render={renderProps => (
                        //         <button onClick={renderProps.onClick} className="btn-change7">Connect</button>
                        //     )}
                        // />
                        :
                        <button className="theme-btn sm" disabled={props.data.length > 0} style={{ background: props.data.length > 0 ? "gray" : "" }} onClick={handleConnect}><span>Connect</span></button>

                    }


                </div>
            </div>


            {props.data.length > 0 ?
                props.data.map((curElem, index) => {
                    return (
                        <React.Fragment key={index}>
                            <ConnectRows
                                curElem={curElem}
                            />
                        </React.Fragment>
                    )
                })
                : ""}

            <ConnectModal
                show={show}
                handleClose={handleClose}
                name={props.name}
                url={props.url}
                shortType={props.type}
            />
        </div>
    )
}

export default IntegrationAcc