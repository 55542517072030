import React from "react";

import { RiRobot2Fill } from 'react-icons/ri'
import { AiOutlineEyeInvisible } from "react-icons/ai"

import { Link } from "react-router-dom";

import LoginHeader from "../../Header/LoginHeader";
import Footer from "../../Footer/Footer";
import { appName } from "../../../Global/Global";
import { useSelector } from "react-redux";

function Register() {
  const rebrandData = useSelector(state => state.rebrand)

  return (
    <>
      <div className="login-header"><LoginHeader></LoginHeader></div>

      <div className="login-wrap">

        <div className="login-left">
          <h2>Welcome to <span>{rebrandData?.data?.name ? rebrandData?.data?.name : appName}!</span></h2>
          <p className="pt-3">Launch your very own AI HR Agency —fully white-label the platform, rebrand it under your business name, including the URL, and offer AI-powered teams as your own service to clients. Step into the future where AI innovation and new revenue streams are just a click away.</p>
        </div>

        <div className="login-right">



          <div className="login-box">
            <div className="symbol"><RiRobot2Fill /></div>
            <h2>Register to AIStaff</h2>
            <div className="login-form">
              <div className="input-wrap">
                <label htmlFor="">Username</label>
                <div className="inp-outer">
                  <input className="inp" type="text" placeholder="Johnson" />
                </div>
              </div>
              <div className="input-wrap">
                <label htmlFor="">Email Address</label>
                <div className="inp-outer">
                  <input className="inp" type="text" placeholder="Johnsonwillamson@gmail.com" />
                </div>
              </div>
              <div className="input-wrap">
                <label htmlFor="">Passoword</label>
                <div className="inp-outer">
                  <input className="inp" type="password" value="Password" />
                  <span className="inp-icon"><AiOutlineEyeInvisible /></span>
                </div>
              </div>
              <div className="input-wrap">
                <label htmlFor="">Confirm Passoword</label>
                <div className="inp-outer">
                  <input className="inp" type="password" value="Password" />
                  <span className="inp-icon"><AiOutlineEyeInvisible /></span>
                </div>
              </div>
              <div className="input-wrap">
                <Link to="/dashboard-1"><button className="theme-btn full"><span>Register</span></button></Link>
              </div>

            </div>

            <div className="log-option">Already have an account?  <Link to="/">Login</Link></div>
          </div>



        </div>
      </div>

      <div className="login-footer"><Footer></Footer></div>
    </>
  )
}

export default Register;