import React, { useEffect, useState } from "react";

import BackHeader from "../../../Header/BackHeader";
import Footer from "../../../Footer/Footer";

import { BiTrash } from "react-icons/bi"
import { BsCodeSlash, BsCardImage } from "react-icons/bs"
import { AiOutlineClose } from "react-icons/ai"

import { Link, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataRedux } from "../../../../Redux/Actions/CommonActions";
import EmbedRows from "./EmbedRows";
import { FaSpinner } from "react-icons/fa";
import { unmountEmbedList } from "../../../../Redux/Actions/EmbedActions";
import { onFetchHiredStaff } from "../../../../Redux/Actions/StaffActions";

const EmbedViewMore = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const auth = useSelector(state => state.auth)
    const { hid, sid } = queryString.parse(location.search)
    const embedList = useSelector(state => state.embed.list)
    const [hiredStaff, setHiredStaff] = useState({
        name: "",
        image: ""
    })
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const fetchEmbeds = () => {
        let data = {
            staffId: sid,
            hireId: hid
        }
        dispatch(fetchDataRedux("fetch-embed-list", data, "FETCH_EMBED_LIST", loader, setLoader))
    }

    const fetchHiredStaff = () => {
        let data = {
            id: hid
        }
        dispatch(onFetchHiredStaff(data, setHiredStaff))
    }



    useEffect(() => {
        if (hid && sid) {
            fetchEmbeds()
            fetchHiredStaff()
        }

        return () => {
            dispatch(unmountEmbedList())
        }
    }, [hid, sid])

    return (
        <>
            <BackHeader />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="embed-wrap large">
                        <div className="titleBar">

                            <div className="titleBar-profile">
                                {hiredStaff.name ?
                                    <>
                                        <div className="titleBar-profile-img"><img alt="" src={hiredStaff.image} /></div>
                                        <div className="titleBar-left">
                                            <h2>{hiredStaff.name} Embeds</h2>
                                            <p>Create and Use embeds to your site</p>
                                        </div>
                                    </>
                                    : null}
                            </div>
                            <div className="titleBar-right">
                                {+auth.user.isClientAccount === 1 ? null :
                                    <Link to={`/create-embed?sid=${sid}&hid=${hid}`} className="theme-btn sm-w-100"><span><BsCodeSlash /> Create New Embed</span></Link>}
                            </div>
                        </div>

                        <div className="embed-top forEmbed">
                            <span>Embed Name</span>
                            <span>Website</span>
                            <span>Staff</span>
                            <span>Status</span>
                            <span>Action</span>
                        </div>

                        <div className="embed-list forEmbed">
                            {embedList.length > 0 ?
                                embedList.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <EmbedRows
                                                curElem={curElem}
                                                fetchEmbeds={fetchEmbeds}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                :
                                <div className="text-center mt-4">
                                    {loader.fetch ?
                                        <FaSpinner className="spin" size={25} /> :
                                        "No embed available"
                                    }
                                </div>

                            }
                            {/* <div className="embed-single">
                                <div className="embed-user">
                                    <div className="embed-user-icon"><BsCardImage /></div>
                                    <div className="embed-user-txt">
                                        <h6>Test Embed Name</h6>
                                        <p>15-09-2023 - 09:30 AM</p>
                                    </div>
                                </div>
                                <div><span>app.aistaffs.co</span>m</div>
                                <div><span>Johnson Doe</span></div>
                                <div>
                                    <div className="working-hours-left">
                                        <div className="switch-btn">
                                            <label htmlFor="" className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash /></div></Link>
                                    <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash /></div></Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            {/* Add Member Modal */}
            <Modal className="VideoModal sm-modal" show={show2} onHide={handleClose2} centered>
                <Modal.Body>
                    <span className="modalClose abs" onClick={handleClose2}><AiOutlineClose /></span>
                    <div className="">
                        <div className="text-center pt-4">
                            <div className="modalIcon"><BsCardImage /></div>

                            <div className="confirmation-cont">
                                <h5 className="pt-4">Are you sure to delete this “Johnson Doe - Embed AI Site” Embed ?</h5>
                                <p>You can’t Restore the deleted Embed data</p>
                            </div>

                            <div className="btnGroup mt-4 mb-4">
                                {/* <button className="theme-btn bdr" onClick={handleClose2}><span>Cancel</span></button> */}
                                <button className="theme-btn"><span><BiTrash /> Delete Embed</span></button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Add Member Modal */}

        </>
    )
}

export default EmbedViewMore;