import React, { useEffect, useState } from 'react'
import { BsPlusCircleFill, BsUpload } from "react-icons/bs"
import { PiMicrophoneBold, PiMicrophoneSlashBold } from "react-icons/pi"
import { MdAlternateEmail } from "react-icons/md"
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux'
import UserMessage from './UserMessage'
import AssistantMessage from './AssistantMessage'
import StaffToggleBox from './StaffToggleBox'
import { setAlert } from '../../../../Redux/Actions/AlertActions'
import { MdOutlineCancel } from "react-icons/md";
import AutoMode from './AutoMode/AutoMode';
import { FiSend } from 'react-icons/fi';
import { onSetInitialData, onUnsetInitialData } from '../../../../Redux/Actions/GodModeAction';
import AutoModeInput from './AutoMode/AutoModeInput';
import { FaSpinner } from 'react-icons/fa';
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import UploadFileModal from './UploadFileModal';
import pdfImg from "../../../../assets/images/icon/pdfFile.png"
import wordImg from "../../../../assets/images/icon/wordFile.png"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Messages = ({ messages, setMessages, fetchMessages, loader, assistant, setAssistant, type, socket }) => {
    let date = new Date()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const project = useSelector(state => state.project)

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const [languages, setLanguages] = useState([])
    const [text, setText] = useState("")

    const [file, setFile] = useState({
        type: "",
        url: ""
    })

    const [vcType, setVcType] = useState("en-US")

    const [typingActive, setTypingActive] = useState(false)
    const [showIntTyping, setShowIntTyping] = useState(false)

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [activeStaffBox, setActiveStaffBox] = useState(false)

    const handleSendMessage = (e) => {
        e.preventDefault()
        if (assistant) {

            let obj = false

            let myObj = {
                type: "user",
                fileType: file.type,
                message: text,
                staffId: assistant.id,
                staffType: assistant.category,
                assistant,
                staff: [assistant],
                fileUrl: file.url,
                created: date.toLocaleString()
            }

            setMessages(prevMessages => {
                const updatedMessages = [...prevMessages];
                const lastIndex = updatedMessages.length;
                if (lastIndex >= 0) {
                    updatedMessages[lastIndex] = myObj
                }
                obj = {
                    message: text,
                    conveArr: updatedMessages,
                    userId: auth.user.id,
                    cid: project.activeConv.id,
                    fileType: file.type,
                    staffId: assistant.id,
                    assistantId: assistant.assistantId,
                    threadId: project.activeConv?.threadId,
                    staffType: assistant.category,
                    type: assistant.type,
                    fileUrl: file.url
                }
                setText("")
                setFile({
                    type: "",
                    url: ""
                })
                socket.emit("send-message", obj)

                let scrollDiv = document.getElementById("chat-data-box")
                if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                    scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
                }

                setTimeout(() => {
                    setTypingActive(true)
                }, 3000)
                return updatedMessages;
            });

        } else {
            dispatch(setAlert("Please choose staff to chat with by using '@'", "danger"))
        }
    }


    const handleChange = (e) => {
        let { value } = e.target
        if (!assistant) {
            if (value.indexOf('@') === 0) {
                setActiveStaffBox(true)
            } else {
                setActiveStaffBox(false)
            }
        }
        setText(value)
    }

    const handleRemoveAssist = () => {
        setAssistant(false)
        dispatch(onUnsetInitialData())
    }
    const handleRemoveMedia = () => {
        setFile({
            type: "",
            url: ""
        })
    }

    const handleActiveListen = (type) => {
        if (type === "start") {
            if (assistant) {
                SpeechRecognition.startListening(
                    {
                        continuous: true,
                        language: vcType
                    }
                ).then(() => {

                })
            } else {
                dispatch(setAlert("Please choose staff to chat with by using '@'", "danger"))
            }
        } else {
            SpeechRecognition.stopListening().then(() => {
                resetTranscript()
            })

        }
    }

    const handleChnageVcLang = (e) => {
        setVcType(e.target.value)
        SpeechRecognition.stopListening().then(() => {
            resetTranscript()
        })
    }

    const fetchLanguages = () => {
        let data = {}
        dispatch(fetchData("fetch-languages", data, setLanguages, false, false, false, "shortDataLg"))
    }


    useEffect(() => {
        if (socket) {
            const handleReceivedResponse = (res) => {
                switch (res.status) {
                    case "start":
                        setTypingActive(false)
                        let obj = {
                            type: "assistant",
                            message: "",
                            assistant,
                            staff: [assistant],
                            staffId: assistant.id,
                            staffType: assistant.category,
                            graphics: [],
                            created: date.toLocaleString()
                        }
                        setMessages(prevMessages => {
                            const updatedMessages = [...prevMessages];
                            const lastIndex = updatedMessages.length;
                            if (lastIndex >= 0) {
                                updatedMessages[lastIndex] = obj
                            }

                            let scrollDiv = document.getElementById("chat-data-box")
                            if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                                scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
                            }
                            return updatedMessages;
                        });
                        break;
                    default:
                        break;
                }
            };

            socket.on("received-response", handleReceivedResponse);

            return () => {
                socket.off("received-response", handleReceivedResponse);
            };
        }
    }, [assistant])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.isShiftKey) {
            handleSendMessage(e)
        }
    }


    useEffect(() => {
        if (transcript && !project.godModeStatus) {
            setText(transcript)
        }
    }, [transcript])

    useEffect(() => {
        resetTranscript()
    }, [project.godModeStatus])


    const onSetGodIntialStaffData = (assist) => {
        let data = { cid: project.activeConv.id, staff: assist }
        dispatch(onSetInitialData(data))
    }

    useEffect(() => {
        if (project.activeConv) {
            if (project.activeConv.type === "single") {
                let asssist = project.activeConv.staff[0]
                setAssistant(asssist)
                onSetGodIntialStaffData(asssist)
            }
        }
    }, [project.activeConv])

   

    useEffect(() => {
        fetchLanguages()
    }, [])





    return (

        <div className="site-wrapper forChat">

            <div className="chat-container">
                <div className="messages" id='chat-data-box'>
                    {messages.length > 0 ?
                        messages.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {curElem.type === "user" ?
                                        <UserMessage
                                            curElem={curElem}
                                            socket={socket}
                                            activeConv={project.activeConv}
                                            assistant={assistant}
                                            typingActive={typingActive}
                                            index={index}
                                            length={messages.length}
                                            showIntTyping={showIntTyping}
                                        />
                                        :
                                        curElem.type === "assistant" ?
                                            <AssistantMessage
                                                curElem={curElem}
                                                socket={socket}
                                                messages={messages}
                                                setMessages={setMessages}
                                                index={index}
                                                assistant={assistant}
                                                activeConv={project.activeConv}
                                                setTypingActive={setTypingActive}
                                                setShowIntTyping={setShowIntTyping}
                                            />
                                            :
                                            curElem.type === "godMode" ?
                                                <AutoMode
                                                    curElem={curElem}
                                                    messages={messages}
                                                    setMessages={setMessages}
                                                    activeConv={project.activeConv}
                                                    index={index}
                                                />
                                                : ""
                                    }
                                </React.Fragment>
                            )
                        })
                        :
                        <div className='d-flex align-items-center justify-content-center' style={{ height: "100%" }}>
                            {loader.fetch ?
                                <FaSpinner className='spin' size={40} /> : ""}

                        </div>
                    }

                    {/* <div className="message-wrap">
                        <div className="message-single">
                            <div className="message-group">
                                <div className="user-photo"><img alt="" src={require('../../../../assets/images/user-9.png')} /></div>
                                <div className="message-head">
                                    <span>Alexandra Wills</span>
                                    <span>09:00 AM</span>
                                </div>
                                <div className="message-body">
                                    <div className="message-txt">
                                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                    </div>
                                    <div className="user-nav">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                                <div className="drop-icon"><HiDotsVertical /></div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <ul>
                                                    <li><Link to="/">Action</Link></li>
                                                    <li><Link to="/">Another action</Link></li>
                                                    <li><Link to="/">Something else</Link></li>
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="message-wrap">
                        <div className="message-single">
                            <div className="message-group">
                                <div className="user-photo"><img alt="" src={require('../../../../assets/images/user-9.png')} /></div>
                                <div className="message-head">
                                    <span>Alexandra Wills</span>
                                    <span>09:00 AM</span>
                                </div>
                                <div className="message-body">
                                    <div className="message-txt">
                                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                        <div className="message-images">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="message-image-single">
                                                        <img alt="" src={require('../../../../assets/images/chat-img-1.png')} />
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon" ><AiOutlineEye /></div>
                                                            <div className="embed-user-icon"><FiDownload /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="message-image-single">
                                                        <img alt="" src={require('../../../../assets/images/chat-img-2.png')} />
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon" ><AiOutlineEye /></div>
                                                            <div className="embed-user-icon"><FiDownload /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="message-image-single">
                                                        <img alt="" src={require('../../../../assets/images/chat-img-3.png')} />
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon" ><AiOutlineEye /></div>
                                                            <div className="embed-user-icon"><FiDownload /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="message-image-single">
                                                        <img alt="" src={require('../../../../assets/images/chat-img-4.png')} />
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon" ><AiOutlineEye /></div>
                                                            <div className="embed-user-icon"><FiDownload /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-nav">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                                <div className="drop-icon"><HiDotsVertical /></div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <ul>
                                                    <li><Link to="/">Action</Link></li>
                                                    <li><Link to="/">Another action</Link></li>
                                                    <li><Link to="/">Something else</Link></li>
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    {/* <div className="message-wrap own-message">
                        <div className="message-single">
                            <div className="message-group">
                                <div className="user-photo"><img alt="" src={require('../../../../assets/images/user-8.png')} /></div>
                                <div className="message-head">
                                    <span>You</span>
                                    <span>09:00 AM</span>
                                </div>
                                <div className="message-body">
                                    <div className="message-txt">
                                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                        <div className="message-images">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="message-image-single img-full">
                                                        <img alt="" src={require('../../../../assets/images/chat-img-1.png')} />
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon"><AiOutlineEye /></div>
                                                            <div className="embed-user-icon"><FiDownload /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-nav">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                                <div className="drop-icon"><HiDotsVertical /></div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <ul>
                                                    <li><Link to="/">Action</Link></li>
                                                    <li><Link to="/">Another action</Link></li>
                                                    <li><Link to="/">Something else</Link></li>
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="msg-tags">Tags <span>@Alexandra</span> <span><BsImages /> Image</span></div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="chatscreen-bottom">
                <div className="chat-container">


                    <div className='chat-container-top'>
                        <div className='chat-container-left'>
                            {file.url ?
                                <div className='attach-img-wrap'>
                                    <div className='attach-img'>
                                        <img src={file.type === "pdf" ? pdfImg : file.type === "doc" ? wordImg : file.url} />
                                        <MdOutlineCancel style={{ cursor: "pointer", position: "absolute", top: 0, right: 0, color: "#fff" }} size={20} onClick={() => handleRemoveMedia()} />
                                    </div>
                                </div> : null}
                            {project.godModeStatus ?
                                <AutoModeInput
                                    assistant={assistant}
                                    message={messages}
                                    setMessage={setMessages}
                                    transcript={transcript}
                                />
                                :
                                <form className="inp-outer icon-left mention-inp" onSubmit={handleSendMessage} style={{ position: "relative", borderTop: file.url ? "none" : "" }}>

                                    {assistant && project.activeConv?.type === "group" ?
                                        <div style={{ height: "100%" }} className='d-flex align-items-center mentionTag'>
                                            <span className='added-staff-batch'>{assistant.name} <span className='ms-2'>
                                                <MdOutlineCancel style={{ cursor: "pointer" }} size={20} onClick={() => handleRemoveAssist()} />
                                            </span></span>
                                        </div>
                                        : ""}


                                    <textarea
                                        className="inp alt"
                                        placeholder="Type new questions"
                                        value={text}
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        required
                                    />

                                    {activeStaffBox ?
                                        <StaffToggleBox
                                            text={text}
                                            setActiveStaffBox={setActiveStaffBox}
                                            setAssistant={setAssistant}
                                            setText={setText}
                                            onSetGodIntialStaffData={onSetGodIntialStaffData}
                                        />
                                        : ""}
                                    <span className="inp-icon">
                                        <div className="user-nav">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="g" id="dropdown-basic">
                                                    <div className="drop-icon"><BsPlusCircleFill /></div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {type === "s" ? null :
                                                        <div className="act-menu">
                                                            <div className="embed-user-icon"><MdAlternateEmail /></div>
                                                            <div className="act-menu-txt">
                                                                <h6>Mention Staff</h6>
                                                                <p>Mention Staff for reply</p>
                                                            </div>
                                                        </div>}
                                                    <div className="act-menu" onClick={handleShow2}>
                                                        <div className="embed-user-icon"><BsUpload /></div>
                                                        <div className="act-menu-txt">
                                                            <h6>Upload File</h6>
                                                            <p>Input file in prompt</p>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </span>

                                    <div className="chat-icon">
                                        <button type='submit' style={{ border: "none", background: "transparent" }}><span><FiSend /></span></button>
                                    </div>
                                </form>
                            }
                        </div>
                        <div className='chat-container-right'>
                            <div className='inp-outer icon-left' style={{ width: 150 }}>
                                <select
                                    className='inp alt pe-5'
                                    style={{ height: 46 }}
                                    value={vcType}
                                    onChange={(e) => handleChnageVcLang(e)}
                                >
                                    {languages.length > 0 ?
                                        languages.map((curElem, index) => {
                                            return (
                                                <option key={index} value={curElem.code}>{curElem.name}</option>
                                            )
                                        })
                                        : ""}
                                </select>
                                {listening ?
                                    <span

                                        className='inp-icon'
                                        onClick={() => handleActiveListen("stop")}
                                    ><PiMicrophoneBold color='green' /></span>
                                    :
                                    <span

                                        className='inp-icon'
                                        onClick={() => handleActiveListen("start")}
                                    ><PiMicrophoneSlashBold /></span>
                                }
                            </div>
                        </div>
                    </div>

                    <p className="chat-info">You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>
                </div>
            </div>
            <UploadFileModal
                show={show2}
                handleClose={handleClose2}
                setFile={setFile}
                file={file}
                conv={project.activeConv}
            />

        </div>
    )
}

export default Messages