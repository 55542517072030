import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useImmer } from "use-immer";
import { fetchData } from '../../../../Redux/Actions/CommonActions'
import Select from 'react-select'
import { onCreateMembership } from '../../../../Redux/Actions/WhiteLabelActions'
import { setAlert } from '../../../../Redux/Actions/AlertActions'
import { Link } from 'react-router-dom'

const CreateMemModal = ({ show, handleClose, fetchMemberships, curElem }) => {
    const dispatch = useDispatch()
    const socials = useSelector(state => state.social)
    const [staffs, setStaffs] = useState([])
    const [paymentAcc, setPaymentAcc] = useState([])
    const [state, setState] = useImmer({
        name: "",
        staff: [],
        companyLimit: 1,
        departmentLimit: 1,
        projectLimit: 1,
        credit: 1,
        payment: {
            methodType: "",
            accId: 0,
            paytime: "monthly",
            price: 1
        }

    })
    const [loader, setLoader] = useState({
        create: false
    })


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let obj = { ...state }
        let url = "create-membership"
        if (obj.id) {
            url = "update-membership"
        }
        dispatch(onCreateMembership(url, obj, fetchMemberships, handleClose, loader, setLoader))
    }

    const handleChange = (e) => {
        let { name, value } = e.target
        if (name === "companyLimit" || name === "departmentLimit" || name === "projectLimit") {
            if (value > 500) {
                value = 500
                dispatch(setAlert("Max limit is 500!", "danger"))
            }
            if (value < 0) {
                value = 0
            }
        }
        if (name === "credit") {
            if (value > 20000) {
                value = 20000
                dispatch(setAlert("Max limit is 20000 for credit!", "danger"))
            }
            if (value < 0) {
                value = 0
            }
        }
        setState({
            ...state,
            [name]: value
        })
    }

    const onChooseStaff = (e) => {
        setState((draft) => {
            draft.staff = e
        })
    }

    const handlePayment = (e) => {
        let { name, value } = e.target

        setState((draft) => {
            draft.payment = {
                ...draft.payment,
                [name]: value
            }
        })
    }

    const fetchStaffs = () => {
        dispatch(fetchData("fetch-hired-staff", {}, setStaffs, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        if (state.payment.methodType === "paypal") {
            setPaymentAcc(socials.paypal)
        } else if (state.payment.methodType === "stripe") {
            setPaymentAcc(socials.stripe)
        } else {
            setPaymentAcc([])
        }
    }, [state.payment.methodType])

    useEffect(() => {
        if (curElem) {
            setState(curElem)
        }
    }, [curElem])



    useEffect(() => {
        fetchStaffs()
    }, [])



    return (
        <Modal className="VideoModal modal-700" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="">
                    <div className="titleBar">
                        <div className="titleBar-profile">
                            <div className="titleBar-left">
                                <h2 className='text-capitalize'>Create New Membership</h2>
                            </div>
                        </div>
                        <span className="modalClose" onClick={handleClose}><AiOutlineClose /></span>
                    </div>

                    <form onSubmit={handleSubmit} className='mt-3'>
                        <div className="input-wrap mt-0">
                            <label htmlFor="">Name</label>
                            <div className="inp-outer">
                                <input
                                    type="text"
                                    className="inp alt"
                                    placeholder="Enter Your Name"
                                    name='name'
                                    value={state.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="input-wrap">
                            <label htmlFor="">Staff</label>
                            <Select
                                options={[{
                                    id: 0,
                                    name: "All"
                                }, ...staffs]}
                                getOptionLabel={(e) => {
                                    return e.name
                                }}
                                getOptionValue={(e) => {
                                    return e.id
                                }}
                                value={state.staff}
                                isMulti={true}
                                classNamePrefix="react-select"
                                onChange={(e) => onChooseStaff(e)}
                            />
                        </div>

                        <div className="input-wrap">
                            <label htmlFor="">How many companies you want in this membership?</label>
                            <div className="inp-outer">
                                <input
                                    className='inp alt'
                                    name='companyLimit'
                                    type="number"
                                    value={state.companyLimit}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-wrap">
                            <label htmlFor="">How many departments you want in the company?</label>
                            <div className="inp-outer">

                                <input
                                    className='inp alt'
                                    name='departmentLimit'
                                    type="number"
                                    value={state.departmentLimit}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-wrap">
                            <label htmlFor="">How many projects you want in the company?</label>
                            <div className="inp-outer">
                                <input
                                    className='inp alt'
                                    name='projectLimit'
                                    type="number"
                                    value={state.projectLimit}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-wrap">
                            <label htmlFor="">Credits</label>
                            <div className="inp-outer">
                                <input
                                    className='inp alt'
                                    name='credit'
                                    type='number'
                                    value={state.credit}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            {/* <p className="pt-2"><small>* 1 Credit = 5 points</small></p> */}
                        </div>

                        <div className="input-wrap">
                            <label htmlFor="">Payment</label>
                            <div className='row p-2'>
                                <div className='col-6'>
                                    <label htmlFor="">Payment method</label>
                                    <div className="inp-outer">
                                        <select
                                            className="inp alt"
                                            name="methodType"
                                            value={state.payment.methodType}
                                            onChange={handlePayment}
                                            required
                                        >
                                            <option value="">Select Method</option>
                                            {/* <option value={"paypal"}>Paypal</option> */}
                                            <option value={"stripe"}>Stripe</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="">Payment account list</label>
                                    <div className="inp-outer">
                                        <select
                                            className="inp alt"
                                            name="accId"
                                            value={state.payment.accId}
                                            onChange={handlePayment}
                                            required
                                        >
                                            <option value="">Select</option>
                                            {paymentAcc.length > 0 ?
                                                paymentAcc.map((curElem, index) => {
                                                    return (
                                                        <option key={index} value={curElem.id}>{curElem.name}</option>
                                                    )
                                                })
                                                : ""}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-6 mt-2'>
                                    <label htmlFor="">Paytime</label>
                                    <div className="inp-outer">
                                        <select
                                            className="inp alt"
                                            name="paytime"
                                            value={state.payment.paytime}
                                            onChange={handlePayment}
                                            required
                                        >
                                            <option value={"monthly"}>Monthly</option>
                                            <option value={"yearly"}>Yearly</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6 mt-2">
                                    <label htmlFor="">Price ($)</label>
                                    <div className="inp-outer">
                                        <input
                                            type='number'
                                            name="price"
                                            className="inp alt"
                                            value={state.payment.price}
                                            onChange={handlePayment}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            {state.payment.methodType && paymentAcc.length === 0 ?
                                <div className="alert alert-info" role="alert">
                                    You do not have {state.payment.methodType} integration. <Link to='/integrations'>Click here</Link> to connect.
                                </div> : null}
                        </div>

                        {/* <div className="input-wrap">
                            <label htmlFor="">Payment account list</label>
                            <div className="inp-outer">
                                <select
                                    className="inp alt"
                                    name="methodType"
                                    value={state.payment.methodType}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select</option>
                                    {paymentAcc.length > 0 ?
                                        paymentAcc.map((curElem, index) => {
                                            return (
                                                <option key={index} value={curElem.id}>{curElem.name}</option>
                                            )
                                        })
                                        : ""}
                                </select>
                            </div>
                        </div> */}
                        <div className="input-wrap text-end">
                            <button className="theme-btn bdr me-1" type='button' onClick={handleClose}><span>Cancel</span></button>
                            <button className="theme-btn" type='submit'><span>{loader.create ? <>Creating <FaSpinner className="spin" /></> : "Create"}</span></button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CreateMemModal