import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const fetchAccData = (setTeamData, setClientData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-clients", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTeamData(res.data.team_members)
                setClientData(res.data.clients)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}

// export const addAccData = (userDetails, fetchClientTeam, loader, setLoader, setUser) => (dispatch, getState) => {
//     commonAxios("create-client", userDetails, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 dispatch(setAlert(res.msg, 'success'));
//                 fetchClientTeam()
//                 setUser({
//                     type:  { name: 'Team Member', code: 'team' },
//                     email: '',
//                     name: '',
//                     password: ''
//                 })
//             } else {
//                 dispatch(setAlert(res.msg, 'danger'));
//             }
//             setLoader({
//                 ...loader,
//                 create: false
//             });

//         }).catch((err) => {
//             console.log(err)
//             setLoader({
//                 ...loader,
//                 create: false
//             });
//         })

// }

export const onEditClient = (url, data, setData, fetchClientTeam, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientTeam()
                dispatch(setAlert(res.msg, 'success'));
                handleClose()
                setData({
                    name: "",
                    email: "",
                    password: "",
                    type: "team"
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, 'danger'));
            setLoader(false);
        })
}


export const onDeleteAcc = (data, fetchClientTeam, setSweet) => (dispatch, getState) => {
    commonAxios("delete-client", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientTeam()
                dispatch(setAlert(res.msg, 'success'))
            } else {
                dispatch(setAlert(res.msg, 'danger'))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
            dispatch(setAlert(err.msg, 'danger'))
        })

}
