import React from 'react'
import { useSelector } from 'react-redux'

const StaffToggleBox = ({ text, setAssistant, setText, setActiveStaffBox, onSetGodIntialStaffData }) => {
    const project = useSelector(state => state.project.create)

    const handleChoose = (cur) => {
        setAssistant(cur)
        setText("")
        setActiveStaffBox(false)
        onSetGodIntialStaffData(cur)
    }

    return (
        <div className='staff-toggle-box'>
            <ul className='p-2'>
                {project.staff.length > 0 ?
                    project.staff.filter((curElem) => {
                        return curElem.name.toLowerCase().includes(text.substring(1).toLowerCase())
                    }).map((curElem, index) => {
                        return (
                            <li key={index} onClick={() => handleChoose(curElem)}>
                                <div className="userToogleList p-1">
                                    <div className="userToogleList-left">
                                        <div className=""><img style={{ width: 30, height: 30, borderRadius: 50 }} alt="" src={curElem.image} /></div>
                                        <div className="userToogleList-txt">
                                            <h5>{curElem.name}</h5>
                                        </div>
                                    </div>

                                </div>
                            </li>
                        )
                    })
                    : ""}
            </ul>
        </div>
    )
}

export default StaffToggleBox