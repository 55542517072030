import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onCreateCat } from '../../../Redux/Actions/CompanyActions'

const CreateCatModal = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        name: "",
    })
    const [loader, setLoader] = useState({
        create: false
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        dispatch(onCreateCat(data, props.fetchCategory, props.handleClose, loader, setLoader))
    }


    return (
        <Modal className="VideoModal modal-700" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className="titleBar">
                    <div className="titleBar-profile">
                        <div className="titleBar-left">
                            <h2 className='text-capitalize'>Create Your Own Category</h2>
                        </div>
                    </div>
                    <span className="modalClose" onClick={props.handleClose}><AiOutlineClose /></span>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="input-wrap">
                        <label>Enter Category Name</label>
                        <div className='inp-outer'>
                            <input
                                className="inp alt"
                                type="text"
                                value={data.name}
                                placeholder='Enter category name'
                                name='name'
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="input-wrap text-end">
                        <button className="theme-btn bdr me-1" type='button' onClick={props.handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='submit'><span>{loader.create ? <>Creating <FaSpinner className="spin" /></> : "Create"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateCatModal