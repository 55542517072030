import React from 'react'
import { BsFacebook, BsLaptop } from 'react-icons/bs'
import { appName } from '../../Global/Global'
import { useSelector } from 'react-redux'

const CheckResources = () => {
    const rebrandData = useSelector(state => state.rebrand)

    return (
        <div className="company-block mt-5">
            <div className="company-block-title">
                <div>
                    <h3>Check Resources</h3>
                    <p>We added Webinar and Facebook group</p>
                </div>
            </div>
            <div className="webnair-block">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <div className="webnair-block-left">
                            <div className="webnair-block-img">
                                <img alt="" src={require('../../assets/images/webnair.png')} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="webnair-block-right">
                            <h3>Check Our Free Webinar</h3>
                            <p>Join us for a private training that will walk you through how to use {rebrandData?.data?.name ? rebrandData?.data?.name : appName} to help you create and sell videos for maximum engagement, views and profits. This training or upgrade will not be made available afterwards. Sign up and make sure to show up on this live training with all your questions.</p>
                            <a className="theme-btn mt-3" href="https://www.abhineos.com/training" target="_blank"><span><BsLaptop /> Join Webinar</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="facebook-block">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <div className="facebook-block-left"><img alt="" src={require('../../assets/images/facebook-banner.png')} /></div>
                    </div>
                    <div className="col-sm-6">
                        <div className="facebook-block-right">
                            <h3>Join to our Facebook Group</h3>
                            <p>10000+ Members in Groups</p>
                            <a className="theme-btn mt-3" href="https://aistaffs.com/fb" target="_blank" rel="nooper noreferrer"><span><BsFacebook /> Join Now</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckResources