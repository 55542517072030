import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateDirectory = (url, data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/update-directory?id=${res.data}`)
                // fetchDirectory()
                dispatch(setAlert(res.msg, "success"))
                // handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchDirectory = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-directory", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_FETCH_DIRECTORY", payload: res.data[0] })
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}



// No AXISO HERE
export const onChangeDirectory = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_DIRECTORY", payload: { name, value } })
}