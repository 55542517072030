import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

import { BiDotsHorizontalRounded, BiSolidBrush } from "react-icons/bi"
import { AiFillFilter } from "react-icons/ai"
import { MdArrowBackIos } from "react-icons/md"
import { FaClockRotateLeft, FaUser, FaUserPlus } from "react-icons/fa6"
import { BsFillChatDotsFill, BsStars, BsBoxArrowInLeft } from "react-icons/bs"
import { HiDotsHorizontal } from "react-icons/hi"
import { AiOutlineClose } from "react-icons/ai"
import { fetchData, onDeleteCampaign } from "../../../../Redux/Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import ConversationRows from "./ConversationRows";
import { activeGodMode, deactiveGodMode, onUpdateProjectStaff, updateActiveConvVoice } from "../../../../Redux/Actions/ProjectActions";
import CommonAlert from "../../../CommonComponents/CommonAlert";
import { setAlert } from "../../../../Redux/Actions/AlertActions";
import { onStopGodMode } from "../../../../Redux/Actions/GodModeAction";
import AddStaffModal from "./AddStaffModal";
import ChatHeaderConvModal from "./ChatHeaderConvModal";
import { CgRemove } from "react-icons/cg";
import { onFetchDpart } from "../../../../Redux/Actions/DepartmentActions";

const ChatHeader = (props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { pid, cid, did, sid, type } = queryString.parse(location.search)
    const project = useSelector(state => state.project)
    const [isActive, setActive] = useState("false");

    const [depart, setDepart] = useState(false)
    const [loader, setLoader] = useState({
        fetch: true
    })

    const [language, setLanguage] = useState([])
    const [response, setResponse] = useState([])
    const [tone, setTone] = useState([])

    const [convVoiceData, setConvVoiceData] = useState({
        lang: "",
        tone: "",
        response: "",
        langCode: ""
    })

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [show2, setShow2] = useState(false)
    const handleShow2 = () => setShow2(true)
    const handleClose2 = () => setShow2(false)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: project.activeConv.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            cid: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("clear-conversation", data, false, false, setSweet, props.fetchMessages))
    }

    const ToggleClass = () => {
        setActive(!isActive);
    };

    const handleGodMode = () => {
        if (project.godModeStatus) {
            dispatch(deactiveGodMode())
            dispatch(onStopGodMode())
        } else {
            if (props.assistant) {
                dispatch(activeGodMode())
            } else {
                dispatch(setAlert("Please choose staff to enable auto mode!", "danger"))
            }
        }
    }

    const handleChangeVoice = (e) => {
        let { name, value } = e.target
        if (name === "langCode") {
            let langData = language.find(({ code }) => code === value)
            setConvVoiceData({
                ...convVoiceData,
                [name]: value,
                lang: langData.name
            })
            value = {
                language: langData.name,
                langCode: value
            }
            dispatch(updateActiveConvVoice(name, value))
        } else {
            setConvVoiceData({
                ...convVoiceData,
                [name]: value
            })
            dispatch(updateActiveConvVoice(name, value))
        }

    }

    const handleRemoveStaff = (index) => {
        let obj = { ...project.create }
        obj.staff = obj.staff.filter((curElem, ind) => ind !== index)
        dispatch(onUpdateProjectStaff(obj))
    }

    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchData("fetch-languages", data, setLanguage, false, false, false, "shortDataLg"))
    }

    const fetchTone = () => {
        let data = {}
        dispatch(fetchData("fetch-tone", data, setTone, false, false, false, "shortDataLg"))
    }

    const fetchResponse = () => {
        dispatch(fetchData("fetch-response-style", {}, setResponse, false, false, false, "shortDataLg"))
    }


    useEffect(() => {
        if (project.activeConv) {
            setConvVoiceData({
                ...convVoiceData,
                lang: project.activeConv.language,
                langCode: project.activeConv.langCode,
                tone: project.activeConv.tone,
                res: project.activeConv.response
            })
        }
    }, [project.activeConv])

    const fetchDepartment = () => {
        let data = { id: did }
        dispatch(onFetchDpart(data, setDepart, loader, setLoader))
    }
    useEffect(() => {
        if (did) {
            fetchDepartment()
        }
    }, [did])

    useEffect(() => {
        fetchLanguage()
        fetchTone()
        fetchResponse()
    }, [])

    return (
        <>
            <header className="site-header">
                <div className="site-container">
                    <div className="site-header-main">
                        <div className="chat-header-left" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                            <div className="icon-back" ><MdArrowBackIos /></div>
                            <div className="user-stack">
                                {project.activeConv ?

                                    <>
                                        {
                                            type === "p" ?
                                                project.create.staff.length > 0 ?
                                                    project.create.staff.map((curElem, index) => {
                                                        return (
                                                            <div className="user-stack-single" key={index}>
                                                                <img alt="" src={curElem.image} />
                                                            </div>
                                                        )
                                                    })
                                                    : ""
                                                :
                                                project.activeConv.staff.length > 0 ?
                                                    project.activeConv.staff.map((curElem, index) => {
                                                        return (
                                                            <div className="user-stack-single" key={index}>
                                                                <img alt="" src={curElem.image} />
                                                            </div>
                                                        )
                                                    })
                                                    : ""

                                        }
                                        {project.activeConv.type === "group" ?
                                            <div className="user-stack-single last"><span>{project.create.staff.length > 4 ? "4+" : project.create.staff.length}</span></div> : null}
                                    </> : null}
                            </div>
                            <h6>{project.activeConv?.type === "group" ? project.create.name : project.activeConv ? project.activeConv.staff[0].name : ""}
                                {project.activeConv?.type === "group" ? "" : <p style={{ fontSize: 13 }}>{project.activeConv ? project.activeConv.staff[0].category : ""}</p>}</h6>

                        </div>

                        <div className={isActive ? "chat-header-right" : "chat-header-right act"}>
                            <span className="chatheader-close" onClick={ToggleClass}><AiOutlineClose /></span>
                            <div className="chat-mode" onClick={handleGodMode} style={{ cursor: "pointer" }}>
                                <BsStars /> {project.godModeStatus ? "Disable" : ""} Auto Mode
                            </div>
                            {project.activeConv ?
                                +project.activeConv.embedId === 0 ?
                                    <div className="user-nav history">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="n" id="dropdown-basic">
                                                <div className="drop-icon"><FaClockRotateLeft /></div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <button className="theme-btn full mb-3" onClick={handleShow}><BsFillChatDotsFill /> <span>New Conversation</span></button>
                                                <ul>
                                                    {project.conversationList.length > 0 ?
                                                        project.conversationList.map((curElem, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <ConversationRows
                                                                        curElem={curElem}
                                                                        activeConv={project.activeConv}
                                                                        fetchConv={props.fetchConversation}
                                                                        setConvNewId={props.setConvNewId}
                                                                        convNewId={props.convNewId}
                                                                        convList={project.conversationList}
                                                                    />
                                                                </React.Fragment>

                                                            )
                                                        })
                                                        : ""}
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    :
                                    "" : ""
                            }

                            {project.activeConv?.type === "group" ?
                                <div className="user-nav history full">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="n" id="dropdown-basic">
                                            <div title="Staffs" className="drop-icon"><FaUser /></div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button
                                                className="theme-btn full mb-3"
                                                onClick={handleShow2}
                                                style={{ cursor: depart.staff.length !== project.create.staff.length ? "" : "not-allowed", background: depart.staff.length !== project.create.staff.length ? "" : "gray" }}
                                                disabled={depart.staff.length === project.create.staff.length}
                                            >
                                                <FaUserPlus /> <span>Add Staffs</span>
                                            </button>
                                            <h6 className="pb-3">Added Staffs <span>({project.create.staff.length})</span></h6>
                                            <ul>
                                                {project.create.staff.length > 0 ?
                                                    project.create.staff.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div className="userList">
                                                                    <div className="userList-left">
                                                                        <div className="userList-img"><img alt="" src={curElem.image} /></div>
                                                                        <div className="userList-txt">
                                                                            <h5>{curElem.name}</h5>
                                                                            <p><FaUser /> {curElem.category} </p>
                                                                        </div>
                                                                    </div>
                                                                    {project.create.staff.length === 1 ? null :
                                                                        <div className="drop-style">
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="t" id="dropdown-basic">
                                                                                    <BiDotsHorizontalRounded />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <ul>
                                                                                        <li onClick={() => handleRemoveStaff(index)}><a style={{ cursor: "pointer" }}><CgRemove /> Remove</a></li>
                                                                                    </ul>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    })

                                                    : ""}
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> : ""}

                            <div className="user-nav history CS">
                                <Dropdown>
                                    <Dropdown.Toggle variant="n" id="dropdown-basic">
                                        <div className="drop-icon"><AiFillFilter /></div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <h5>Conversation Settings</h5>
                                        <div className="input-wrap">
                                            <div className="inp-outer">
                                                <select
                                                    name="langCode"
                                                    className="inp alt"
                                                    value={convVoiceData.langCode}
                                                    onChange={handleChangeVoice}
                                                >
                                                    <option value="">Language</option>
                                                    {language.length > 0 ?
                                                        language.map((curElem, index) => {
                                                            return (
                                                                <option key={index} value={curElem.code}>{curElem.name}</option>
                                                            )
                                                        })
                                                        : ""}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-wrap">
                                            <div className="inp-outer">
                                                <select
                                                    name="tone"
                                                    className="inp alt"
                                                    value={convVoiceData.tone}
                                                    onChange={handleChangeVoice}
                                                >
                                                    <option value="">Tone</option>
                                                    {tone.length > 0 ?
                                                        tone.map((curElem, index) => {
                                                            return (
                                                                <option key={index} value={curElem.name}>{curElem.name}</option>
                                                            )
                                                        })
                                                        : ""}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-wrap">
                                            <div className="inp-outer">
                                                <select
                                                    name="response"
                                                    className="inp alt"
                                                    value={convVoiceData.response}
                                                    onChange={handleChangeVoice}
                                                >
                                                    <option value="">Response Type</option>
                                                    {response.length > 0 ?
                                                        response.map((curElem, index) => {
                                                            return (
                                                                <option key={index} value={curElem.name}>{curElem.name}</option>
                                                            )
                                                        })
                                                        : ""}
                                                </select>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            <div className="user-nav forClear">
                                <Dropdown>
                                    <Dropdown.Toggle variant="n" id="dropdown-basic">
                                        <div className="drop-icon"><HiDotsHorizontal /></div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ul>
                                            <li><a style={{ cursor: "pointer" }} onClick={onDelete}><BiSolidBrush /> Clear all Chat</a></li>
                                            <li><a style={{ cursor: "pointer" }} onClick={() => navigate(-1)}><BsBoxArrowInLeft /> Exit Chat</a></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </div>

                        <div className="drop-icon toggle" onClick={ToggleClass}><HiDotsHorizontal /></div>

                    </div>
                </div>
            </header>

            <AddStaffModal
                show={show2}
                handleClose={handleClose2}
                addedStaffs={project.create.staff}
                depart={depart}
                create={project.create}
            />

            <ChatHeaderConvModal
                show={show}
                pid={pid}
                cid={cid}
                did={did}
                sid={sid}
                fetchConv={props.fetchConversation}
                handleClose={handleClose}
                type={type}
            />

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this chat messages?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />

        </>
    )
}

export default ChatHeader;