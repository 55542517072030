import React, { useEffect, useState } from "react";

import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";

import { FaUserCircle, FaCog, FaShareAlt, FaUsersCog, FaSpinner } from "react-icons/fa"
import { BiSupport, BiLogOut, BiTrash } from "react-icons/bi"
import { RiUserFill, RiRobot2Fill, RiLock2Fill, RiSettings5Fill, RiVideoFill } from "react-icons/ri"

import { BsSearch, BsEnvelope, BsFillChatDotsFill, BsCodeSlash, BsEnvelopeFill } from "react-icons/bs"

import { Link, NavLink } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion';
import ProfileSidebar from "../ProfileSidebar";
import TitleBar from "../../../CommonComponents/TitleBar";
import { fetchArticles, fetchVideos } from "../../../../Redux/Actions/HelpActions";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import appThumb from "../../../../assets/images/appthumb.png"
import { IoCloseSharp } from "react-icons/io5";

const HelpSupport = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch()
    const rebrand = useSelector(state => state.rebrand.data)
    const help = useSelector(state => state.help)
    const [url, setUrl] = useState('')
    const [q, setQ] = useState('')
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }


    const handleOpenSupport = () => {
        window.open("", "_blank")
    }

    const playVideo = (url) => {
        setUrl(url);
        handleShow()
    }

    const closeModal = () => {
        setUrl('');
        handleClose()
    }

    useEffect(() => {
        fetchHelpData()
    }, [])


    return (
        <>
            <TitleBar title="Help & Support" />
            <Header />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="account-wrap">
                        <ProfileSidebar />
                        <div className="account-right larger">
                            <div className="titleBar">
                                <div className="titleBar-left">
                                    <h2>Help and Support</h2>
                                    <p>You can find answers and get direct support here.</p>
                                </div>
                                <div className="titleBar-right"><a className="theme-btn sm-w-100" href="http://support.vineasx.com/" target="_blank" rel="noreferrer"><span><BsEnvelopeFill /> Email Support</span></a></div>
                            </div>
                            <div className="input-wrap">
                                <div className="inp-outer">
                                    <input
                                        className="inp"
                                        type="text"
                                        placeholder="Search"
                                        onChange={(e) => setQ(e.target.value)}
                                    />
                                    <span className="inp-icon"><BsSearch /></span>
                                </div>
                            </div>
                            <div className="support-list">
                                <div className="row">
                                    {help.article.length > 0 ?
                                        help.article.filter((art) => {
                                            return art.tags.toLowerCase().includes(q.toLowerCase())
                                        }).map((curElem, index) => {
                                            return (
                                                <div className="col-6 col-lg-4 col-xl-3" key={index}>
                                                    <Link to={`/help-details?id=${curElem.id}`}>
                                                        <div className="support-single">
                                                            <div className="support-icon">
                                                                <img
                                                                    src={curElem.logo}
                                                                    alt=''
                                                                    style={{ width: 100, padding: 15, borderRadius: 10, height: 100, objectFit: "contain", filter: "invert(1)" }}
                                                                />
                                                            </div>
                                                            <p>{curElem.name}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        }) :
                                        <div className='col-sm-12 mt-3 text-center'>
                                            {
                                                loader.article ?
                                                    <FaSpinner className="spin" style={{ fontSize: 25, color: "#000" }} />
                                                    : ""
                                            }
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="titleBar pt-5">
                                <div className="titleBar-left">
                                    <h2>Popular Video Tutorial</h2>
                                </div>
                            </div>

                            <div className="support-vid">
                                <div className="row">
                                    {help.videos.length > 0 ?
                                        help.videos.map((curElem, index) => {
                                            return (
                                                <div className="col-sm-6" key={index}>
                                                    <div className="support-vid-single" onClick={() => playVideo(curElem.url)}>
                                                        <div className="support-video-thumb">
                                                            <img src={appThumb} alt="" />
                                                        </div>
                                                        <div className="support-txt">
                                                            <h6>{curElem.title}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) :

                                        <div className='col-sm-12 mt-3 text-center'>
                                            {
                                                loader.video ?
                                                    <FaSpinner className="spin" style={{ fontSize: 25, color: "#000" }} />
                                                    : ""
                                            }
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="dashboard-block text-center">
                                <h5>Can't Find Your Answer? We're Here for You!</h5>
                                <p className="pt-3">It happens to the best of us. Sometimes, you might have a question that isn't covered in our FAQs or <br />
                                    documentation. Don't worry - we've got your back!</p>
                                <a href="https://support.vineasx.com/" target="_blank" className="theme-btn bdr mt-3"><span>Contact</span></a>
                            </div>

                        </div>
                    </div>


                </div>
            </div>

            <Footer />


            <Modal className="VideoModal" show={show} onHide={closeModal} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose">
                        <IoCloseSharp />
                    </div>
                    <div className="modalVidWrap">
                        <div className="modalVid">
                            <iframe src={url} title="AIStaffs Walkthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default HelpSupport;