import React, { useEffect, useState } from "react";
import Footer from "../../../Footer/Footer";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useLocation, useNavigate } from "react-router-dom";
import {  BiChevronLeft } from "react-icons/bi"
import TitleBar from "../../../CommonComponents/TitleBar";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onFetchDirectory } from "../../../../Redux/Actions/DirectoryActions";
import WriteDoc from "../../Projects/KnowledgeBase/Write/WriteDoc";
import UploadDoc from "../../Projects/KnowledgeBase/Upload/UploadDoc";
import ImportDoc from "../../Projects/KnowledgeBase/Import/ImportDoc";

const UpdateDirectory = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { id } = queryString.parse(location.search)
    const directoryData = useSelector(state => state.directory.updateData)
    const [loader, setLoader] = useState({
        fetch: true
    })

    console.log(directoryData)

    const fetchDirectory = () => {
        let data = {
            id
        }
        dispatch(onFetchDirectory(data, loader, setLoader))
    }

    useEffect(() => {
        if (id) {
            fetchDirectory()
        }
    }, [id])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <TitleBar title="Update Directory" />

                <header className="site-header">
                    <div className="site-container">
                        <div className="site-header-main">
                            <div className="backBtn alt">
                                <a className="back" style={{cursor:"pointer"}} onClick={() => navigate(-1)}><BiChevronLeft /></a>
                                <h6>Directories</h6>
                            </div>
                            <div className="header-right">
                                {/* <Link className="theme-btn bdr" ><span>Next</span></Link> */}
                                {/* <Link className="theme-btn"><span>Update</span></Link> */}
                            </div>
                        </div>
                    </div>
                </header>

                <div className="embed-progress">
                    <div className="embed-progress-in"></div>
                </div>

                <div className="site-wrapper">
                    <div className="site-container">
                        <div className="embed-wrap width-700">
                            <div className="titleBar">
                                <div className="titleBar-left">
                                    <h2>Update Training Documents - {directoryData.main.name}</h2>
                                    <p>Add Document details and train Ai</p>
                                </div>
                            </div>

                            <div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                                    <Nav variant="b" className="navStyle third">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">
                                                <h6>Write</h6>
                                                <p>Write training document</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">
                                                <h6>Upload</h6>
                                                <p>Upload your document</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">
                                                <h6>Import Website</h6>
                                                <p>Import from website URL</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className="pt-5">
                                        <Tab.Pane eventKey="first">
                                            <WriteDoc
                                                ids={{ directoryId: id }}
                                                fetchData={fetchDirectory}
                                                url="create-knowledge-manual"
                                                kdData={directoryData.main.data}
                                                type="directory"
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <UploadDoc
                                                ids={{ directoryId: id }}
                                                fetchData={fetchDirectory}
                                                url="create-knowledge-extract"
                                                kdData={directoryData.main.data}
                                                type="directory"
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <ImportDoc
                                                ids={{ directoryId: id }}
                                                fetchData={fetchDirectory}
                                                url="create-knowledge-website"
                                                kdData={directoryData.main.data}
                                                type="directory"
                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer></Footer>

            </>
    )
}

export default UpdateDirectory;