import React, { useState } from "react";

import CompanyHeader from "../../Header/CompanyHeader";
import Footer from "../../Footer/Footer";

import{AiFillPlusCircle} from "react-icons/ai"
import{BsLaptop, BsFacebook} from "react-icons/bs"
import { Link } from "react-router-dom";

function CompanyProfile() {

    return(
        <>
        <CompanyHeader></CompanyHeader>

        <div className="site-wrapper">
           <div className="site-container">
              <div className="company-empty">

                <div className="company-block">
                    <div className="company-block-title">
                        <div>
                            <h3>Choose Company</h3>
                            <p>Create and Manage Companies</p>
                        </div>
                        <Link to="/departments" className="theme-btn" href=""><span><AiFillPlusCircle/> Create Company</span></Link>
                    </div>

                    <div className="company-opt">
                        <ul>
                            <li>
                                <div className="company-opt-left">
                                    <div className="company-opt-img"><img alt="" src={require('../../../assets/images/design.png')} /></div>
                                    <div className="company-opt-details">
                                        <h6>Design Agency</h6>
                                        <p>Started at 10 Sep 2023 - 09:30AM</p>
                                    </div>
                                </div>
                                <div className="company-opt-right">
                                    <Link to="/departments" className="theme-btn bdr" href=""><span>Launch</span></Link>
                                </div>
                            </li>
                            <li>
                                <div className="company-opt-left">
                                    <div className="company-opt-img"><img alt="" src={require('../../../assets/images/settings.png')} /></div>
                                    <div className="company-opt-details">
                                        <h6>Company Name</h6>
                                        <p>Started at 10 Sep 2023 - 09:30AM</p>
                                    </div>
                                </div>
                                <div className="company-opt-right">
                                   <Link to="/departments" className="theme-btn bdr" href=""><span>Launch</span></Link>
                                </div>
                            </li>
                            <li>
                                <div className="company-opt-left">
                                    <div className="company-opt-img"><img alt="" src={require('../../../assets/images/manage.png')} /></div>
                                    <div className="company-opt-details">
                                        <h6>Company Name</h6>
                                        <p>Started at 10 Sep 2023 - 09:30AM</p>
                                    </div>
                                </div>
                                <div className="company-opt-right">
                                   <Link to="/departments" className="theme-btn bdr" href=""><span>Launch</span></Link>
                                </div>
                            </li>
                            <li>
                                <div className="company-opt-left">
                                    <div className="company-opt-img"><img alt="" src={require('../../../assets/images/intregration.png')} /></div>
                                    <div className="company-opt-details">
                                        <h6>Company Name</h6>
                                        <p>Started at 10 Sep 2023 - 09:30AM</p>
                                    </div>
                                </div>
                                <div className="company-opt-right">
                                   <Link to="/departments" className="theme-btn bdr" href=""><span>Launch</span></Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                </div>

                <div className="company-block mt-5">
                    <div className="company-block-title">
                        <div>
                            <h3>Check Resources</h3>
                            <p>We added Webinar and Facebook group</p>
                        </div>
                    </div>
                    <div className="webnair-block">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="webnair-block-left">
                                    <div className="webnair-block-img">
                                       <img alt="" src={require('../../../assets/images/webnair.png')} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="webnair-block-right">
                                    <h3>Check Our Free Webinar</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor</p>
                                    <a className="theme-btn mt-3" href=""><span><BsLaptop/> Join Webinar</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="facebook-block">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="facebook-block-left"><img alt="" src={require('../../../assets/images/facebook-banner.png')} /></div>
                            </div>
                            <div className="col-sm-6">
                                <div className="facebook-block-right">
                                    <h3>Join to our Facebook Group</h3>
                                    <p>10000+ Members in Groups</p>
                                    <a className="theme-btn mt-3" href=""><span><BsFacebook/> Join Now</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              </div>
           </div>
        </div>
          
        <Footer></Footer>
        </>
    )
}

export default CompanyProfile;