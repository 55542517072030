import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchArticles, fetchVideos } from "../../../../Redux/Actions/HelpActions";
import Header from "../../../Header/Header";
import { Tab } from "react-bootstrap";
import TrainingNav from "./TrainingNav";
import { FaSpinner } from "react-icons/fa";
import Footer from "../../../Footer/Footer";
import TitleBar from "../../../CommonComponents/TitleBar";

const SupportArticle = () => {
    let location = useLocation()
    const dispatch = useDispatch()
    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;
    const articleData = useSelector(state => state.help.article);

    const [filterArticle, setFilterArticle] = useState(false)
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    useEffect(() => {
        if (articleData.length > 0) {
            setFilterArticle(articleData.filter(article => article.id === id)[0])
        }
    }, [articleData])

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    useEffect(() => {
        fetchHelpData()
    }, [])

    return (
        <>
            <TitleBar title="Help & Support" />
            <Header />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="outer-container pb-5">
                        <div className="titleBar">
                            <div className="titleBar-left">
                                <h2>Help</h2>
                                <p>Select from Popular Topics</p>
                            </div>
                        </div>
                    </div>
                    <div className="account-wrap for-post">
                        <Tab.Container id="left-tabs-example">
                            <div className="account-left">
                                <TrainingNav
                                    article={articleData}
                                    articleId={id}
                                />
                            </div>
                            <div className="account-right">
                                <div className="dashboard-block mt-0">
                                    <Tab.Content>
                                        {filterArticle ?
                                            filterArticle.items.map((item, index) => {
                                                return (
                                                    item.id === +art_id ?
                                                        <React.Fragment key={index}>
                                                            <div className="support-single" key={index}>
                                                                <div className="upgrade-tab-block">
                                                                    <div className="text-dark"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.content
                                                                        }}>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        : ''
                                                )
                                            })
                                            :
                                            <div className="spinner d-flex justify-content-center" style={{ width: '100%', height: "900px" }}>
                                                <div className="spinner_tag">
                                                    <FaSpinner className='spin' style={{ color: "#000", fontSize: "30px" }} />
                                                </div>
                                            </div>
                                        }
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

}

export default SupportArticle;