import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchDataRedux, onFetchById, onUploadMedia } from "../../../Redux/Actions/CommonActions";
import { FaSpinner } from "react-icons/fa";
import { onChangeDepartment, onCreateDepartment, unmountCreateDepartment } from "../../../Redux/Actions/DepartmentActions";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import queryString from "query-string";
import TitleBar from "../../CommonComponents/TitleBar";
import Select from 'react-select'
import Header from "../../Header/Header";
import { IoIosArrowBack } from "react-icons/io";

const CreateDepartments = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { cid, did } = queryString.parse(location.search)
    const create = useSelector(state => state.department.create)
    const activeCompany = useSelector(state => state.company.activeCompany)
    const staffList = useSelector(state => state.staff.list)
    const [hiredStaffs, setHiredStaffs] = useState([])
    const [percent, setPercent] = useState(0)
    const [image, setImage] = useState("https://reeelapps-app.s3.us-west-2.amazonaws.com/AiOffices1/1727360484_TAkWKxb8k97SYwLZaYvTYJAiOffices1.png")
    const [loader, setLoader] = useState({
        upload: false,
        create: false,
        fetch: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeDepartment(name, value))
    }

    const onChooseStaff = (e) => {
        dispatch(onChangeDepartment("staff", e))
    }

    const onCompeleteUpload = (url) => {
        setImage(url)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = { ...create }
        obj.image = image
        obj.companyId = cid
        if (obj.image) {
            setLoader({
                ...loader,
                create: true
            })
            let url = "create-department"
            if (did) {
                url = "update-department"
            }
            dispatch(onCreateDepartment(url, obj, navigate, loader, setLoader))
        } else {
            dispatch(setAlert("Please provide image for your department.", "danger"))
        }
    }

    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 50000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    const fetchDepartment = () => {
        let data = {
            id: did
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onFetchById("fetch-department", data, "EDIT_DEPARTMENT", loader, setLoader))
    }

    const fetchStaffs = () => {
        let obj = { cid: activeCompany.id }
        dispatch(fetchDataRedux("fetch-hired-staff", obj, "FETCH_STAFF_LIST", false, false))
    }

    useEffect(() => {
        if (staffList.length > 0) {
            setHiredStaffs(staffList.filter((curElem) => {
                return +curElem.isFire === 0
            }))
        }
    }, [staffList])


    useEffect(() => {
        if (create.id) {
            setImage(create.image)
        }
    }, [create.id])


    useEffect(() => {
        if (cid, did) {
            fetchDepartment()
        }
    }, [cid, did])


    useEffect(() => {
        if (activeCompany) {
            fetchStaffs()

        }
    }, [activeCompany])

    useEffect(() => {
        return () => {
            dispatch(unmountCreateDepartment())
        }
    }, [])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div> :
            <>
                <TitleBar title="Departments" />
                <Header />

                <div className="site-wrapper">
                    <div className="site-container">
                        <div className="theme-btn" onClick={() => navigate(-1)}>
                            <span><IoIosArrowBack size={25} />Back</span>
                        </div>
                        <div className="company-empty">

                            <div className="company-block">
                                <div className="company-block-title">
                                    <div>
                                        <h3>{did ? "Update" : "Create"} Department Details</h3>
                                        <p>Fill the department details</p>
                                    </div>
                                </div>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="input-wrap">
                                    <label htmlFor="">Department Name</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp"
                                            type="text"
                                            name="name"
                                            placeholder="Type Department Name"
                                            onChange={handleChange}
                                            value={create.name}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Description</label>
                                    <div className="inp-outer">
                                        <textarea
                                            className="inp"
                                            name="description"
                                            value={create.description}
                                            onChange={handleChange}
                                            placeholder="Type Department Description"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Select Staffs</label>
                                    {hiredStaffs.length > 0 ?
                                        <Select
                                            options={hiredStaffs}
                                            className="inp-outer"
                                            getOptionLabel={(e) => {
                                                return e.name
                                            }}
                                            getOptionValue={(e) => {
                                                return e.id
                                            }}
                                            value={create.staff}
                                            isMulti={true}
                                            classNamePrefix="react-select"
                                            onChange={(e) => onChooseStaff(e)}
                                        />
                                        :
                                        <div className="text-center p-2"> You do not have hired staff. <Link to={`/staffs?cid=${activeCompany.id}&type=av`}>Click Here</Link> to hire</div>
                                    }

                                </div>

                                <div className="input-wrap">
                                    <div className="file-field">
                                        <div className="me-3 d-flex align-items-center justify-content-center flImg" style={{ height: 50 }}>
                                            {loader.upload ?
                                                <FaSpinner className="spin" size={25} />
                                                :
                                                <img alt="" src={image} style={{ maxHeight: "100%" }} />
                                            }
                                        </div>
                                        <div>
                                            <h6>Click to Upload Department Image</h6>
                                            <p>JPG, PNG, JPEG upto 5MB is allowed</p>
                                        </div>
                                        {loader.upload ? null :
                                            <input
                                                type="file"
                                                onChange={onInputImage}
                                                accept="image/jpg, image/png, image/jpeg"
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    {did ?
                                        <button
                                            className="theme-btn full"
                                            type="submit"
                                            style={{
                                                background: create.name && create.description && create.staff.length > 0 ? "" : "gray",
                                                cursor: create.name && create.description && create.staff.length > 0 ? "" : "not-allowed"
                                            }}
                                            disabled={create.name && create.description && create.staff.length > 0 ? false : true}
                                        >
                                            <span>{loader.create ? <>Updating <FaSpinner className="spin" /></> : "Update Department"}</span>
                                        </button>
                                        :
                                        <button
                                            className="theme-btn full"
                                            type="submit"
                                            style={{
                                                background: create.name && create.description && create.staff.length > 0 ? "" : "gray",
                                                cursor: create.name && create.description && create.staff.length > 0 ? "" : "not-allowed"
                                            }}
                                            disabled={create.name && create.description && create.staff.length > 0 ? false : true}
                                        >
                                            <span>{loader.create ? <>Creating <FaSpinner className="spin" /></> : "Create Department"}</span>
                                        </button>

                                    }
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

                <Footer />
            </>
    )
}

export default CreateDepartments;