import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

import {BiTrash, BiDotsHorizontalRounded, BiSolidBrush} from "react-icons/bi"
import {AiFillFilter} from "react-icons/ai"
import{MdArrowBackIos, MdOutlineEdit} from "react-icons/md"
import{FaClockRotateLeft, FaUser, FaUserPlus} from "react-icons/fa6"
import{BsFillChatDotsFill, BsStars, BsBoxArrowInLeft} from "react-icons/bs"
import{HiDotsHorizontal} from "react-icons/hi"
import{AiOutlineClose} from "react-icons/ai"

import { MdOutlineModeEdit } from "react-icons/md";
import { RiEditLine } from "react-icons/ri";

import { LuFiles } from "react-icons/lu";
import { FaRegTrashCan } from "react-icons/fa6";

function ChatHeaderAuto() {

    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive); 
    };

    return(
        <>
          <header className="site-header">
             <div className="site-container">
                <div className="site-header-main">
                    <div className="chat-header-left">
                        <div className="icon-back"><MdArrowBackIos/></div>
                        <div className="user-stack">
                            <div className="user-stack-single"><img alt="" src={require('../../assets/images/user-2.jpg')} /></div>
                        </div>
                        <h6>Alexandra Wills</h6>
                        <div className="ms-3 d-flex gap-2">
                           <RiEditLine />
                           <FaRegTrashCan />
                        </div>
                    </div>

                    <div className={isActive ? "chat-header-right" : "chat-header-right act"}>
                    <span className="chatheader-close" onClick={ToggleClass}><AiOutlineClose/></span>
                    <div className="chat-mode"><BsStars/> Auto Mode</div>
                    <div className="user-nav history">
                    <Dropdown>
                            <Dropdown.Toggle variant="n" id="dropdown-basic">
                                <div className="drop-icon"><FaClockRotateLeft/></div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <button className="theme-btn full mb-3"><BsFillChatDotsFill/> <span>New Conversation</span></button>
                                <ul>
                                    <li>
                                        <div className="flexLink" to="/">
                                            <p>List out Saas UX Testin Saas UX Testin</p>
                                            <div className="flexLink-icon">
                                                <button><MdOutlineEdit/></button>
                                                <button><BiTrash/></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flexLink" to="/">
                                            <p>List out Saas UX Testin Saas UX Testin</p>
                                            <div className="flexLink-icon">
                                                <button><MdOutlineEdit/></button>
                                                <button><BiTrash/></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flexLink" to="/">
                                            <p>List out Saas UX Testin Saas UX Testin</p>
                                            <div className="flexLink-icon">
                                                <button><MdOutlineEdit/></button>
                                                <button><BiTrash/></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flexLink" to="/">
                                            <p>List out Saas UX Testin Saas UX Testin</p>
                                            <div className="flexLink-icon">
                                                <button><MdOutlineEdit/></button>
                                                <button><BiTrash/></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flexLink" to="/">
                                            <p>List out Saas UX Testin Saas UX Testin</p>
                                            <div className="flexLink-icon">
                                                <button><MdOutlineEdit/></button>
                                                <button><BiTrash/></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flexLink" to="/">
                                            <p>List out Saas UX Testin Saas UX Testin</p>
                                            <div className="flexLink-icon">
                                                <button><MdOutlineEdit/></button>
                                                <button><BiTrash/></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flexLink" to="/">
                                            <p>List out Saas UX Testin Saas UX Testin</p>
                                            <div className="flexLink-icon">
                                                <button><MdOutlineEdit/></button>
                                                <button><BiTrash/></button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                     </div>

                     <div className="user-nav history">
                        <div className="drop-icon"><LuFiles /></div>
                     </div>

                     <div className="user-nav history">
                     <Dropdown>
                        <Dropdown.Toggle variant="n" id="dropdown-basic">
                            <div className="drop-icon"><AiFillFilter/></div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <h5>Chat Filter</h5>
                            <div className="input-wrap">
                                <div className="inp-outer">
                                    <select name="" id="" className="inp alt">
                                        <option value="">Language : EN</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-wrap">
                                <div className="inp-outer">
                                    <select name="" id="" className="inp alt">
                                        <option value="">Tone : Cynical</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-wrap">
                                <div className="inp-outer">
                                    <select name="" id="" className="inp alt">
                                        <option value="">Response : Creative</option>
                                    </select>
                                </div>
                            </div>
                        </Dropdown.Menu>
                        </Dropdown>
                     </div>

                     <div className="user-nav forClear">
                     <Dropdown>
                        <Dropdown.Toggle variant="n" id="dropdown-basic">
                            <div className="drop-icon"><HiDotsHorizontal/></div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <ul>
                                <li><Link to="/"><BiSolidBrush/> Clear all Chat</Link></li>
                                <li><Link to="/"><BsBoxArrowInLeft/> Exit Chat</Link></li>
                            </ul>
                        </Dropdown.Menu>
                        </Dropdown>
                     </div>

                    </div>

                    <div className="drop-icon toggle" onClick={ToggleClass}><HiDotsHorizontal/></div>

                </div>
             </div>
          </header>
        </>
    )
}

export default ChatHeaderAuto;