import React, { useEffect, useState } from "react";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import { AiFillPlusCircle } from "react-icons/ai"
import { BiSearch } from "react-icons/bi"
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { fetchData, fetchDataRedux } from "../../../../Redux/Actions/CommonActions";
import { FaSpinner } from "react-icons/fa";
import TitleBar from "../../../CommonComponents/TitleBar";
import SingleConv from "./SingleConv";
import CreateProjConvModal from "./CreateProjConvModal";
import { IoIosArrowBack } from "react-icons/io";
import queryString from "query-string";

const ProjectConversation = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const auth = useSelector(state => state.auth)
    const staffList = useSelector(state => state.staff.list)
    const { pid } = queryString.parse(location.search)
    const activeCompany = useSelector(state => state.company.activeCompany)
    const [staffs, setstaffs] = useState([])
    const [conv, setConv] = useState([])
    const [proj, setProj] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [filterObj, setFilterObj] = useState({
        qs: "",
        qp: "",
        qe: "",
        typeS: "",
        typeP: "",
        typeE: ""
    })


    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const fetchConv = () => {
        let obj = { cid: activeCompany.id }
        dispatch(fetchData("fetch-conversation-all", obj, setConv, loader, setLoader))
    }

    const handleChange = (name, value) => {
        setFilterObj({
            ...filterObj,
            [name]: value
        })
    }

    const projConv = conv.filter((curElem) => {
        return curElem.type === "group"
    }).filter((curElem) => +curElem.projectId === +pid)

    useEffect(() => {
        if (staffList.length > 0) {
            setstaffs(staffList.map((curElem, index) => {
                if (index === 0) {
                    return { value: '', label: 'All' }
                }
                return {
                    label: curElem.name,
                    value: curElem.name
                }
            }))
        }
    }, [staffList])


    const fetchStaffs = () => {
        let obj = { cid: activeCompany ? activeCompany.id : 0 }
        dispatch(fetchDataRedux("fetch-hired-staff", obj, "FETCH_STAFF_LIST", false, false, true))
    }

    useEffect(() => {
        if (activeCompany) {
            fetchConv()
            fetchStaffs()
        }
    }, [activeCompany])


    return (
        <>
            <Header />
            <TitleBar title="Project Conversations" />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="outer-container">
                        <div className="titleBar">
                            <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                <h4>   <span><IoIosArrowBack size={25} />Project Conversations</span></h4>
                            </div>
                        </div>
                    </div>

                    <div className="department-list mt-5">
                        <div className="row align-items-center mb-4">
                            <div className="col-auto">
                                <div className="select-style alt input-wrap mt-0">
                                    <label className="label" htmlFor="">Staff</label>
                                    <Select
                                        options={staffs}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        onChange={(e) => handleChange("typeP", e.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-auto" style={{ marginRight: 'auto' }}>
                                <div className="input-wrap mt-0">
                                    <label className="label" htmlFor="">Search</label>
                                    <div className="inp-outer icon-left round">
                                        <input
                                            className="inp sm"
                                            type="text"
                                            placeholder="Search"
                                            value={filterObj.qp}
                                            onChange={(e) => handleChange("qp", e.target.value)}

                                        />
                                        <span className="inp-icon"><BiSearch /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                {+auth.user.isClientAccount === 1 ? null :
                                    <button className="theme-btn sm-w-100" onClick={handleShow2}><span><AiFillPlusCircle /> New Conversation</span></button>
                                }
                            </div>
                        </div>

                        <div className="row">
                            {projConv.length > 0 ?
                                projConv.filter((curElem) => {
                                    return curElem.name.toLowerCase().includes(filterObj.qp.toLowerCase())
                                }).filter((curElem) => {
                                    if (filterObj.typeP !== "") {
                                        if (curElem.staff.length > 0) {
                                            return curElem.staff.find(({ name }) => name === filterObj.typeP)
                                        }
                                    } else {
                                        return curElem
                                    }
                                }).map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <SingleConv
                                                type="group"
                                                curElem={curElem}
                                                fetchConv={fetchConv}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                :
                                <div className="col-12 text-center mt-4">
                                    {loader.fetch && activeCompany ?
                                        <FaSpinner className="spin" size={25} />
                                        : "You have no project conversation created yet."}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            <CreateProjConvModal
                show={show2}
                handleClose={handleClose2}
                fetchConv={fetchConv}
                proj={proj}
                setProj={setProj}
            />
        </>
    )
}

export default ProjectConversation;