const initialState = {
    google: [],
    facebook: [],
    cal: [],
    elevenlabs: [],
    instagram: [],
    autoresponder: [],
    smtp: [],
    twilio: [],
    paypal: [],
    stripe: []
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            if (!action.payload) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state,
                    google: action.payload.google ? action.payload.google : [],
                    facebook: action.payload.facebook ? action.payload.facebook : [],
                    cal: action.payload.cal ? action.payload.cal : [],
                    elevenlabs: action.payload.elevenlabs ? action.payload.elevenlabs : [],
                    instagram: action.payload.instagram ? action.payload.instagram : [],
                    autoresponder: action.payload.autoresponder ? action.payload.autoresponder : [],
                    smtp: action.payload.smtp ? action.payload.smtp : [],
                    twilio: action.payload.twilio ? action.payload.twilio : [],
                    paypal: action.payload.paypal ? action.payload.paypal : [],
                    stripe: action.payload.stripe ? action.payload.stripe : [],
                }
            }

        case 'ADD_SYVID':
            return {
                ...state,
                syvid: action.payload
            }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}