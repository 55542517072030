import React, { useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { BsCardImage, BsCodeSlash } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions'
import CommonAlert from '../../../CommonComponents/CommonAlert'
import { useDispatch, useSelector } from 'react-redux'

const EmbedRows = ({ curElem, fetchEmbeds }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false

    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }



    const performDelete = () => {
        let data = {
            id: curElem.id,
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-embed", data, false, false, setSweet, fetchEmbeds))
    }

    return (
        <div className="embed-single">
            <div className="embed-user">
                <div className="embed-user-icon"><BsCardImage /></div>
                <div className="embed-user-txt">
                    <h6>{curElem.name}</h6>
                    <p>{curElem.created}</p>
                </div>
            </div>
            <div><span>{curElem.url}</span></div>
            <div><span>{curElem.staff[0].name}</span></div>
            <div>
                <span className="active-tag" style={{ background: +curElem.status === 0 ? "gray" : "" }}>{+curElem.status === 0 ? "Inactive" : "Active"}</span>
            </div>
            <div className="d-flex">
                <Link to={`/embed-code?id=${curElem.id}`}><div className="embed-user-icon"><BsCodeSlash /></div></Link>
                {+auth.user.isClientAccount === 1 ? null :
                    <a onClick={onDelete} style={{ cursor: "pointer" }}><div className="embed-user-icon ms-2"><BiTrash /></div></a>}
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this embed?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div>
    )
}

export default EmbedRows